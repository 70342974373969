<template>
  <div>
    <div class="hide-content"></div>

    <div class="geo">
      <img src="/icons/geo-hero.svg" class="error geo-gadget-img" alt="error" />
      <h4 class="give-access color-white">
         {{ $t('fix_address_modal.title') }}
      </h4>
      <h4 class="color-grey">
        {{ $t('fix_address_modal.subtitle') }}
      </h4>
      <LongButton @click="close">
        OK
      </LongButton>
    </div>
  </div>
</template>

<style scoped>
.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  left: 0;
}
.geo {
  position: fixed;
  top: 50%;
  width: 90vw;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2.5vh 5.4vw 3.7vh;
  text-align: center;
  background: #1d1d1b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2.3vh 2.3vh 10vw 10vw;

  z-index: 10;
}

.geo .info {
  font-weight: 400 !important;
  color: #a4a3a1;
  font-size: 4.5vw;
  padding: 2.5vh 0 0;
}

.geo p {
  color: #e2223b;
  font-size: 5vw;
  line-height: 3vh;
  padding: 1.3vh 0 0;
  margin: 0;
}

.geo h4 {
  margin: 0;
  font-weight: 400;
  padding-top: 2.5vh;
  font-size: 5vw;
}

.geo-gadget-img {
  height: auto;
  margin-top: 3.7vh;

  width: 40vw;
}

img.error {
  margin-top: 6.6vh;
  margin-bottom: 2.5vh;
}

.geo-fail-img {
  height: auto;
  margin-top: 2.5vh;
  width: 17vw;
}
.geo-example {
  width: 75vw;
  margin-top: 2.5vh;
  height: auto;
}

.error-modal-button {
  margin-top: 2.55vh !important;
}

@media screen and (min-width: 768px) {
  .geo {
    width: 80vh;
    border-radius: 2.3vh;
  }
  .geo-fail-img {
    width: 10vh;
  }
  .geo p {
    font-size: 3vh;
  }
  .generic-button {
    width: 100%;
    font-size: 3vh;
    padding: 1vh 2vh;
  }
}
</style>

<script>
import LongButton from "@/components/LongButton.vue";
import SnakeGame from "@/components/SnakeGame.vue";

import InfoPopupDesktop from "@/components/desktop/InfoPopupDesktop.vue"

export default {
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  computed: {},
  mounted() {
    if (!this.isMobile) {
      this.$store.commit('desktop/setInfoPopupVariant', 'fix_address');
      this.close();
      return;
    }

    this.toggleGlobalScrolling(true);
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  },
  components: {
    LongButton,
    SnakeGame,
    InfoPopupDesktop
  },
};
</script>