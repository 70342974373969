<template>
  <div>
      <div class="hide-content"></div>
      <div class="introduce">
        <h4>{{ $t('greeting.introduce_text') }}</h4>
        <img src="/icons/hero.png" alt="hero" class="hero" />
        <input
          type="text"
          class="name"
          maxlength="32"
          :placeholder="$t('greeting.introduce_input_name')"
          v-model="client_name"
        />
        <input
          type="text"
          class="name"
          maxlength="20"
          :placeholder="$t('greeting.introduce_input_phone')"
          v-model="client_phone"
        />
        <!-- <a
          href="#"
          class="d-block pt-3"
          @click.prevent="makeOrder()"
          v-if="!clientName"
        >{{ $t('greeting.skip') }}</a> -->
        <LongButton
          class="btn-primary mt-4"
          v-if="isDataValid"
          @click="onConfirm"
        >{{ $t('greeting.go') }}</LongButton>
      </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            client_name: null,
            client_phone: null
        }
    },
    methods: {
        onConfirm() {
            this.$emit('confirm', {
                name: this.client_name,
                phone: this.client_phone
            })
        }
    },
    computed: {
        isDataValid() {
            return this.client_name 
                && this.client_phone 
                && /^\+?[0-9]{5,20}$/.test(this.client_phone);
        }
    }
}
</script>

<style scoped>
.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.8);
  opacity: 1;
  top: 0;
  left: 0;
}

.introduce {
  box-shadow: 0px 0px 5px #dddddd;
  border-radius: 40px;
  display: block;
  background: white;
  margin: 0 auto;
  width: 90%;
  text-align: center;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  padding: 20px 0 30px;
  position: absolute;
}

img.hero {
  height: auto;
  width: 250px;
}


.name {
  display: block;
  box-shadow: 0px 3px 19px #e9e9e9;
  border-radius: 25px;
  margin: 10px auto;
  border: 1px solid gainsboro;
  transition: 0.3s;
  width: 60%;
  padding: 8px;
  opacity: 0.5;
  text-align: center;
}

.name:active,
.name:focus {
  outline: none;
  padding: 8px 20px;
  width: 80%;
  border: 1px solid grey;
}
</style>