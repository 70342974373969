<template>
  <header :class="{
    'header-preorder': !is_delivery
  }">
    <h3 class="color-black">
      {{is_delivery ? 'DELIVERY' : 'TAKEAWAY'}}
    </h3>
    <p class="color-black">
      {{ $t("desktop_delivery.modal_subtitle") }}
    </p>
    <div class="tabs">
      <div @click="switchTab('cart')" :class="getTabClass('cart')">
        <span :class="current_tab == 'cart' ? 'active' : ''">1</span>
        {{ $t("desktop_delivery.tab_cart") }}
      </div>
      <div @click="switchTab('client')" :class="getTabClass('client')">
        <span :class="current_tab == 'client' ? 'active' : ''">2</span>
        {{ $t("desktop_delivery.tab_client") }}
      </div>
      <div @click="switchTab('delivery')" :class="getTabClass('delivery')">
        <span :class="current_tab == 'delivery' ? 'active' : ''">3</span>
        {{ $t("desktop_delivery.tab_delivery") }}
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    current_tab: String,
    unlocked_tabs: Object,
    is_delivery: Boolean
  },
  methods: {
    getTabClass(target_tab) {
      return {
        tab: true,
        'tab-preorder': !this.is_delivery,
        active: this.current_tab === target_tab,
      };
    },
    switchTab(target_tab) {
      if (!this.unlocked_tabs[target_tab]) return;
      this.$emit("switchTab", target_tab);
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
header {
  background: #cbd9e6;
  border-radius: 2vh 2vh 0 0;
  padding: 2.2vh 0 0 0;
  text-align: center;
  width: 100%;
}
header.header-preorder {
  background: #ffd2a8;
}
.tab span {
  color: #cbd9e6;
  padding: 0 1.1vh;
  border-radius: 5vh;
  font-size: 2.5vh;
  line-height: 1.6;
  width: 4vh;
  height: 4vh;
  margin-right: 1.7vh;
  background: #1d1d1d;
}
.tab span:first-child {
  padding: 0 1.3vh;
}
.tab span.active {
  color: #1d1d1d;
  background: #cbd9e6;
}
.tab-preorder span.active {
  color: #1d1d1d;
  background: #ffd2a8;
}
h3 {
  font-size: 4.5vh;
  line-height: 0.8;
}
p {
  padding-top: 1.7vh;
  font-size: 1.7vh;
  line-height: 0.8;
}
.tabs {
  display: flex;
  padding-top: 2.2vh;
  justify-content: space-between;
}
.tab {
  cursor: pointer;
  flex-basis: 33.3%;
  color: #1d1d1d;
  display: flex;
  font-size: 2vh;
  align-items: center;
  justify-content: center;
  background: #cbd9e6;
  padding: 1.5vh;
}
.tab-preorder {
  background: #ffd2a8;
}
.tab.active {
  color: #ededed;
  background: #1d1d1d;
}
</style>

