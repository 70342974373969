<template>
  <div class="order-detail">
    <select :value="value" @input="$emit('input', $event.target.value)">
      <option :value="false">
        {{ $t("preorder_details.takeaway") }}
      </option>
      <option :value="true">
        {{ $t("preorder_details.with_reservation") }}
      </option>
    </select>
    <img
      :src="
        isDarkMode
          ? '/icons/details/reservation-white.svg'
          : '/icons/details/reservation.svg'
      "
      v-if="value"
    />
    <img
      :src="
        isDarkMode
          ? '/icons/details/takeaway-white.svg'
          : '/icons/details/takeaway.svg'
      "
      v-else
    />
    <div class="w-100">
      <p v-if="value">
        {{ $t("preorder_details.with_reservation") }}
      </p>
      <p v-else>{{ $t("preorder_details.takeaway") }}</p>
    </div>
    <div class="selective-item">
      <img
        :src="isDarkMode ? '/icons/arrow-black.svg' : '/icons/arrow-white.svg'"
        class="arrow"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean,String],
      default: false,
    },
  },
};
</script>