var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preorder-bill"},[_c('div',{class:_vm.headerClass},[_c('div',{staticClass:"header-info"},[_c('div',{staticClass:"order-id"},[_c('h3',[_vm._v(_vm._s(_vm.preorder.is_delivery ? "DELIVERY" : "TAKEAWAY"))]),_c('span',[_vm._v("\n          id:\n          "),_c('h3',{staticClass:"d-inline"},[_vm._v(_vm._s(_vm.preorder.local_id))])])]),_c('ClientName',{attrs:{"clientName":_vm.preorder.client.name,"systemColor":_vm.preorder.is_delivery ? 'delivery' : 'preorder'}}),_c('div',{staticClass:"order-details"},[_c('div',{staticClass:"p-0"},[(_vm.preorder.client.phone)?_c('div',[_c('img',{attrs:{"src":!_vm.isPaid
                  ? '/icons/mobile-white.svg'
                  : '/icons/header/phone.svg'}}),_c('p',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.preorder.client.phone))])]):_vm._e(),(_vm.preorder.takeaway_date)?_c('div',[_c('img',{attrs:{"src":!_vm.isPaid ? '/icons/time-white.svg' : '/icons/header/time.svg'}}),_c('p',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.takeawayDateTimeString))])]):_vm._e(),(!_vm.preorder.takeaway_date)?_c('div',[_c('img',{attrs:{"src":!_vm.isPaid ? '/icons/time-white.svg' : '/icons/header/time.svg'}}),_c('p',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.preorderTimeString))])]):_vm._e(),(false)?_c('div',[_c('img',{attrs:{"src":!_vm.isPaid ? '/icons/time-white.svg' : '/icons/header/time.svg'}}),_c('p',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.takeawayDateTimeString))])]):_vm._e(),_c('div',[_c('img',{attrs:{"src":!_vm.isPaid
                  ? '/icons/cutlery-dark.svg'
                  : '/icons/header/cutlery.svg'}}),_c('p',{staticClass:"float-right"},[_vm._v("\n              "+_vm._s(_vm.$tc(
                  "preorder_details.guests_count_suffix",
                  _vm.preorder.guests_count
                ))+"\n            ")])]),_c('div',[_c('img',{attrs:{"src":_vm.preorder.pay_method == 'cash'
                  ? '/icons/header/cash.svg'
                  : '/icons/header/card.svg'}}),_c('p',{staticClass:"float-right"},[_vm._v("\n              "+_vm._s(_vm.$t(("pay_methods." + (_vm.preorder.pay_method) + ".preorder")))+"\n            ")])]),(!_vm.preorder.is_delivery)?_c('div',[_c('img',{attrs:{"src":_vm.preorder.with_reservation
                  ? '/icons/header/reservation.svg'
                  : '/icons/header/takeaway.svg'}}),_c('p',{staticClass:"float-right"},[_vm._v("\n              "+_vm._s(_vm.preorder.with_reservation
                  ? _vm.$t("preorder_bill.with_reservation")
                  : _vm.$t("preorder_bill.with_takeaway"))+"\n            ")])]):_vm._e(),(_vm.addressDisplayText && _vm.preorder.is_delivery)?_c('div',{staticClass:"d-flex"},[_c('img',{attrs:{"src":!_vm.isPaid
                  ? '/icons/address-white.svg'
                  : '/icons/header/house.svg'}}),_c('p',{staticClass:"address text-right"},[_vm._v("\n              "+_vm._s(_vm.addressDisplayText)+"\n            ")])]):_vm._e()])])],1)]),_c('CardList',{attrs:{"list":_vm.preorder.list,"status":"executed","billTotal":_vm.preorder.total,"virtual":_vm.virtual_list}}),_c('div',{staticClass:"total-block"},[_c('h4',{staticClass:"total"},[_vm._v(_vm._s(_vm.$t("list.total")))]),_c('span',{staticClass:"amount"},[_vm._v(_vm._s(_vm.preorder.total)+_vm._s(_vm.currency.symbol))])]),(_vm.bonus_value)?_c('div',{staticClass:"bonus-balance"},[_c('span',{staticClass:"bonus-text"},[_vm._v(_vm._s(_vm.$t("pay_methods.bonus_balance")))]),_c('span',{staticClass:"bonus-value"},[_vm._v("+"+_vm._s(_vm.bonus_value)+_vm._s(_vm.currency.symbol))])]):_vm._e(),(_vm.preorder.status === 'pending')?_c('LongButton',{staticClass:"bill-pay-button",attrs:{"variant":"success"},on:{"click":_vm.onPay}},[_vm._v(_vm._s(_vm.$t("preorder_bill.action_pay")))]):_vm._e(),_c('a',{attrs:{"href":("/api/preorder/cheque/" + (_vm.preorder._id) + "/" + (_vm.$i18n.locale)),"target":"_blank"}},[(_vm.preorder.status === 'confirmed' && _vm.isMobile)?_c('LongButton',{staticClass:"btn-transparent"},[_vm._v(_vm._s(_vm.$t("bill.download_check")))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }