<template>
  <div>
    <div class="hide-content"></div>
    <div class="error-modal">
      <div class="general-popup">
        <img src="/icons/geo-hero.svg" class="hero" />

        <h3>{{ $t(`address.correct_title`) }}</h3>
        <p class="error-msg">{{ $t(`address.correct_example`) }}</p>
        <p class="error-msg color-white">
          {{ $t('address.select_google') }}
        </p>
      </div>
      <LongButton
        variant="primary"
        class="rect-borders mt-20 w-90"
        @click="emitClose"
        >OK</LongButton
      >
    </div>
  </div>
</template>

<script>
import LongButton from "@/components/LongButton";

export default {
  data() {
    return {};
  },
  props: {
    error: {
      type: String,
      default: null,
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
  mounted() {
    if (!this.isMobile) {
      this.$store.commit('desktop/setInfoPopupVariant', 'fix_address');
      this.emitClose();
      return;
    }
    this.toggleGlobalScrolling(true);
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  },
  components: {
    LongButton,
  },
};
</script>

<style scoped>
p,
h3 {
  margin: 0;
  padding: 0;
}

.mt-20 {
  margin-top: 2.5vh;
}

.w-90 {
  width: 90%;
}

.error-modal {
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 2;
  padding: 20px 0 25px;
  position: absolute;
}

img.hero {
  height: 20vh;
  margin-top: 1.2vh;
  width: auto;
}

p.error-msg {
  padding-top: 2.5vh;
  font-size: 1.7vh;
  color: #908f8d;
}

h3 {
  color: #ededed;
  padding-top: 2.2vh;
  font-size: 2.3vh;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  left: 0;
}
</style>