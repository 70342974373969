  <template>
  <div class="delivery-cost-box">
    <span class="title">🛵 {{ $t("delivery_info.title") }}</span>
    <span class="info" v-if="state.type == 'free'">
      {{ $t("delivery_info.free") }}
    </span>
    <span class="info" v-else-if="state.type == 'taxi'">
      {{ $t("delivery_info.taxi") }}
    </span>
    <span class="info" v-if="state.type == 'constant'">
      {{ $t("delivery_info.constant") }}
      <span class="price-value"> {{ state.price }}{{ currency.symbol }} </span>
      ({{ $t(`delivery_info.payment_${state.payment}`) }})
    </span>
  </div>
</template>

  <style scoped>
.delivery-cost-box {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: block;
  padding: 2vh;
  margin: 2.5vh auto 0;
  background: #cbd9e6;
  border-radius: 1.2vh;
}

span.title {
  display: block;
  font-size: 2.3vh;
  line-height: 1;
  color: #1d1d1d;
}

span.info {
  display: block;
  font-size: 2vh;
  padding-top: 2vh;
  line-height: 1;
  color: #1d1d1d;
}

span.price-value {
  color: #129b81;
}
@media screen and(min-width:1024px) {
  .delivery-cost-box {
    padding: 1.6666666666666667vh;
  }
  span.title {
    font-size: 2vh;
    line-height: 2.3333333333333335vh;
  }
  span.info {
    font-size: 1.7777777777777777vh;
    line-height: 132%;
    padding-top: 0.5555555555555556vh;
  }
  span.price-value {
    font-size: 2.222vh;
    line-height: 2.5555555555555554vh;
  }
}
</style>

  <script>
export default {
  props: {
    state: Object,
  },
  computed: {
    currency() {
      return this.$store.state.currency.current;
    },
  },
};
</script>
