<template>
  <div :class="isDarkMode ? 'wrapper dark' : 'wrapper'">
   
    <p class="info" v-if="isPartnerCafe">
      {{ $t("moeco_partner_card.title_delivery") }}
    </p>
    
    <p class="info" v-else v-html="$t('moeco_partner_card.title_normal')"></p>
     <img src="../assets/advertise-text.svg" class="ad-text" v-if="currentLocale !== 'eng'">
     <img src="../assets/advertise-text-eng.svg" class="ad-text eng" v-if="currentLocale == 'eng'">
    <img :src="available_images[image_index]" class="partner" />
    <h4 class="motto">{{ available_mottos[$i18n.locale][motto_index] }}</h4>
    <a
      @click="dispatchAdEvent('contact')"
      href="https://qrwaiter.com.ua/userContent/moeco_contact.vcf"
    >
      <button class="save-contact">
        {{ $t("moeco_partner_card.save_contact") }}
        <img src="../assets/moeco-logo.png" class="logo" />
      </button>
    </a>
  </div>
</template>

<script>
import AdvertisingAPI from "@/api/advertising";

export default {
  props: {
    cafe: Object,
  },
  data() {
    return {
      available_mottos: {
        uk: [
          "Таксі мого міста",
          "Будь ЕСО з МОЕСО",
          "Таксі майбутнього",
          "Екологічне перевезення з винятковими стандартами сервісу",
        ],
        ru: [
          "Такси моего города",
          "Будь ЕСО с МОЕСО",
          "Такси будущего",
          "Экологические перевозки с исключительными стандартами сервиса.",
        ],
        en: [
          "Taxi of my town",
          "Be ЕСО with МОЕСО",
          "Taxi of the future",
          "Ecological transportations with exclusive service standards.",
        ],
      },
      available_images: [
        "/icons/ads/moeco_1.jpg",
        "/icons/ads/moeco_2.jpg",
        "/icons/ads/moeco_3.jpg",
      ],

      motto_index: 0,
      image_index: 0,
    };
  },
  methods: {
    dispatchAdEvent(type) {
      AdvertisingAPI.registerEvent(
        this.cafe._id,
        this.$store.state.clients.client_id,
        "moeco",
        type
      ).catch((error) => {
        console.error(error);
      });
    },
    randomMotto() {
      this.motto_index = Math.floor(
        Math.random() * this.available_mottos.uk.length
      );
    },
    randomImage() {
      this.image_index = Math.floor(
        Math.random() * this.available_images.length
      );
    },
  },
  mounted() {
    this.randomMotto();
    this.randomImage();
    this.dispatchAdEvent("view");
  },
  computed: {
    isPartnerCafe() {
      return this.cafe.delivery.service_tag == "moeco";
    },
    currentLocale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  position: relative;
  padding-bottom: 2.5vh;
  background: #1d1d1d;
}
.wrapper.dark {
  background: #1d1d1d;
}

img.ad-text {
  position: absolute;
  top: 1.2vh;
  z-index: 1;
  width: 95%;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: auto;
}

img.ad-text.eng {
  top:2.5vh
}

p.info {
  color: #908f8d;
  font-size: 2vh;
  position: relative;
  z-index: 2;
  padding: 2.5vh;
  line-height: normal;
  margin: 0;
}
img.partner {
  width: 100%;
  height: 28vh;
  object-fit: cover;
}
h4.motto {
  text-align: center;
  color: #ededed;
  font-size: 2.1vh;
  padding-top: 2.5vh;
  margin: 0;
}

img.logo {
  right: 6vh;
  height: 2.5vh;
  top: 2.3vh;

  width: auto;
  position: absolute;
}

button.save-contact {
  background: #006fa7;
  position: relative;
  color: #ededed;
  font-size: 1.8vh;
  padding: 2.2vh 10vh 2.2vh 2.2vh;
  margin: 3.7vh auto 2.2vh;
  border: none;
  border-radius: 5vh;
  width: 90%;
  display: block;
}
@media screen and (min-width: 1025px) {
  .wrapper {
    border-radius: 2.2222vh;
  }
  .info {
    padding: 2.2222vh;
    font-size: 1.8888888888888888vh;
    line-height: 2.2222vh;
  }
  h4.motto {
    font-size: 1.6666666666666667vh;
    line-height: 1.8888888888888888vh;
    padding-top: 2.4444444444444446vh;
  }
  button.save-contact {
    background: #5395ff;
    font-size: 1.7777777777777777vh;
    line-height: 2vh;
    padding: 1.7777777777777777vh 2.22222vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img.logo {
    display: none;
  }
}
</style>
