<template>
  <div class="future-alert-box">
    <span class="title">
        🕐 {{ $t('future_alert.title') }}
    </span>
    <span class="info">
         {{ $t('future_alert.info', {
             date: formattedDate
         }) }}
    </span>
  </div>
</template>

<style scoped>
.future-alert-box {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: block;
  padding: 2vh;
  margin: auto;
  /* background: #cbd9e6; */
  background: #1d1d1b;
  color: #ededed;
  border-radius: 1.2vh;
}

span.title {
  display: block;
  font-size: 2.3vh;
  line-height: 1;
  /* color: #1d1d1d; */
  color: #ededed;
}

span.info {
  display: block;
  font-size: 2vh;
  padding-top: 2vh;
  line-height: 1;
  /* color: #1d1d1d; */
  color: #ededed;
}

span.price-value {
  color: #129b81;
}


@media screen and(min-width:1024px) {
  .future-alert-box {
    padding: 1.6666666666666667vh;
  }
  span.title {
    font-size: 2vh;
    line-height: 2.3333333333333335vh;
  }
  span.info {
    font-size: 1.7777777777777777vh;
    line-height: 132%;
    padding-top: 0.5555555555555556vh;
  }
  span.price-value {
    font-size: 2.222vh;
    line-height: 2.5555555555555554vh;
  }
}
</style>

<script>
import moment from 'moment'

export default {
  props: {
      date: [String, Date]
  },
  computed: {
    currency() {
      return this.$store.state.currency.current;
    },
    formattedDate() {
        return moment(this.date).format('DD.MM.YYYY HH:mm');
    }
  },
};
</script>
