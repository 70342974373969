<template>
  <div class="client-info">
    <form @submit.prevent="nextStep" v-if="!phone_confirmation">
      <h4>{{ $t('preorder_time_modal.personal_title') }}</h4>
      <input
        type="text"
        v-model="client.name"
        maxlength="100"
        :placeholder="$t('preorder_time_modal.call_you')"
        required
      />
      <input
        type="text"
        placeholder="Ваш номер телефону"
        v-imask="phone_mask"
        @complete="onPhoneMaskComplete"
        :value="client.phone"
        required
      />
      <button class="next" type="submit">{{ $t('desktop_delivery.next') }}</button>
    </form>
    <form @submit.prevent="onConfirmPhone" v-if="phone_confirmation">
      <h4>{{ $t('desktop_delivery.sms_confirm') }}</h4>
      <input
        :class="!validation.confirm ? 'red-input': ''"
        type="text"
        v-model="phone_confirmation_code"
        maxlength="4"
        placeholder="XXXX"
        required
      />
      <button class="next" type="submit" v-if="phone_confirmation_code">
        {{ $t('desktop_delivery.next') }}
      </button>
    </form>
  </div>
</template> 

<style scoped>
* {
  padding: 0;
  margin: 0;
}
h4 {
  font-size: 2.2vh;
}
input {
  margin-top: 1.7vh;
  border: 1px solid #a4a3a1;
  border-radius: 1vh;
  width: 40vh;
  text-align: center;
  background: transparent;
  padding: 1.3vh;
  font-size: 1.8vh;
  outline: none;
}
.client-info {
  padding: 3.3vh 3.3vh 0;
  text-align: center;
  background: #ededed;
}
.next {
  background: #416293;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 1vh;
  color: white;

  padding: 1.8vh 3.5vh;
  margin: 3.3vh auto 0;
  display: block;
  font-size: 2.1vh;
  width: 33.3vh;
  transition: 0.3s;
  outline: none;
  border: none;
}

.next:active {
  box-shadow: none;
  transform: translateY(0.5vh);
}

.red-input {
  border: 2px solid #e2223b !important;
}
</style>

<script>
import { IMaskDirective } from "vue-imask";

import SecurityAPI from '@/api/security';

export default {
  data() {
    return {
      client: {
        name: null,
        phone: null
      },
      // phone_mask: {
      //   mask: "{+38}-000-000-00-00",
      //   lazy: false
      // },
      validation: {
        phone: false,
        confirm: true
      },
      phone_confirmation: false,
      phone_confirmation_token: null,
      phone_confirmation_code: ''
    };
  },
  props: {
    initial_client: Object
  },
  computed: {
    phone_mask() {
      return {
        mask: this.$store.getters["worldwide/phoneMask"],
        lazy: false,
      };
    },
  },
  methods: {
    nextStep() {
      if (!this.validation.phone) return;

      if (this.initial_client.phone == this.client.phone) {
        this.$emit('next', this.client);
        return;
      }
      
      // this.$emit("next", this.client);
      this.phone_confirmation = true;

      SecurityAPI.ask(this.client.phone).then(data => {
        this.phone_confirmation_token = data.token;
      }).catch(error => {
        alert(`Сталася помилка при відправці СМС. Будь-ласка, спробуйте пізніше.`);
      });
    },
    onPhoneMaskComplete(ev) {
      const detail = ev.detail;

      this.client.phone = detail.unmaskedValue;
      this.validation.phone = true;
    },
    onConfirmPhone() {
      if (this.phone_confirmation_code.length < 4) return;

      SecurityAPI.check(
        this.phone_confirmation_token,
        this.phone_confirmation_code
      ).then(data => {
        if (!data.result) {
          this.validation.confirm = false;
          this.phone_confirmation_code = '';
          return;
        }

        this.$emit("next", this.client);
      }).catch(error => {
        this.validation.confirm = false;
        this.phone_confirmation_code = '';
      });
    }
  },
  mounted() {
    if (this.initial_client.name && this.initial_client.phone) {
      this.client.name = this.initial_client.name;
      this.client.phone = this.initial_client.phone;
    }
  },  
  directives: {
    imask: IMaskDirective
  }
};
</script>