<template>
  <div class="leng-change-container">
    <div class="leng-selector-container">
      <div v-for="l in available" :key="l" 
        class="leng-selector-item"
        :class="{
          'active-leng': currentLanguage == l
        }"
        @click="currentLanguage = l">
        {{ name_translations[l] }}
      </div>
    </div>
    <div class="devider"></div>
    <div class="apply-button active-btn" @click="onConfirm">
      {{ button_translations[currentLanguage] || 'Select'}}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentLanguage: 'uk',

      button_translations: {
        uk: 'Обрати',
        en: 'Select',
        ru: 'Выбрать'
      },

      name_translations: {
        en: "English",
        ru: "Русский",
        uk: "Українська",
        be: "Беларуская",
        cs: "čeština",
        de: "Deutsch",
        fr: "Français",
        it: "Italiano",
        sv: 'Svenska',
        no: 'Norsk',
        pt: 'Português',
        hu: "Magyar",
        el: "ελληνικά",
        pl: 'polski',
        nl: 'Nederlands',
        ja: "日本語",
        th: 'ไทย',
        ko: '한국어',
        zh: '中文, 汉语, 漢語',
        hi: 'हिन्दी; हिंदी',
        az: "Azərbaycan dili",
      }
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onSwitch(arg) {
        this.currentLanguage = arg;
    },
    onConfirm() {
      this.$emit("switch", this.currentLanguage);
    }
  },
  computed: {
    available() {
      return this.$store.getters['menu/supportedLocales'];
    }
  },
  mounted() {
    this.currentLanguage = this.$i18n.locale;
  }
}
</script>

<style scoped>
.leng-change-container {
  width: 100vw;
  position: absolute;
  left: 0;
  top: 6.222222222222222vh;
  background: rgba(29, 29, 27, 0.9);
  backdrop-filter: blur(20px);
  border-radius: 0px 0px 2.222vh 2.222vh;
}
.leng-selector-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2.4444444444444446vh 18.555555555555557vh 3.3333vh;
  overflow: scroll;
  max-height: 81.4vh;
}
.leng-selector-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.leng-selector-item {
  cursor: pointer;
  width: 37.22222222222222vh;
  height: 5.555555555555555vh;
  border: 0.1111vh solid #ededed;
  box-sizing: border-box;
  border-radius: 2.7777777777777777vh;
  font-size: 1.7777777777777777vh;
  line-height: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ededed;

  margin-top: 2.2222vh;
}
.active-leng {
  background: #129b81;
  color: #ededed;
  border-color: #129b81;
}
.leng-selector-item:nth-child(3n) {
  margin-right: 0;
}
.devider {
  border-top: 0.1111vh solid #464646;
  width: 76.81%;
  margin: 0 auto 3.3333vh;
}
.apply-button {
  width: 37.22222222222222vh;
  height: 5.555555555555555vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #129b81;
  border-radius: 1.1111vh;
  margin: 0 auto 3.3333333333333335vh;
  font-size: 1.7777777777777777vh;
  line-height: 2vh;
  color: #ededed;
  cursor: pointer;
  opacity: 0.7;
}
.active-btn {
  opacity: 1;
}
</style>
