<template>
  <OrderModalTemplate
    :titleMode="'DELIVERY'"
    :modePicture="'/hero-pics/choose-system.svg'"
    :modeBgColor="'#cbd9e6'"
    :minCheckCost="min_check || null"
    :deliveryState="uiState"
    :cafe="cafe"
    :motd="delivery.motd"
    @close="emitClose"
    v-if="isMobile"
  />

  <SystemPopupDesktop 
    :minCheckCost="min_check || null"
    :deliveryState="uiState"
    :cafe="cafe"
    :motd="delivery.motd"
    @close="emitClose"
    v-else/>
</template>


<script>
import OrderModalTemplate from "@/components/modals/OrderModalTemplate.vue";

import SystemPopupDesktop from "@/components/desktop/SystemPopupDesktop.vue"

export default {
  data() {
    return {};
  },
  props: {
    delivery: Object,
    min_check: Number,
    cost: Number,
    cost_min_check: Number,
    cost_conditional_type: {
      type: String,
      default: "constant",
    },
    cafe: Object,
    cost_type: String,
    individual: String,
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
  components: {
    OrderModalTemplate,
    SystemPopupDesktop
  },
  computed: {
    currency() {
      return this.$store.state.currency.current;
    },
    uiState() {
      if (
        this.delivery.service_tag == "qrwaiter" ||
        this.delivery.service_status != "ready"
      ) {
        if (this.delivery.cost_type == "free")
          return {
            type: "free",
            cost: 0,
          };

        if (this.delivery.cost_type == "taxi") {
          return {
            type: "additional",
            cost: 0,
          };
        }

        if (this.delivery.cost_type == "constant") {
          return {
            type: "constant",
            cost: this.delivery.cost,
          };
        }

        if (this.delivery.cost_type == "conditional") {
          if (this.delivery.cost_conditional_type == "constant") {
            return {
              type: "constant",
              cost: this.delivery.cost,
              min_check: this.delivery.cost_min_check,
            };
          } else {
            return {
              type: "additional",
              cost: this.delivery.cost,
              min_check: this.delivery.cost_min_check,
            };
          }
        }
      } else {
        if (this.delivery.taxi_payer == "client") {
          return {
            type: "constant",
            cost: this.delivery.taxi_cost,
          };
        }

        if (this.delivery.taxi_payer == "cafe") {
          return {
            type: "additional",
          };
        }

        if (this.delivery.taxi_payer == "conditional") {
          if (!this.delivery.cost_min_checks.length) {
            return {
              type: 'additional'
            }
          }

          const max_package = this.delivery.cost_min_checks.slice().pop();

          return {
            type: "constant",
            cost: max_package.price,
            min_check: max_package.limit,
          };
        }
      }
    },
  },
  // mounted() {
  //   this.toggleGlobalScrolling(true);
  // },
  // beforeDestroy() {
  //   this.toggleGlobalScrolling(false);
  // },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

.ok {
  background: #cbd9e6;
  color: #1d1d1b;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  z-index: 5;
  top: 0;
  left: 0;
}

.info {
  position: relative;
  bottom: 0;
}

.pt-20 {
  padding-top: 2.2vh;
}

.c-grey {
  color: #464646;
}

.container-modal {
  position: fixed;
  background: transparent;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
  width: 90vw;
  left: 0;
  margin: 0 auto;
  right: 0;
}

h3 {
  font-size: 6.7vw;
  line-height: 0.8;
}

.option1 {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-size: contain;
  height: auto;
  border-radius: 1.1vh;
  margin: 0 auto;
  background-color: #cbd9e6;
  width: 100%;
  background-repeat: no-repeat;
  position: relative;
}

.option1 img {
  height: 20vh;
  width: auto;
}

.option-title {
  position: relative;
  padding: 0.8vh 5.4vw;
  width: 100%;
  background: #1d1d1b;
}

.option-info {
  position: relative;
  width: 100%;
  padding: 2.7vw 5vw;
}

.option-info.del {
  background: #cbd9e6;
  position: relative;
  border-radius: 0 0 1.1vh 1.1vh;
  bottom: 0;
}

.option-title.del {
  top: 0;
  position: relative;
  z-index: 1;
  color: #cbd9e6;
}

.option-info p {
  line-height: 1.2;
}

@media screen and (min-width: 768px) {
  .option1 {
    min-height: 50vh;
    width: 61vh;
  }
  .option1 img {
    height: 33.5vh;
  }
  .option-info p {
    font-size: 2.2vh;
    color: #1d1d1b;
  }
  .option-info {
    padding: 2.2vh 5.4vh;
  }
  h3 {
    font-size: 4vh;
  }

  .pt-20 {
    padding-top: 1.7vh;
  }

  .option-title {
    padding: 0.8vh 5.4vh;
  }

  .ok {
    width: 60vh;
  }
}
</style>