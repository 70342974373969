<template>
  <div class="desktop-navbar">
    <div class="navbar-section">
      <img
        v-if="(variant != 'marketplace' || showBackButton) && !hideArrowBack"
        class="back-icon"
        @click="
          onBackButtonPressed
        "
        src="/icons/desktop/back-navbar-icon.svg"
        alt=""
      />
      <img
        v-else-if="!statusPresent"
        class="qr-logo-icon"
        src="/icons/logo.svg"
        alt=""
      />
      <div v-else class="waiting-container" @click="$emit('statusClicked')">
        <img src="/icons/desktop/waiting-navbar-icon.svg" alt="" />
        {{ statusTimer || ''}}
      </div>
    </div>
    <div
      v-if="variant != 'marketplace'"
      @click="navbar_extended = !navbar_extended"
      class="navbar-section display-flex-centered"
    >
      <img :src="cafe.image" alt="" class="cafe-image" />
      <div>
        <div class="small-grey-text capitalize">{{ currentSystem }} 

          <span class="color-red" v-if="target && target.demo">&nbsp;DEMO</span>
        </div>
        <div class="big-white-text">{{ cafe.name }}</div>
      </div>
    </div>
    <div v-else class="navbar-section display-flex-column-centered">
      <div
        class="small-grey-text capitalize"
        :class="{
          'tc-green': cityFilter,
        }"
      >
        <img
          src="/icons/desktop/desktop-location-icon.svg"
          alt=""
          v-if="!cityFilter"
        />
        <img src="/icons/desktop/filters-geo-active.svg" alt="" v-else />
        {{ city || "Ужгород" }}
      </div>
      <div class="big-white-text">
        {{ $t("marketplace.list.favorite_cafes") }}
      </div>
    </div>
    <div
      v-if="variant == 'choose_mode'"
      class="navbar-section display-flex-centered-right position-relative"
    ></div>
    <div
      v-else-if="variant == 'cart'"
      class="navbar-section display-flex-centered-right position-relative"
    >
      <img
        class="cart-icon"
        src="/icons/desktop/cart-desktop-icon.svg"
        alt=""
        @click="$emit('openCart')"
        v-show="!hideCart"
      />
      <div class="cart-indicator" v-if="cart.length" v-show="!hideCart">
        {{ cart.length }}
      </div>
    </div>
    <div
      v-else-if="variant == 'marketplace'"
      class="navbar-section display-flex-centered-right position-relative"
    >
      <img
        v-if="is_map_active"
        class="nav-action-icon"
        src="/icons/desktop/map-white-icon.svg"
        @click="$emit('openMap')"
        alt=""
      />
      <img
        v-else
        class="nav-action-icon"
        src="/icons/desktop/map-icon.svg"
        @click="$emit('openMap')"
        alt=""
      /><img
        v-if="are_filters_active"
        class="nav-action-icon"
        src="/icons/desktop/filter-white-icon.svg"
        @click="$emit('openFilters')"
        alt=""
      /><img
        v-else
        class="nav-action-icon"
        src="/icons/desktop/filter-icon.svg"
        @click="$emit('openFilters')"
        alt=""
      />
      <div :class="{ 'search-input': true, 'active-input': is_search_active }">
        <img
          src="/icons/desktop/search-icon.svg"
          @click="is_search_active = true"
          alt=""
        /><img
          v-if="is_search_active"
          @click="onCloseSearch"
          src="/icons/desktop/close-icon.svg"
          alt=""
        /><input
          @blur="onSearchBlur"
          type="text"
          v-model="search"
          @focus="is_search_active = true"
          :placeholder="$t('marketplace.list.search_ph')"
        />
      </div>
    </div>
    <div v-if="navbar_extended" class="black-background">
      <div class="bonus-balance-section">
        <div class="bonus-balance-section-title" v-if="isLoyaltyEnabled">
          <div class="bonus-text">
            {{ $t("pay_methods.bonus_balance") }}
          </div>
          <div
            class="bonus-amount"
            :class="{
              'tc-green': !!bonusBalanceValue,
            }"
          >
            {{ bonusBalanceValue || 0 }} {{ currency.iso_code }}
          </div>
        </div>
        <div class="bonus-balance-section-icons">
          <a :href="`tel:${cafe.contact_phone}`" v-if="cafe.contact_phone"
            ><img
              src="/icons/desktop/phone-nav-icon.svg"
              alt=""
              class="link-icon"
          /></a>
          <a :href="websiteLink" target="_blank" v-if="cafe.social.website">
       <img :src="`/icons/desktop/website-nav-icon.svg`" class="link-icon" />
    </a>
          <a :href="cafe.social.instagram" v-if="cafe.social.instagram"
            ><img
              src="/icons/desktop/instagram-nav-icon.svg"
              alt=""
              class="link-icon"
          /></a>
          <a :href="cafe.social.facebook" v-if="cafe.social.facebook"
            ><img
              src="/icons/desktop/facebook-nav-icon.svg"
              alt=""
              class="link-icon"
          /></a>
          <img
            src="/icons/desktop/google-translate-nav-icon.svg"
            alt=""
            class="link-icon"
            @click="
              show_lang_ui = !show_lang_ui;
              navbar_extended = false;
            "
          />
          <a
            :href="cafe.address_url_override || `https://maps.google.com/?q=${cafe.address}`"
            v-if="cafe.address"
            ><img
              src="/icons/desktop/map-nav-icon.svg"
              alt=""
              class="link-icon"
          /></a>
        </div>
        <a
        :href="offertLink"
        target="_blank"
        class="offert-link"
        v-if="canShowPublicOffert"
      >
        {{ $t("navbar.offert") }}
      </a>
      </div>
      
    </div>
    <div v-if="navbar_extended" class="bg-backdrop" @click="navbar_extended = false"></div> 

    <div v-if="is_order_info_panel_active" class="black-background">
      <div class="order-waiting-info-panel">
        <div class="order-waiting-info-panel-title">
          <div>
            <div class="info-panel-cafe-name">{{ cafe.name }}</div>
            <div class="info-panel-order-mode tc-delivery">delivery</div>
          </div>
          <div class="info-panel-order-id">id: UYNF</div>
        </div>
        <div class="info-panel-separate-row">
          <img src="/icons/desktop/vertical-order-devider-line.svg" alt="" />
        </div>
        <div class="info-panel-row">
          <div>
            <img src="/icons/desktop/plain-icon.svg" alt="" />
            <span class="info-text">Замовлення підтверджено</span>
          </div>
          <div class="titming tc-grey">00:00</div>
        </div>
        <div class="info-panel-separate-row">
          <img
            src="/icons/desktop/vertical-order-devider-dashed-line.svg"
            alt=""
          />
        </div>
        <div class="info-panel-row">
          <div>
            <img src="/icons/desktop/waiting-navbar-icon.svg" alt="" />
            <span class="info-text">Приготування</span>
          </div>
        </div>
      </div>
    </div>
    <LangChange @switch="switchLocale" v-if="show_lang_ui" />
  </div>
</template>
<script>
import GeolocationService from "@/services/geolocation";

import ScheduleService from "@/services/schedule";

import GeoModal from "@/components/GeoModal.vue";

import OrderAPI from "@/api/order";

import Polyglote from "@/services/polyglote";

import LanguageModal from "@/components/LanguageModal.vue";

import CafeSchedule from "@/components/CafeSchedule.vue";

import TelemetryService from "@/services/telemetry";

import LangChange from "@/components/desktop/LangChange.vue";
import OrderStatusNotification from "@/components/OrderStatusNotification.vue";
export default {
  props: {
    cafe: Object,
    menu_type: [String, Number],
    variant: {
      type: String,
      default: "marketplace",
    },
    menu: Object,
    target: Object,
    are_filters_active: Boolean,
    is_map_active: Boolean,
    city: String,
    cityFilter: Boolean,
    statusPresent: Boolean,
    showBackButton: Boolean,
    statusTimer: String,
    hideArrowBack: {
      type: Boolean,
      default: false
    },
    hideCart: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      is_search_active: false,
      is_order_info_panel_active: false,
      search: null,
      navbar_extended: false,
      show_lang_ui: false,
    };
  },
  methods: {
    hideSearch() {
      this.is_search_active = false;
    },
    returnToChooseMode() {
      this.$router.push({
        name: "choose_mode",
        params: {
          code: this.$route.params.code || this.cafe.online_menu_link
        },
      });
    },
    returnToMarketplace() {
      this.$router.push("/marketplace");
    },
    onBackButtonPressed() {
      if (this.showBackButton) {
        this.$emit('back');
      } else {
        if (this.variant == 'cart' && (this.cafe && this.cafe._id != 300 && this.cafe._id != 329)) {
          this.returnToChooseMode();
        } else {
          this.returnToMarketplace();
        }
      }
    },
    switchLocale(locale) {
      const system_language = ["uk", "ru", "en", "cs"].includes(locale)
        ? locale
        : "en";

      Polyglote.updateLocale(system_language);

      this.$i18n.locale = system_language;

      this.show_lang_ui = false;

      TelemetryService.emit(TelemetryService.Events.ChangeLocale, locale);

      this.$store.dispatch("menu/tryLocale", locale);
      this.$store.commit("menu/buildDictionaries");
      this.$store.commit("menu/addBuiltinTranslations", system_language);
    },
    onSearchBlur() {
      if (!this.search) this.is_search_active = false;
    },
    onCloseSearch() {
      this.search = null;
      this.is_search_active = false;
    },
  },
  watch: {
    search() {
      this.$emit("search", this.search);
    },
  },
  computed: {
    cart() {
      return this.$store.state.cart.items;
    },
    currency() {
      return this.$store.state.currency.current;
    },
    currentSystem() {
      if (!this.target) return "";

      return this.target.is_delivery ? "Delivery" : "Takeaway";
    },
    bonusBalanceValue() {
      return this.$store.getters["clients/bonusBalance"];
    },
    canShowPublicOffert() {
      if (this.cafe.pay_methods && this.cafe.pay_methods.online) return true;
      if (
        this.cafe.preorder &&
        this.cafe.preorder.pay_methods &&
        this.cafe.preorder.pay_methods.online
      )
        return true;
      if (
        this.cafe.delivery &&
        this.cafe.delivery.pay_methods &&
        this.cafe.delivery.pay_methods.online
      )
        return true;
      if (
        this.cafe.hotels &&
        this.cafe.hotels.pay_methods &&
        this.cafe.hotels.pay_methods.online
      )
        return true;

      return false;
    },
    offertLink() {
      // const raw = JSON.stringify(this.cafe.bank_details);
      // const value = btoa(raw);
      return `/offert_view/${this.cafe._id}/${this.menu._id}`;
    },
    isLoyaltyEnabled() {
      return (
        this.cafe.loyalty &&
        this.cafe.loyalty.cashback_percent &&
        this.cafe.loyalty.terms_agreed &&
        this.$store.getters["clients/isAuthorized"]
      );
    },
    websiteLink() {
      if (!this.cafe || !this.cafe.social.website) return;

      const v = this.cafe.social.website;

      if (!v.startsWith('https://')) {
        return `https://${v}`;
      } else {
        return v;
      }
    }
  },
  components: {
    LangChange,
    OrderStatusNotification,
  },
};
</script>
<style scoped>
.desktop-navbar {
  width: 100vw;
  height: 6.222222222222222vh;
  background: #1d1d1b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
}
.navbar-section {
  width: 33%;
  cursor: pointer;
}
.display-flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
.display-flex-column-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.display-flex-centered-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.cafe-image {
  width: 8vh;
  height: 4vh;
  border-radius: 0.5555555555555556vh;
  object-fit: cover;
  object-position: center;
  margin-right: 1.1111vh;
}
.small-grey-text {
  font-weight: normal;
  font-size: 1.4444444444444444vh;
  line-height: 1.6666666666666667vh;
  color: #a4a3a1;
  display: flex;
  align-items: center;
}
.small-grey-text img {
  width: 1.3333333333333333vh;
  height: 1.3333333333333333vh;
  margin-right: 0.5555555555555556vh;
}
.big-white-text {
  font-size: 2vh;
  line-height: 2.3333333333333335vh;
  color: #ededed;
}
.capitalize {
  text-transform: capitalize;
}
.back-icon {
  width: 2.777vh;
  height: 2.777vh;
  margin-left: 1.666vh;
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.cart-icon {
  width: 2.4444444444444446vh;
  margin-right: 2.7777777777777777vh;
  z-index: 2;
  cursor: pointer;
}
.cart-indicator {
  position: absolute;
  right: 5.2vh;
  padding: 0 0.4444444444vh 0 0.333333333vh;
  background: #e2223b;
  border-radius: 0.2222222222222222vh 0px 0px 0.2222222222222222vh;
  font-size: 1.2222222222222223vh;
  line-height: 1.4444444444444444vh;
  height: 1.6666666666666667vh;
  display: flex;
  align-items: center;
  color: #ededed;
}
.qr-logo-icon {
  width: 3.333vh;
  margin-left: 3.333vh;
}
.waiting-container {
  display: flex;
  align-items: center;
  font-size: 1.444vh;
  line-height: 1.666vh;
  color: #ededed;
}
.waiting-container img {
  width: 3.333vh;
  margin-left: 3.333vh;
  margin-right: 1.1111vh;
}
.bonus-balance-section {
  position: absolute;
  z-index: 12;
  top: 0;
  border-top: 0.1111vh solid #908f8d;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #1d1d1b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 2.2222vh 2.2222vh;
  padding: 2.444vh 1.666vh 1.666vh;
  width: 56.3vh;
}
.bonus-balance-section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6666666666666667vh;
}
.bonus-text {
  font-size: 1.5555555555555556vh;
  line-height: 1.7777777777777777vh;
  color: #908f8d;
}
.bonus-amount {
  font-weight: normal;
  font-size: 2vh;
  line-height: 132%;
  color: #908f8d;
}
.tc-green {
  color: #129b81;
}
.tc-grey {
  color: #908f8d;
}
.bonus-balance-section-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bonus-balance-section-icons .link-icon {
  width: 4.444444444444445vh;
  height: 4.444444444444445vh;
  cursor: pointer;
}
.black-background {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 6.222222222222222vh;
  z-index: 12;
  width: 100vw;
  height: 93.88888888888888vh;
}
.order-waiting-info-panel {
  width: 48.333vh;
  padding: 1.7777777777777777vh 3.333333vh 2.22222vh;
  background: #1d1d1b;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 10px 0px;
}
.order-waiting-info-panel-title {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1.6666666666666667vh;
}
.info-panel-cafe-name {
  font-size: 2.2222vh;
  line-height: 2.5555555555555554vhd;
  color: #ededed;
}

.info-panel-order-mode {
  font-size: 1.4444444444444444vh;
  line-height: 1.6666666666666667vh;
  text-transform: uppercase;
}
.tc-delivery {
  color: #cbd9e6;
}
.tc-preorder {
  color: #ffd2a8;
}
.tc-reserve {
  color: #aeccc6;
}
.info-panel-order-id {
  font-size: 1.4444444444444444vh;
  line-height: 1.6666666666666667vh;
  color: #908f8d;
}
.info-panel-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4444444444444444vh;
  line-height: 1.6666666666666667vh;
}
.info-panel-row img {
  width: 4.555555555555555vh;
  height: 4.555555555555555vh;
  margin-right: 1.11111vh;
}
.info-panel-row .info-text {
  color: #ededed;
}
.info-panel-row .timing {
  color: #908f8d;
}
.info-panel-separate-row {
  width: 100%;
  padding: 0.4444444444444444vh 1vh;
}
.info-panel-separate-row img {
  width: 2.5555555555555554vh;
  height: 2.5555555555555554vh;
}
.nav-action-icon {
  width: 2.2222vh;
  height: 2.2222vh;
  margin-right: 2.2222vh;
  position: relative;
  cursor: pointer;
}
.search-input {
  position: relative;
  width: 5.555555555555555vh;
  height: 4.444444444444445vh;
  transition: width 0.5s;
  display: flex;
  align-items: center;
}
.search-input input {
  width: 100%;
  height: 100%;
  border-top: 0.1111vh solid #908f8d;
  border-left: 0.1111vh solid #908f8d;
  border-bottom: 0.1111vh solid #908f8d;
  border-right: none;
  border-radius: 1vh 0 0 1vh;
  background: transparent;
  padding-left: 4.4vh;
  padding-right: 3.5vh;
  transition: background 0.5s, border-color 0.5s;
  cursor: pointer;
  font-size: 2vh;
  line-height: 2.4444444444444446vh;
  color: #464646;
}
.search-input input:focus {
  outline-width: 0;
}
.search-input img {
  position: absolute;

  width: 2.22vh;
  cursor: pointer;
}
.search-input img:nth-of-type(1) {
  left: 1.6666666666666667vh;
}
.search-input img:nth-of-type(2) {
  right: 1.1111vh;
}
.active-input {
  width: 35vh;
}
.active-input input {
  background: #ededed;
  border-color: #ededed;
  cursor: text;
}
.offert-link {
  font-size: 1.7vh;
  color: #908f8d;
  margin-top: 1.6vh;
  /* padding-top: 1vh; */
  display: inline-block;
}

.bg-backdrop {
  position: fixed;
  top: 25vh;
  left: 0;
  width: 100vw;
  height: 90vh;
  z-index: 12;
}
</style>
