import io from 'socket.io-client';

let Socket = null;
let Callbacks = {};

function connect(_Callbacks) {
    if (window.location.hostname == 'localhost') {
        Socket = io('localhost:3000/preorder');
    } else {
        Socket = io('/preorder');
    }

    Callbacks = _Callbacks;

    setupEvents();
}

function listen(id) {
    Socket.emit('listen', id);
}

function setupEvents() {
    Socket.on('update', Callbacks.onUpdate);
}

export default {
    connect,
    listen
}