const LS_KEY = 'delivery_cache';

import moment from 'moment'

function get() {
    const data = window.localStorage.getItem(LS_KEY);

    if (!data) return null;

    const obj = JSON.parse(data);

    const then = moment(obj.timestamp);
    const now = moment();

    if (now.diff(then, 'minutes') > 59) {
        window.localStorage.removeItem(LS_KEY);
        return null;
    }

    return obj;
}
function set(client) {
    client.timestamp = Date.now();
    window.localStorage.setItem(LS_KEY, JSON.stringify(client));
}

function clear() {
    window.localStorage.removeItem(LS_KEY);
}

export default {
    get,
    set,
    clear
}