<template>
  <div>
    <div class="modal-box" v-if="!preorder">
      <DesktopOrderHeader
        :current_tab="current_tab"
        @switchTab="onSwitchTab"
        :unlocked_tabs="unlocked_tabs"
        :is_delivery="target.is_delivery"
      />

      <DesktopOrderCart @next="onCartTabNext" v-if="current_tab == 'cart'" />

      <DesktopClientInfo
        :initial_client="client"
        @next="onClientTabNext"
        v-if="current_tab == 'client'"
      />

      <DesktopDeliveryInfo 
        :initial_address="client.address" 
        @next="onFinishForm" 
        :cafe="cafe" 
        @address="onSetAddress"
        :is_delivery="target.is_delivery"
        v-if="current_tab == 'delivery'" />

      <div
        class="error-block"
        v-if="error"
      >На жаль, сталася помилка при відправці замовлення. Будь ласка, спробуйте пізніше або зв'яжіться з тех. підтримкою.</div>
    </div>
    <p class="extra" v-if="!preorder && current_tab !== 'cart'">
      {{$t('preorder.contact_phone')}}
      <a :href="`tel:${contact_phone}`">{{ contact_phone }}</a>
    </p>
    <div class="bill-container" v-if="preorder">
      <PreorderBill
        :preorder="preorder"
        :virtual_list="virtual_list"
        @pay="liq_pay_modal = true"
        :cafe="cafe"
        @save="onBillSave"
      />
      <div class="extra bill-extra">
        {{$t('preorder.contact_phone')}}
        <a :href="`tel:${contact_phone}`">{{ contact_phone }}</a>
        <div v-if="preorder.status == 'confirmed'">
          <div class="color-red" v-if="pay_method == 'cash'">{{ $t('delivery_bill.cash_warning') }}</div>
          <div class="color-red" v-if="isAdditionalDelivery">{{ $t('preorder_bill.taxi_tariff') }}</div>
          <span class="pt-5" v-html="$t('delivery_bill.warning')"></span>
        </div>
        <div v-if="preorder.status == 'pending'">{{ $t('preorder_bill.send_after_payment') }}</div>
      </div>

      <div>
        <img class="sync-qr" :src="syncQrSrc" alt="Scan to sync on mobile">
      </div>
    </div>

    <OnlinePaymentModal
      :order_id="preorder._id"
      mode="preorder"
      @close="liq_pay_modal = false"
      @success="onPayModalSuccess"
      v-if="liq_pay_modal"
    />

    <PandaFatalError :error="$t(`geo.fail_titles.out_of_range`)" v-if="out_of_range" />

    <PandaFatalError :error="$t(`cafe_closed.title`)" v-if="cafe_closed_modal" />

    <img src="/hero-pics/eat.svg" class="hero hero-eat" v-if="current_tab == 'cart'" />
    <img src="/hero-pics/wait.svg" class="hero hero-wait" v-if="current_tab == 'client'" />
    <img src="/hero-pics/skate.svg" class="hero hero-skate" v-if="current_tab == 'delivery'" />
  </div>
</template>

<script>
import DesktopOrderHeader from "@/components/modals/desktop/DesktopOrderHeader";
import DesktopOrderCart from "@/components/modals/desktop/DesktopOrderCart";
import DesktopClientInfo from "@/components/modals/desktop/DesktopClientInfo";
import DesktopDeliveryInfo from "@/components/modals/desktop/DesktopDeliveryInfo";

import PandaFatalError from "@/components/modals/PandaFatalError.vue";

import OnlinePaymentModal from "@/components/modals/OnlinePaymentModal.vue";

import PreorderBill from "@/components/PreorderBill.vue";

import PreorderAPI from "@/api/preorder";
import PositionSaver from "@/services/positionsaver"

import ScheduleService from "@/services/schedule"

export default {
  props: {
    cafe: Object,
    target: Object
  },
  data() {
    return {
      current_tab: "cart",

      client: {
        name: null,
        phone: null,
        address: null
      },

      cart_comment: null,
      takeaway_date: null,
      pay_method: null,
      liq_pay_modal: false,
      cafe_closed_modal: false,

      unlocked_tabs: {
        cart: true,
        client: false,
        delivery: false
      },

      error: null,
      busy: false,

      preorder: null,

      out_of_range: false
    };
  },
  methods: {
    onSwitchTab(target_tab) {
      this.current_tab = target_tab;
    },
    onClientTabNext(_client) {
      this.client.name = _client.name;
      this.client.phone = _client.phone;

      this.onTabUnlock("delivery");
      this.onSwitchTab("delivery");
    },
    onCartTabNext(comment) {
      if (this.cafeClosed) {
        this.cafe_closed_modal = true;
        return;
      }

      this.cart_comment = comment;
      this.onTabUnlock("client");
      this.onSwitchTab("client");
    },
    onFinishForm(data) {
      if (this.busy) return;
      this.client.address = data.address;
      this.takeaway_date = data.takeaway_date;
      this.pay_method = data.pay_method;

      if (this.target.is_delivery) {
        this.checkDeliveryAddress();
      } else {
        this.sendPreorder();
      }
    },
    onTabUnlock(tab) {
      this.unlocked_tabs[tab] = true;
    },
    onPayModalSuccess() {
      this.liq_pay_modal = false;
      this.makePreorderConfirmed();
    },
    makePreorderConfirmed() {
      this.$set(this.preorder, "status", "confirmed");
      PositionSaver.clear("delivery");
    },
    onBillSave() {},
    sendPreorder() {
      const request_data = {
        cart: this.$store.state.cart.items,
        name: this.client.name,
        phone: this.client.phone,
        address: this.client.address,
        is_delivery: this.target.is_delivery,
        cafe_id: this.target.cafe_id,
        takeaway_date: this.takeaway_date,
        comment: this.cart_comment,
        is_in_area: true,
        client_id: this.client.phone
          ? this.$store.state.clients.client_id
          : null
      };

      PreorderAPI.create(request_data)
        .then(data => {
          PreorderAPI.setPayMethod(data.preorder._id, this.pay_method)
            .then(cdata => {
              this.preorder = cdata.preorder;
              this.$emit("hideMenu");
              this.$store.dispatch("cart/clear");

              if (this.pay_method == "online") {
                this.liq_pay_modal = true;
              } else {
                this.makePreorderConfirmed();
              }
            })
            .catch(error => {
              console.error(error);
              this.error = error;
              this.busy = false;
            });
        })
        .catch(error => {
          console.error(error);
          this.error = error;
          this.busy = false;
        });
    },
    checkDeliveryAddress() {
      PreorderAPI.checkDeliveryAddress(this.target.cafe_id, this.client.address)
        .then(data => {
          if (!data.result) {
            this.out_of_range = true;
            return;
          }

          this.sendPreorder();
        })
        .catch(error => {
          alert(`Сталася помилка при перевірці зони доставки.`);
          this.busy = false;
        });
    },
    onSetAddress(addr) {
      this.client.address = addr;
    }
  },
  computed: {
    contact_phone() {
      if (this.cafe.delivery && this.cafe.delivery.contact_phone) {
        return this.cafe.delivery.contact_phone;
      } else {
        return this.cafe.contact_phone;
      }
    },
    isAdditionalDelivery() {
      return this.cafe.delivery.cost_type == "taxi";
    },
    virtual_list() {
      const addtional_delivery = {
        name: this.$t("delivery_bill.delivery_item"),
        price: this.$t("delivery_bill.delivery_additional")
      };

      if (this.isAdditionalDelivery) {
        return [addtional_delivery];
      }

      return [];
    },
    syncQrSrc() {
      const link = `https://app.qrwaiter.com.ua/from_desktop/${this.cafe.online_menu_link}/${this.preorder._id}`;
      return `https://api.qrserver.com/v1/create-qr-code/?size=128x128&data=${link}`;
    },
    cafeClosed() {
      if (!this.cafe.delivery) return true;

      const helper = new ScheduleService.ScheduleHelper(
        this.cafe.schedule
      );

      return !helper.isOpen();
    },
  },
  components: {
    DesktopOrderHeader,
    DesktopOrderCart,
    DesktopClientInfo,
    DesktopDeliveryInfo,
    PreorderBill,
    OnlinePaymentModal,
    PandaFatalError
  }
};
</script>

<style scoped>
.modal-box {
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #ededed;
  border-radius: 2.1vh;
  max-height: 88vh;
  width: 71.5vh;
  padding-bottom: 3.3vh;
  margin-top: 9vh;
}

.extra {
  max-height: 20vh;
  width: 44%;
  margin: 10vh auto !important;
  overflow: auto;
  color: #ededed;
  text-align: center;
  margin: 8vh 0 0 0;
}

.bill-extra {
  margin-top: 10vh !important;
}

.hero {
  position: absolute;
  left: 0;
  bottom: 0;

  height: 26vh;
  width: auto;
}

.error-block {
  color: #e2223b;
  text-align: center;
  margin-top: 1vh;
  padding: 0 3vh;
  font-size: 1.8vh;
}

::-webkit-scrollbar {
  display: none;
}

.sync-qr {
  position: absolute;
  right: 2.5vh;
  bottom: 2.5vh;

  width: 17vh;
  height: 17vh;
}
</style>