<template>
  <div class="desktop-preorder-session">
    <div :class="{ 'cart-container': true, 'active-cart': visible }">
      <div
        class="scrollable-area"
        ref="cartContainer"
        v-if="!preorder || preorder.status == 'pending'"
      >
        <div class="cart-title">
          {{ $t("desktop_delivery.tab_cart") }}
          <img
            @click="$emit('hide')"
            src="/icons/desktop/close-icon.svg"
            alt=""
          />
        </div>
        <div v-if="!cart.length" class="empty-cart">
          <img src="/icons/desktop/panda-error.png" class="empty-cart-panda" />
          <div class="empty-cart-text">
            {{ $t("cart.empty_desktop") }}
          </div>
          <img
            src="/icons/desktop/arrow-large.svg"
            alt=""
            class="large-arrow"
          />
        </div>
        <div v-else>
          <OrderCart
            :cart="cart"
            :target="target"
            :disabled="false"
            :isHotel="false"
            :hideSendButton="true"
            :min_check="cartMinCheck"
            :number="1"
          />
          <div class="preorder-detail-container" v-if="canShowDetails">
            <PreorderDetailsDesktop
              :is_delivery="target.is_delivery"
              @confirm="onDetailsConfirm"
              @paySplit="runNextSplitPayment"
              @phoneConfirm="(t) => (confirming_token = t)"
              :pay_methods="pay_methods"
              :dates="possibleTakeawayTimes"
              :bonus_balance="clientBonusBalance"
              :showBonus="canPayByBonus"
              :cafe="cafe"
              :taxiDelivery="true"
              :deliveryPricingState="deliveryPricingState"
              :show_delivery_asap="!cafeClosed"
              :split_payments="
                preorder ? preorder.split_payments : split_payments
              "
              :split_index="currentSplitIndex"
              ref="detailsDesktop"
            />
          </div>
        </div>
      </div>
      <div class="scrollable-area" v-else>
        <div class="cart-title">
          {{ $t("desktop_delivery.tab_cart") }}
          <img
            @click="$emit('hide')"
            src="/icons/desktop/close-icon.svg"
            alt=""
          />
        </div>
        <PreorderBill :cafe="cafe" :preorder="preorder" @save="onBillSave" />

        <CheckActionButton
          class="bill-group"
          icon="pay-by-card-white"
          variant="filled-green"
          @click="online_pay_modal = true"
          v-if="preorder.pay_method != 'online' && pay_methods.online && !order_status_expired"
        >
          {{ $t("pay_methods.online.long") }}
        </CheckActionButton>

        <CheckActionButton
          class="mt-10"
          icon="desktop/arrow-red"
          variant="outlined-red"
          @click="refuse_modal = true"
          v-if="canCancelOrder"
        >
          {{ $t("actions.cancel_2") }} | {{ cancelOrderTimeString}}
        </CheckActionButton>

        <a
          :href="`/api/preorder/cheque/${preorder._id}/${$i18n.locale}`"
          target="_blank"
        >
          <CheckActionButton
            :class="`${
              preorder.pay_method != 'online' && pay_methods.online
                ? 'mt-10'
                : 'bill-group'
            }`"
            icon="desktop/arrow"
          >
            {{ $t("bill.download_check") }}
          </CheckActionButton>
        </a>

        <CommentField
          class="bill-group"
          v-model="preorder.comment"
          :completed="true"
          v-if="preorder.comment"
        />

        <OrderStatusNotification
          :displayAsBlock="true"
          class="display-as-block"
          ref="orderStatusNotification"
          @expired="cancel_time_left = 0; order_status_expired = true"
          @waiting_time="cancel_time_left = 0"
          v-if="preorder.status == 'confirmed'"
        />

        <ContactIcons :cafe="cafe" class="bill-group" />

        <FeedbackButton
          @click="show_feedback_ui = true"
          v-if="!feedback_sent"
        />

        <!-- <button
          v-if="preorder.status == 'confirmed'"
          class="download-app"
          @click="downloadApp"
        >
          {{ $t("card.download_app") }}
        </button> -->

        <div class="qr-logo-large">
          <img src="/icons/desktop/qr-waiter-logo.svg" alt="" />
        </div>
        <PartnerCard
          class="bill-group"
          :cafe="cafe"
          v-if="target.is_delivery && canShowPartnerCard"
        />
      </div>
    </div>

    <OnlinePaymentModal
      mode="preorder"
      :order_id="preorder._id"
      @close="online_pay_modal = false"
      @success="online_pay_modal = false"
      v-if="online_pay_modal"
    />

    <RateCafePopup
      :mode="modeKey"
      @success="feedback_sent = true"
      @close="show_feedback_ui = false"
      :order_id="preorder._id"
      v-if="show_feedback_ui"
    />

    <PhoneConfirmModal
      :token="confirming_token"
      @confirmed="
        $refs.detailsDesktop.onPhoneConfirmed();
        confirming_token = null;
      "
      @resend="$refs.detailsDesktop.askPhoneConfirmation()"
      @cancel="confirming_token = null"
      v-if="confirming_token"
    />

    <InfoPopupDesktop :variant="uiPopupVariant" v-if="uiPopupVariant" />

    <InfoPopupDesktop 
      @cancel="refuse_modal = false"
      @confirm="cancelPreorder"
      variant="cancel"
      v-if="refuse_modal"/>
  </div>
</template>

<script>
import PreorderDetailsDesktop from "@/components/PreorderDetails.vue";

import OrderStatusNotification from "@/components/OrderStatusNotification.vue";

import OrderCart from "@/components/OrderCart.vue";

import ScheduleService from "@/services/schedule";

import PreorderBill from "@/components/PreorderBill.vue";

import MarketplaceService from "@/services/marketplace";

import PositionSaver from "@/services/positionsaver";

import TelemetryService from "@/services/telemetry";

import CommentField from "@/components/order-details/CommentField.vue";

import OnlinePaymentModal from "@/components/modals/OnlinePaymentModal.vue";

import PreorderAPI from "@/api/preorder";
import PartnerCard from "@/components/PartnerCard.vue";
import BillSaver from "@/services/billsaver";

import FeedbackButton from "@/components/FeedbackButton.vue";
import CafeFeedback from "@/components/CafeFeedback.vue";

import PhoneConfirmModal from "@/components/modals/PhoneConfirmModal.vue";

import PreorderCommunicator from "@/services/preorder/communicator.js";

import ContactIcons from "@/components/order-details/ContactIcons.vue";

import CheckActionButton from "@/components/desktop/CheckActionButtonDesktop.vue";

import RateCafePopup from "@/components/desktop/RateCafePopup.vue";

import InfoPopupDesktop from "@/components/desktop/InfoPopupDesktop.vue";

import moment from "moment";

export default {
  props: {
    visible: Boolean,
    target: Object,
    cafe: Object,
  },
  data() {
    return {
      preorder: null,
      details: {},

      request_data: {
        name: null,
        phone: null,
        takeaway_date: null,
        guests_count: 1,
        address_details: null,
        comment: null,
        address: null,
        source_platform: "web_desktop",
        promocode: null
      },

      selected_pay_method: null,
      online_pay_modal: false,
      show_feedback_ui: false,
      feedback_sent: false,

      confirming_token: false,

      cancel_time_left: 0,
      refuse_modal: false,

      order_status_expired: false,

      busy: false,
    };
  },
  components: {
    PreorderDetailsDesktop,
    OrderCart,
    PreorderBill,
    OrderStatusNotification,
    CommentField,
    OnlinePaymentModal,
    ContactIcons,
    CheckActionButton,
    PartnerCard,
    FeedbackButton,
    CafeFeedback,
    RateCafePopup,
    PhoneConfirmModal,
    InfoPopupDesktop
  },
  watch: {
    cart() {
      if (this.preorder && this.cart.length >= 1) {
        this.preorder = null;
      }
    },
  },
  methods: {
    onDetailsConfirm(params) {
      if (this.busy) return;

      this.request_data.name = params.name;
      this.request_data.phone = params.phone;
      this.request_data.address = params.address;
      this.request_data.takeaway_date = params.takeaway_date;
      this.selected_pay_method = params.pay_method;
      this.request_data.guests_count = params.guests_count;
      this.request_data.with_reservation = params.with_reservation;
      this.request_data.address_details = params.address_details;
      this.request_data.comment = params.comment;
      this.request_data.promocode = params.promocode || null;

      this.selected_pay_method = params.pay_method;

      this.busy = true;

      this.$store.dispatch('clients/create', this.request_data).catch(err => {
        console.error(err);
      }).finally(() => {
        this.sendPreorder();
      });    
    },

    tickCancelTimer() {
      this.cancel_time_left--;

      if (this.cancel_time_left <= 0) return;

      setTimeout(this.tickCancelTimer, 1500);
    },

    onBillSave() {
      const locale = this.$i18n.messages[this.$i18n.locale].download_bill;
      const currency = this.$store.state.currency.current.code;
      BillSaver.generateAndSaveBill(
        this.cafe.name,
        this.preorder,
        locale,
        currency
      );
      // BillSaver.generateAndSaveBill(this.cafe.name, this.preorder);
    },

    onCommunicatorUpdate(preorder) {
      this.preorder = preorder;
      this.$emit("disableScrollSaver");
      PositionSaver.clear("delivery");

      if (this.preorder.status == "confirmed") {
        this.onPreorderConfirmed();
      }
    },

    sendPreorder() {
      const request_data = Object.assign({}, this.request_data);

      request_data.cart = this.cart;
      request_data.is_delivery = !!this.target.is_delivery;
      request_data.cafe_id = this.cafe._id;
      request_data.referral_id = this.getReferralIdOrNull();
      request_data.client_id = this.request_data.phone
        ? this.$store.state.clients.client_id
        : null;
      request_data.marketplace_order =
        !!MarketplaceService && !!MarketplaceService.get()
          ? MarketplaceService.get().flag
          : false;

      PreorderAPI.create(request_data)
        .then((data) => {
          this.preorder = data.preorder;
          //   OrderSaver.set(this.modeKey, this.preorder);
          this.onPreorderSent();
        })
        .catch((error) => {
          console.error(error);
          this.create_error = error;
          this.$store.commit("desktop/setInfoPopupVariant", "fatal_error");
          this.$store.commit("desktop/setPopupError", error);
          this.busy = false;
        })
        .finally(() => {});
    },

    onPreorderConfirmed() {
      this.preorder.status = "confirmed";
      this.busy = false;
      this.$store.dispatch("cart/clear");
      TelemetryService.addMilestone("pay");
      this.$emit("order-closed");
      window.localStorage.setItem(
        "web_status_id",
        `preorder:${this.preorder._id}`
      );

      window.onbeforeunload = function () {
        return "Are you sure you want to leave?";
      };

      this.$nextTick(() => {
        if (this.$refs.cartContainer) this.$refs.cartContainer.scrollTop = 0;
      });

      this.cancel_time_left = 10 * 60;
      this.tickCancelTimer();
    },

    onPreorderSent() {
      PreorderCommunicator.connect({
        onUpdate: this.onCommunicatorUpdate,
      });
      PreorderCommunicator.listen(this.preorder._id);

      PreorderAPI.setPayMethod(this.preorder._id, this.selected_pay_method)
        .then((resp) => {
          this.preorder = resp.preorder;
          if (this.preorder.status == "confirmed") {
            this.onPreorderConfirmed();
          } else {
            this.online_pay_modal = true;
          }
        })
        .catch((error) => {
          // alert(`Помилка :( (${error})`);
          this.$store.commit("desktop/setInfoPopupVariant", "fatal_error");
          this.$store.commit("desktop/setPopupError", error);
        })
        .finally(() => {
          this.busy = false;
        });
    },

    getReferralIdOrNull() {
      const referral_value = window.localStorage.getItem(`last_referral_id`);

      if (referral_value) {
        const tokens = referral_value.split(":");

        if (tokens[0] != this.cafe.online_menu_link) {
          window.localStorage.removeItem("last_referral_id");

          return null;
        }

        return tokens[1];
      }
    },
    downloadApp() {
      window.open('https://app.qrwaiter.com.ua/download', "_blank");
    },
    cancelPreorder(reason) {
      PreorderAPI.cancelPreorder(
        this.preorder._id,
        reason
      ).then(data => {
        this.refuse_modal = false;

        this.cancel_time_left = 0;

        this.$refs.orderStatusNotification.fetchInfo();
      }).catch(err => {
        this.refuse_modal = false;
        this.$refs.orderStatusNotification.fetchInfo();
      });
    }
  },
  computed: {
    cart() {
      return this.$store.state.cart.items;
    },
    modeKey() {
      return this.target.is_delivery ? "delivery" : "preorder";
    },
    cartMinCheck() {
      return this.cafe[this.modeKey].min_check || 0;
    },
    cartTotal() {
      return this.$store.getters["cart/total"];
    },
    canShowDetails() {
      return this.cartTotal >= this.cartMinCheck;
    },
    isLoyaltyEnabled() {
      return (
        this.cafe.loyalty &&
        this.cafe.loyalty.cashback_percent &&
        this.cafe.loyalty.terms_agreed
      );
    },
    cashbackValue() {
      if (!this.isLoyaltyEnabled) return 0;

      const qrwaiter_percent = 1;

      const percent = this.cafe.loyalty.cashback_percent + qrwaiter_percent;

      const total = this.preorder
        ? this.preorder.total
        : this.$store.getters["cart/total"];

      //100 - total
      //percent - x

      return Math.ceil((percent * total) / 100);
    },
    canPayByBonus() {
      if (!this.isLoyaltyEnabled) return false;
      const getter = this.$store.getters["clients/canPayByBonus"];
      const amount = this.$store.getters["cart/total"];

      return getter(amount);
    },
    deliveryPricingState() {
      if (!this.target.is_delivery) return null;

      let total = 0;

      if (this.preorder && this.preorder.total) {
        total = this.preorder.total;
      } else {
        total = this.$store.getters["cart/total"];
      }

      if (
        this.cafe.delivery.service_tag == "qrwaiter" ||
        this.cafe.delivery.service_status != "ready"
      ) {
        if (this.cafe.delivery.cost_type == "free") {
          return {
            type: "free",
          };
        }

        if (this.cafe.delivery.cost_type == "constant") {
          return {
            type: "constant",
            price: this.cafe.delivery.cost,
            payment: this.cafe.delivery.add_cost_to_check ? "check" : "receive",
          };
        }

        if (this.cafe.delivery.cost_type == "taxi") {
          return {
            type: "taxi",
          };
        }

        if (this.cafe.delivery.cost_type == "conditional") {
          if (total >= this.cafe.delivery.cost_min_check) {
            return {
              type: "free",
            };
          } else {
            return {
              type: this.cafe.delivery.cost_conditional_type,
              price: this.cafe.delivery.cost,
              payment: this.cafe.delivery.add_cost_to_check
                ? "check"
                : "receive",
            };
          }
        }
      } else {
        if (this.cafe.delivery.taxi_payer == "client") {
          return {
            type: "constant",
            price: this.cafe.delivery.taxi_cost,
            payment: "receive",
          };
        }

        if (this.cafe.delivery.taxi_payer == "cafe") {
          return {
            type: "free",
          };
        }

        if (this.cafe.delivery.taxi_payer == "conditional") {
          const fitting_cost = this.cafe.delivery.cost_min_checks.find(
            (c) => total < c.limit
          );

          return {
            type: !fitting_cost ? "free" : "constant",
            payment: "receive",
            price: fitting_cost ? fitting_cost.price : 0,
          };
        }
      }
    },
    schedule() {
      return this.cafe[this.modeKey].schedule || this.cafe.schedule;
    },
    cafeClosed() {
      if (!this.cafe.schedule) return true;

      const helper = new ScheduleService.ScheduleHelper(this.schedule);

      if (!helper.isOpen()) return true;

      return !helper.isOpenTimeLimit(
        this.cafe[this.modeKey].close_time_limit || 0
      );
    },
    pay_methods() {
      const container = this.cafe[this.modeKey];

      if (!container.pay_methods) {
        return {
          cash: false,
          card: false,
          online: false,
        };
      }

      return container.pay_methods;
    },
    possibleTakeawayTimes() {
      let now = moment();

      if (this.target.is_delivery && this.cafe.delivery.is_scheduled) {
        return this.scheduleDeliveryTimes;
      }

      if (this.target.is_delivery) {
        const min_delivery_time = this.cafe.delivery.min_order_time;
        const remainder = 5 - (now.minutes() % 5);
        now = moment(now).add(min_delivery_time + remainder, "minutes");
      } else {
        //Preorders minimal time can be set up in cafe settings
        let min_order_time = 30;
        if (this.cafe.preorder && this.cafe.preorder.min_order_time) {
          min_order_time = this.cafe.preorder.min_order_time;
        }

        now = moment(now).add(min_order_time, "minutes");
      }

      if (this.cafeClosed) {
        let helper = new ScheduleService.ScheduleHelper(
          this.schedule,
          now.valueOf()
        );

        let loop_limit = 100;

        while (!helper.isOpen() && loop_limit > 0) {
          now = moment(now).add(1, "hour");

          helper = new ScheduleService.ScheduleHelper(
            this.schedule,
            now.valueOf()
          );

          loop_limit--;
        }
      }

      //18:56
      //56 % 10 = 6
      // now.minutes(now.minutes() + (10 - (now.minutes() % 10)));
      const max_date = moment(now).add(1, "days");
      let times = [];

      const helper = new ScheduleService.ScheduleHelper(
        this.schedule,
        now.valueOf()
      );

      const end_moment = moment(helper.endMoment).subtract(
        this.cafe[this.modeKey].close_time_limit || 0,
        "minutes"
      );

      while (now.isBefore(end_moment)) {
        times.push(now.toDate());
        now.add(10, "minutes");
      }

      if (!this.cafeClosed) {
        const nextday_helper = new ScheduleService.ScheduleHelper(
          this.schedule,
          moment().add(1, "day")
        );

        if (nextday_helper.isWorkday()) {
          let nextday_now = moment(nextday_helper.startMoment).add(1, "hour");

          while (nextday_now.isBefore(moment(nextday_helper.endMoment))) {
            times.push(nextday_now.toDate());
            nextday_now.add(10, "minutes");
          }
        }
      }

      return times;
    },
    canShowPartnerCard() {
      return ["Ужгород", "Мукачево"].includes(this.cafe.city);
    },
    uiPopupVariant() {
      return this.$store.state.desktop.info_popup_variant;
    },
    cancelOrderTimeString() {
      if (!this.canCancelOrder) return '';

      return moment.duration(
        this.cancel_time_left * 1000
      ).format('mm:ss');

    },
    canCancelOrder() {
      return this.cancel_time_left > 0 && this.preorder.status == 'confirmed';
    }
  },
};
</script>

<style scoped>
.display-as-block {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 0 auto;
  border-radius: 1.2vh;
  top: 0;
  margin-top: 2.5vh;
}

.download-app {
  width: 100%;
  margin: 5vh auto 0;
  display: block;
  color: #ededed;
  padding: 1.6vh 0;
  font-size: 2vh;
  border-radius: 5vh;
  border: none;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  background: #1d1d1b;
}

.display-as-block div.order-status-notif {
  width: 100%;
}

.small-width {
  width: 70.83%;
}
.scroll-container {
  position: relative;
  overflow: scroll;
  margin: 0 11.11111111111111vh;
  border-radius: 1.3333333333333333vh;
  max-height: 93.77777777777777vh;
}
.scroll-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.mb-20 {
  margin-bottom: 2.222vh;
}
.row-desktop {
  width: 100%;
  position: relative;
}
.cart-container {
  position: fixed;
  right: 0;
  top: 6.222222222222222vh;
  z-index: 13;
  border-left: 0.1111vh solid #c8c8c8;
  width: 29.17%;
  transform: translateX(100%);
  transition: transform 0.5s;
  min-height: 93.77777777777777vh;

  box-sizing: border-box;
  background: #ededed;
}
.cart-title {
  font-size: 3.3333333333333335vh;
  line-height: 3.888888888888889vh;
  color: #1d1d1b;
  padding: 2.2222vh 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart-title img {
  width: 3.3333333333333335vh;
  cursor: pointer;
}
.active-cart {
  transform: translateX(0%);
  transition: transform 0.5s;
}
.scrollable-area {
  height: 93.7vh;
  width: 100%;
  overflow: scroll;
  padding: 0 3.33333vh 2.2222vh;
}
.scrollable-area::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.empty-cart-panda {
  width: 37.77777777777778vh;
  margin-top: 2.2222vh;
}
.empty-cart-text {
  width: 100%;
  font-size: 2.7777777777777777vh;
  line-height: 3.2222222222222223vh;
  color: #1d1d1b;
  margin-top: 2.2222vh;
}
.large-arrow {
  width: 100%;
  height: 3.111vh;
}
.bill-group {
  margin-top: 3.3vh;
}
.qr-logo-large {
  width: 100%;
  height: 16.666666666666668vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.33333vh;
}
.qr-logo-large img {
  width: 5.555555555555555vh;
  height: 5.555555555555555vh;
}
</style>
