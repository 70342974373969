<template>
  <div>
    <div class="hide-content"></div>
    <div class="container-modal">
      <div class="option2">
        <img src="/hero-pics/preorder.png">
        <div class="info">
        <div class="option-title pre">
          <h3>TAKEAWAY</h3>
        </div>
        <div class="option-info pre color-black">
          <p>{{ $t('preorder_modes.normal_desc') }}</p>
        </div>
        </div>
      </div>

      <LongButton class="preorder-btn" @click="emitClose">OK</LongButton>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  methods: {
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  z-index: 2;
  top: 0;
  left: 0;
}

.container-modal {
  position: absolute;
  background: transparent;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
  width: 90vw;
  left: 0;
  margin: 0 auto;
  right: 0;
}

.preorder-btn {
  background: #FFD2A8;
  color: #1d1d1b;
}

h3 {
  font-size: 6.7vw;
  line-height: 0.8;
}

.option2 {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-size: contain;
  min-height: 35vh;
  border-radius: 1.1vh;
  background-repeat: no-repeat;
  margin: 0 auto;
  background-color: #FFD2A8;
  width: 89.3vw;
  position: relative;
  z-index: 2;
 
}

.info {
  position: relative;
    bottom: 0;
}


.option-title {
  position: relative;
  padding: 0.8vh 5.4vw;
  width: 100%;
  background: #1d1d1b;
}

.option-info {
  position: relative;
  width: 100%;
  padding: 2.5vh;
}

.option2 img {
  height: 20vh;
  width: auto;
}

.option-info.pre {
  position: relative;
  border-radius: 0 0 1.1vh 1.1vh;
  bottom: 0;
  background: #FFD2A8;
}

.option-title.pre {
  position: relative;
  top: 0;
  z-index: 2;
  color: #ffd2a8;
}

.option-info p {
  line-height: 1.2;
}

@media screen and (min-width: 768px) {
  .option2 {
    min-height: 50vh;
    width: 61vh;
  }
  .option2 img {
    height: 33.5vh;
  }
  .option-info p {
    font-size: 2.2vh;
    color: #1d1d1b;
  }
  .option-info {
    padding: 2.2vh 5.4vh;
  }
  h3 {
    font-size: 4vh;
  }

  .pt-20 {
    padding-top: 1.7vh;
  }

  .option-title {
    padding: 0.8vh 5.4vh;
  }

  .preorder-btn {
    border-radius: 5vh;
    font-size: 3vh;
    width: 60vh;
  }
}
</style>