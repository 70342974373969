<template>
  <div class="preorder-bill">
    <div :class="headerClass">
      <div class="header-info">
        <div class="order-id">
          <h3>{{ preorder.is_delivery ? "DELIVERY" : "TAKEAWAY" }}</h3>
          <span>
            id:
            <h3 class="d-inline">{{ preorder.local_id }}</h3>
          </span>
        </div>

        <!-- <div :class="clientNameClass">
          <h4>{{preorder.client.name}}</h4>
        </div> -->
        <ClientName
          :clientName="preorder.client.name"
          :systemColor="preorder.is_delivery ? 'delivery' : 'preorder'"
        />
        <div class="order-details">
          <div class="p-0">
            <div v-if="preorder.client.phone">
              <img
                :src="
                  !isPaid
                    ? '/icons/mobile-white.svg'
                    : '/icons/header/phone.svg'
                "
              />
              <!-- <p class="order-info">{{$t('preorder_bill.phone')}}</p> -->

              <p class="float-right">{{ preorder.client.phone }}</p>
            </div>

            <div v-if="preorder.takeaway_date">
              <img
                :src="
                  !isPaid ? '/icons/time-white.svg' : '/icons/header/time.svg'
                "
              />
              <!-- <p class="order-info">{{ $t('preorder_bill.takeaway') }}</p> -->
              <p class="float-right">{{ takeawayDateTimeString }}</p>
            </div>
            <div v-if="!preorder.takeaway_date">
              <img
                :src="
                  !isPaid ? '/icons/time-white.svg' : '/icons/header/time.svg'
                "
              />
              <!-- <p class="order-info">{{ $t('preorder_bill.time') }}</p> -->
              <p class="float-right">{{ preorderTimeString }}</p>
            </div>
            <div v-if="false">
              <img
                :src="
                  !isPaid ? '/icons/time-white.svg' : '/icons/header/time.svg'
                "
              />
              <!-- <p class="order-info">{{ $t('preorder_bill.takeaway') }}</p> -->
              <p class="float-right">{{ takeawayDateTimeString }}</p>
            </div>
            <div>
              <img
                :src="
                  !isPaid
                    ? '/icons/cutlery-dark.svg'
                    : '/icons/header/cutlery.svg'
                "
              />
              <!-- <p class="order-info">{{ $t('preorder_bill.date') }}</p> -->
              <p class="float-right">
                {{
                  $tc(
                    "preorder_details.guests_count_suffix",
                    preorder.guests_count
                  )
                }}
              </p>
            </div>
            <div>
              <img
                :src="
                  preorder.pay_method == 'cash'
                    ? '/icons/header/cash.svg'
                    : '/icons/header/card.svg'
                "
              />
              <!-- <p class="order-info">{{ $t('preorder_bill.date') }}</p> -->
              <p class="float-right">
                {{ $t(`pay_methods.${preorder.pay_method}.preorder`) }}
              </p>
            </div>
            <div v-if="!preorder.is_delivery">
              <img
                :src="
                  preorder.with_reservation
                    ? '/icons/header/reservation.svg'
                    : '/icons/header/takeaway.svg'
                "
              />
              <!-- <p class="order-info">{{ $t('preorder_bill.date') }}</p> -->
              <p class="float-right">
                {{
                  preorder.with_reservation
                    ? $t(`preorder_bill.with_reservation`)
                    : $t(`preorder_bill.with_takeaway`)
                }}
              </p>
            </div>
            <div
              class="d-flex"
              v-if="addressDisplayText && preorder.is_delivery"
            >
              <img
                :src="
                  !isPaid
                    ? '/icons/address-white.svg'
                    : '/icons/header/house.svg'
                "
              />
              <!-- <p class="order-info">{{$t('preorder_bill.address')}}</p> -->
              <p class="address text-right">
                {{ addressDisplayText }}
              </p>
            </div>
            <!-- <div class="d-flex" v-else-if="addressDisplayText && !preorder.is_delivery">
              <img :src="!isPaid ? '/icons/address-white.svg':'/icons/header/house.svg'" />
              <p class="order-info">{{$t('preorder_bill.address')}}</p>
              <p class="address text-right">
                <a class="address-link" target="_blank" :href="`https://maps.google.com/?q=${cafe.address}`">
                  {{ addressDisplayText }}
                </a>
                
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <CardList
      :list="preorder.list"
      status="executed"
      :billTotal="preorder.total"
      :virtual="virtual_list"
    />

    <div class="total-block">
      <h4 class="total">{{ $t("list.total") }}</h4>
      <span class="amount">{{ preorder.total }}{{ currency.symbol }}</span>
    </div>

    <div class="bonus-balance" v-if="bonus_value">
      <span class="bonus-text">{{ $t("pay_methods.bonus_balance") }}</span>
      <span class="bonus-value">+{{ bonus_value }}{{ currency.symbol }}</span>
    </div>

    <!-- <div class="comment" v-if="preorder.comment">
      <h5 class="color-grey m-0">{{ $t('cart.comment')}}</h5>
      <p class="mb-0">{{ preorder.comment }}</p>
    </div> -->

    <!-- <div class="cashback-value" v-if="bonus_balance != null && preorder.status != 'confirmed'">
      {{ $t('card.bonus_balance', {
          balance: bonus_balance
        }) }}
    </div>   -->

    <LongButton
      class="bill-pay-button"
      variant="success"
      @click="onPay"
      v-if="preorder.status === 'pending'"
      >{{ $t("preorder_bill.action_pay") }}</LongButton
    >

    <a :href="`/api/preorder/cheque/${preorder._id}/${$i18n.locale}`" target="_blank">
    <LongButton
      class="btn-transparent"
      
      v-if="preorder.status === 'confirmed' && isMobile"
      >{{ $t("bill.download_check") }}</LongButton
    >
    </a>
  </div>
</template>

<script>
import CardList from "@/components/card/CardList.vue";

import ClientName from "@/components/order-details/ClientName.vue";

import moment from "moment";

export default {
  props: {
    cafe: Object,
    preorder: Object,
    virtual_list: {
      type: Array,
      default: () => [],
    },
    bonus_balance: {
      type: Number,
      default: null,
    },
    bonus_value: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    onPay() {
      this.$emit("pay");
    },
    onSave() {
      this.$emit("save");
    },
  },
  computed: {
    isPaid() {
      return this.preorder.status == "confirmed";
    },
    status() {
      if (this.preorder.status === "pending")
        return this.$t("card.status_paying");
      if (this.preorder.status === "confirmed")
        return this.$t("card.status_executed");

      return "???";
    },
    headerClass() {
      const suffix = this.preorder.is_delivery ? "delivery" : "preorder";
      return ["header", `header-${this.preorder.status}-${suffix}`];
    },
    clientNameClass() {
      return {
        "client-name": true,
        "client-confirmed-preorder": this.isPaid && !this.preorder.is_delivery,
        "client-confirmed-delivery": this.isPaid && this.preorder.is_delivery,
      };
    },
    takeawayDateTimeString() {
      return moment(this.preorder.takeaway_date).tz(this.cafe.timezone).format("HH:mm");
    },
    takeawayDateString() {
      if (!this.preorder.takeaway_date) {
        return moment().format("DD.MM.YY");
      }
      return moment(this.preorder.takeaway_date).tz(this.cafe.timezone).format("DD.MM.YY");
    },
    preorderDateString() {
      return moment(this.preorder.created_at).format("DD.MM.YYYY");
    },
    preorderTimeString() {
      return moment(this.preorder.created_at).format("HH:mm");
    },
    currency() {
      return this.$store.state.currency.current;
    },
    addressDisplayText() {
      if (this.preorder.is_delivery) {
        const details_string = this.preorder.address_details
          ? `(${this.preorder.address_details})`
          : "";
        return `${this.preorder.client.address} ${details_string}`;
      } else {
        if (!this.cafe.address) return null;
        return this.cafe.address
          .split(",")
          .slice(0, 3)
          .join(",");
      }
    },
  },
  components: {
    CardList,
    ClientName,
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

.bonus-balance {
  padding: 1.2vh 5.4vw 1.2vh;
  border-bottom: 1px solid #bfbfbf;
  font-size: 2vh;
}

.bonus-balance span {
  color: #908f8d;
  text-transform: uppercase;
}
span.bonus-value {
  float: right;
  display: block;
  line-height: normal;
  color: #129b81;
  font-size: 2.2vh;
}

span.value {
  color: #129b81;
  float: right;
  line-height: normal;
}

.comment {
  margin-top: 2.5vh;
  padding: 0 5.4vw;
}

.comment p {
  padding: 1.3vh 4vw 0;
  margin-bottom: 0;
  font-size: 4.5vw;
}

.order-details {
  padding: 1.2vh 5.5vw 2.5vh;

  padding-top: 0;
}

.order-details div {
  padding-top: 1.9vh;
}

p.order-info {
  line-height: normal;
  display: inline-block;
}

p.float-right {
  line-height: 0.8;
  display: inline-block;
  padding-top: 0.6vh;
}

h3 {
  font-size: 4vh;
  line-height: 0.8;
}

p.address {
  line-height: initial;
  width: 100%;
  padding-left: 2vh;
}

.client-name {
  /* width: 100%; */
  background: #ededed;
  color: #1d1d1b;
  font-size: 6.7vw;
  padding: 1.3vh 5.4vw;
}

.client-confirmed-preorder {
  color: #ffd2a8;
  background: #1d1d1b;
}

.client-confirmed-delivery {
  color: #cbd9e6;
  background: #1d1d1b;
}

.client-name h4 {
  line-height: 0.8;
}

.order-id span {
  width: 100%;
  text-align: right;
  font-size: 4vw;
}

.header-pending-delivery,
.header-pending-preorder {
  background-color: #1d1d1b;
  color: #ededed;
}

.header-confirmed-preorder {
  background-color: #ffd2a8;
}

.header-confirmed-delivery {
  background-color: #cbd9e6;
}

.header {
  border-radius: 2.5vh 2.5vh 0 0;
}

.header img {
  height: 2.3vh;
  width: auto;
}

.order-id {
  display: flex;
  justify-content: space-between;
  padding: 2.7vh 2.5vh 2.5vh;
}

.preorder-bill {
  width: 90%;
  margin: 0 auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #ededed;
  border-radius: 5vh;
  padding-bottom: 3.7vh;
}

.btn-transparent {
  box-shadow: none;
  background: transparent;
  border: 1px solid #1d1d1d;
  color: #1d1d1d;
}

.total-block {
  padding: 0 5.4vw;
  margin-top: 2.5vh;
}

.total {
  font-weight: bold;
  display: inline-block;
  margin: 0 !important;
  font-size: 6.5vw;
  text-align: initial;
  padding-top: 0.9vh;
  line-height: 0.8;
}

span.amount {
  float: right;
  margin-top: 0vh;
  line-height: 0.8;
  font-size: 9.6vw;
  font-weight: 500;
}

.cashback-value {
  text-align: center;
  margin-top: 2.5vh;
}

.bill-pay-button {
  margin-top: 2.5vh;
}

.address-link {
  text-decoration: underline !important;
  color: #1d1d1b;
}

@media screen and (min-width: 768px) {
  .preorder-bill {
  
    overflow: auto;
    border-radius: 2vh;
    /* top: 8vh; */
    /* position: stat; */
  }
  .header img {
    margin-right: 1.1vh;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .comment {
    margin: 0;
    padding: 3.3vh 3.3vh 0;
  }
  .comment h5 {
    font-size: 2vh;
  }
  .comment p {
    font-size: 1.8vh;
    padding: 0 1.5vh;
  }
  .header {
    border-radius: 2vh 2vh 0 0;
  }
  .generic-button {
    width: 34vh;
    border-radius: 1vh;
    font-size: 2vh;
    padding: 1.7vh;
  }
  .total-block {
    padding: 3.3vh 1.66vh 0;
    margin: 0;
  }
  span.amount {
    font-size: 4vh;
  }
  .total {
    font-size: 2.7vh;
  }
  .order-id {
    padding: 3.3vh;
  }
  .order-details {
    padding: 0 3.3vh 1.7vh;
  }
  .order-details div {
    padding: 1.7vh 0 0;
  }
  .client-name {
    padding: 1.3vh 3.3vh;
  }
  .order-id span {
    position: relative;
    text-align: right;
    font-size: 1.7vh;
    padding-right: 0;
    line-height: 0.8;
  }
  .client-name h4 {
    font-size: 2.8vh;
  }
  .header img {
    height: 2.6vh;
  }
  p.order-info {
    font-size: 1.8vh;
  }
  h3 {
    font-size: 4.2vh;
  }
}
@media screen and (min-width: 1025px) {
  .preorder-bill {
    width: 100%;
    height: auto;
    overflow: auto;
    border-radius: 2.2222222222222223vh;
    margin-top: 2.2222vh;
    position: static;
  }
  .order-id {
    font-size: 3.3333333333333335vh;
    padding: 2.7777777777777777vh 2.22222vh 2.5vh;
  }
  .order-id span {
    font-size: 1.6666666666666667vh;
  }
  .client-name {
    padding: 0.8888888888888888vh 2.2222vh;
  }
  .order-details {
    padding: 0 2.222vh 2.222vh;
  }
  .order-details div {
    padding-top: 2.2222vh;
    align-items: center;
  }
  .header img {
    height: 2vh;
    width: 2vh;
  }
  p.float-right {
    font-size: 1.7777777777777777vh;
    line-height: 2vh;
    padding: 0;
  }
  h3.d-inline {
    /* font-size: 3.33333vh; */
    font-size: 4.2vh;
    line-height: 70%;
  }
}
</style>
