<template>
  <div>
    <div
      :class="{
        'preorder-session-wrapper': !phone_confirm_ui,
      }"
      id="liqpayHideTarget"
      v-show="!isPopupOverlaying"
    >
      <AIModal
        class="preorder-ai-modal"
        :target="target"
        @closeAI="closeAI"
        v-if="showAIModal"
      />

      <div v-if="!preorder || preorder.status == 'pending'">
        <OrderCart
          :cart="cart"
          :target="target"
          :disabled="busy"
          :number="1"
          :min_check="min_check"
          :cashback="cashbackValue"
          :freeze="currentSplitIndex"
          @updated="onCartUpdated"
          @send="onCartSend"
          @setComment="(c) => (cart_comment = c)"
          :hideSendButton="true"
          v-if="cart && cart.length > 0"
        />

        <PreorderDetails
          :is_delivery="target.is_delivery"
          @confirm="onDetailsConfirm"
          @paySplit="runNextSplitPayment"
          @phoneConfirmUI="(v) => (phone_confirm_ui = v)"
          :pay_methods="pay_methods"
          :dates="possibleTakeawayTimes"
          :bonus_balance="clientBonusBalance"
          :showBonus="canPayByBonus"
          :cafe="cafe"
          @addressChanged="estimateDeliveryToAddress"
          :taxiDelivery="shouldShowTaxiWarning"
          :estimated_packaging_cost="estimated_packaging_cost"
          :deliveryPricingState="deliveryPricingState"
          :show_delivery_asap="!cafeClosed"
          :split_payments="preorder ? preorder.split_payments : split_payments"
          :split_index="currentSplitIndex"
          v-if="cart && cart.length > 0 && isTotalBiggerMinCheck"
        />

        <div class="empty-cart" v-thematic v-if="!cart || cart.length == 0">
          <img src="../assets/error-hero.svg" />
          <h3>{{ $t("cart.empty") }}</h3>
        </div>
      </div>

      <div v-if="preorder && preorder.status != 'pending'">
        <FutureOrderAlert class="future-order-alert-wrapper" :date="preorder.delayed_to" v-if="preorder.delayed_to"/>

        <PreorderBill
          :cafe="cafe"
          :preorder="preorder"
          @pay="showPayUI"
          @save="onBillSave"
          :virtual_list="virtual_list"
          :bonus_balance="clientBonusBalance"
          :bonus_value="cashbackValue"
        />

        <div class="space"></div>

        <div v-if="preorder.status === 'pending'">
          <LongButton class="edit-info" v-thematic @click="onEdit">{{
            $t("preorder.edit")
          }}</LongButton>
        </div>

        <div v-if="canCancelOrder">
          <LongButton
            class="btn-unique"
            variant="red-outline"
            v-thematic
            @click="refuse_modal = true"
            >{{ $t("actions.cancel_2") }} | {{ cancelOrderTimeString }}</LongButton
          >
        </div>

        <div v-if="canPostPayOnline">
          <LongButton
            class="btn-unique"
            variant="success"
            v-thematic
            @click="pay_modal = true"
            >{{ $t("pay_methods.online.long") }}</LongButton
          >
        </div>

        <!-- <div class="preorder-warning color-black pt-5" v-if="clientBonusBalance && preorder.status != 'confirmed'">

      </div>-->

        <!-- <div
        v-if="!is_delivery && preorder.status == 'confirmed'"
        class="preorder-warning color-black"
      >
        <p class="pt-5" v-thematic v-html="$t('preorder_bill.warning')"></p>
      </div>-->

        <!-- <div class="chequeue-comment" v-if="preorder.comment">
        <h4>{{ $t("cart.comment") }}</h4>
        <p v-thematic class="info">
          {{ preorder.comment }}
        </p>
      </div> -->
        <CommentField
          class="chequeue-comment"
          v-model="preorder.comment"
          :completed="true"
          v-if="preorder.comment"
        />

        <DeliveryInfo
          class="w-90"
          :state="deliveryPricingState"
          v-if="deliveryPricingState"
        />

        <OrderStatusNotification
          class="display-as-block"
          ref="orderStatusNotification"
          @expired="order_status_expired = true"
          @waiting_time="cancel_time_left = 0"
          v-if="preorder.status == 'confirmed'"
        />
        <!-- <div class="delivery-price-row" v-if="deliveryPriceUi">
        <span
          :class="
            isDarkMode ? 'delivery-row-title c-white' : 'delivery-row-title'
          "
        >
          {{ $t("delivery_bill.delivery_cost_upper") }}
        </span>
        <span
          :class="
            isDarkMode ? 'delivery-row-value c-white' : 'delivery-row-value'
          "
          >{{ deliveryPriceUi }}</span
        >
      </div> -->

        <!-- <p
        class="text-center d-block color-grey contact delivery-warn"
        v-thematic
        v-if="shouldShowTaxiWarning"
      >
        {{ $t("delivery_bill.cost_by_receive") }}
      </p> -->

        <!-- <p
        class="text-center d-block color-red contact"
        v-thematic
        v-if="shouldShowTaxiWarning"
      >{{ $t('preorder_bill.taxi_tariff') }} {{ additionalDeliveryPrice }}</p>-->

        <FeedbackButton v-if="!feedback_sent" @click="show_feedback = true" />

        <div
          v-if="is_delivery && preorder.status == 'confirmed' && false"
          class="preorder-warning color-black"
        >
          <!-- feedback -->

          <!-- <p
          class="сash-warning pt-5 color-red"
          v-thematic
          v-if="preorder.status === 'confirmed' && preorder.pay_method == 'cash'"
        >{{ $t('delivery_bill.cash_warning') }}</p> -->

          <!-- <p class="pt-5" v-thematic v-html="$t('delivery_bill.warning')"></p> -->
        </div>

        <!-- <div
        class="preorder-warning color-black text-center pt-5"
        target="_blank" v-if="!is_delivery && preorder.status === 'confirmed' && cafe.address"
        v-thematic>
        <p>{{ $t('preorder_bill.cafe_address') }}</p>
        <a :href="`https://maps.google.com/?q=${cafe.address}`">{{ cafe.address }}</a>
      </div>-->

        <a
          class="address-link"
          target="_blank"
          :href="cafe.address_url_override || `https://maps.google.com/?q=${cafe.address}`"
          v-if="!is_delivery"
        >
          <LongButton class="btn-transparent" v-thematic v-if="!is_delivery">
            {{ $t("preorder_bill.open_map") }}
          </LongButton>
        </a>

        <PartnerCard
          :cafe="cafe"
          v-if="target.is_delivery && showPartnerCard"
        />

        <!-- <a
          href="https://app.qrwaiter.com.ua/download"
          target="_blank"
          v-if="preorder.status != 'unpaid' && cafe._id != 336"
        >
          <LongButton variant="dark" class="mt-20">{{
            $t("card.download_app")
          }}</LongButton>
        </a> -->

        <LongButton @click="propagateSwitchView('menu'); $emit('order-dropped')" variant="dark" class="mt-20">{{
            $t("preorder_bill.btn_back_to_menu")
          }}</LongButton>

        <!-- <p class="text-center d-block color-black contact" v-thematic>
        <span v-if="preorder.status === 'pending'">
          {{ $t('preorder_bill.send_after_payment') }}
          <br />
          <br />
        </span>
        {{$t('preorder.contact_phone')}}
        <br />
        <a
          :href="`tel:${delivery_contact_phone}`"
          v-if="target.is_delivery"
        >{{ delivery_contact_phone }}</a>
        <a
          :href="`tel:${preorder_contact_phone}`"
          v-if="!target.is_delivery"
        >{{ preorder_contact_phone }}</a>
      </p>-->

        <LongButton
          class="back-to-marketplace"
          variant="danger"
          @click="backToMarketplace"
          v-if="preorder.status == 'confirmed' && cafe._id != 336 && isFromMarketplace"
          >{{ $t("cafe_closed.to_marketplace") }}</LongButton
        >

        <!-- <div class="snake-button" @click="playing_game = true" v-if="preorder.status === 'confirmed'">
        <img src="../assets/snake.png" alt="Snake Game Logo" />
      </div> -->
        <!-- <p v-if="preorder.status == 'confirmed'" v-thematic class="preorder-warning">
        {{$t('order_buttons.if1')}}
        <a
          href="https://qrwaiter.com.ua"
          target="_blank"
        >{{$t('order_buttons.if2')}}</a>
      </p> -->
      </div>

      <SnakeGame
        :source="'delivery'"
        :user="`${client.name} ${client.phone}`"
        @close="playing_game = false"
        v-if="playing_game"
      />

      <OrderButtons
        mode="cart"
        @switchView="propagateSwitchView"
        :amount="cart.length"
        v-if="!preorder || preorder.status == 'pending'"
      />
      <!-- <PreorderTimeModal
      :cafe="cafe"
      :preorder="preorder"
      :is_delivery="is_delivery"
      @confirm="onTimeModalConfirm"
      :initial_client="client"
      :geolocation="geolocation_positon"
      @fieldChange="time_modal_secure = false"
      ref="timeModal"
      v-if="time_modal"
    />-->

      <GeoModal
        :fail="geo_modal_fail"
        @confirm="onGeoModalConfirm"
        v-if="geo_modal"
      />

      <PayModal
        :methods="pay_methods"
        @cancel="hidePayUI"
        @select="onPayMethodSelect"
        :bonus="canPayByBonus"
        v-if="pay_methods_modal"
      />

      <PandaFatalError
        :error="$t(`geo.fail_titles.out_of_range`)"
        :out_of_range="true"
        @close="out_of_range = false"
        v-if="out_of_range"
      />

      <PhoneConfirmModal
        :token="confirm_phone_token"
        @confirmed="finishSending"
        @resend="resendConfirmation"
        @cancel="cancelPhoneConfirmation"
        v-if="confirming_phone"
      />

      <PandaFatalError
        :error="$t(`cafe_closed.title`)"
        @close="cafe_closed_modal = false"
        v-if="cafe_closed_modal"
      />

      <AlchoholicModal
        @confirm="finishSending"
        @cancel="
          alcoModal = false;
          sending_order = false;
        "
        v-if="alcoModal"
      />

      <PandaFatalError
        :error="create_error"
        @close="create_error = null"
        v-if="create_error"
      />

      <div class="out-of-range" v-if="false">
        <p v-html="$t(`geo.fail_titles.out_of_range`)"></p>

        <p v-html="$t(`geo.fail_subtitles.out_of_range`)"></p>

        <a href="#">
          <button class="marketplace-button" @click="backToMarketplace">
            {{ $t("back_to_marketplace") }}
          </button>
        </a>

        <div class="snake-button" @click="playing_game = true">
          <img src="../assets/snake.png" alt="Snake Game Logo" />
        </div>
      </div>

      <div class="preorder-mode-container" v-if="choosing_mode">
        <div class="hide-content"></div>
        <PreorderMode
          :min_check="min_check"
          :cost="cafe.delivery.cost"
          :cost_type="cafe.delivery.cost_type"
          :individual="individualDelivery"
          :cost_min_check="cafe.delivery.cost_min_check || 0"
          @select="onChooseMode"
          @cancel="choosing_mode = false"
        />
      </div>

      <SupportIcon />
    </div>
    <div class="popup-overlay-container">
      <CafeFeedback
        :mode="modeKey"
        :order_id="preorder._id"
        @success="onFeedbackSent"
        @cancel="show_feedback = false"
        v-if="show_feedback"
      />
    </div>

    <OnlinePaymentModal
      :order_id="preorder._id"
      :mode="'preorder'"
      @close="hidePayModal"
      @success="closePayModal"
      v-if="pay_modal"
    />

    <CancelOrderModal
      @cancel="refuse_modal = false"
      @confirm="cancelPreorder"
      v-if="refuse_modal"/>

    <!-- <NotificationsUpdateModal
      @close="notifications_update_modal = false"
      v-if="notifications_update_modal"/> -->
  </div>
</template>

<script>
import OrderCart from "@/components/OrderCart.vue";
import OrderButtons from "@/components/OrderButtons.vue";
import PartnerCard from "@/components/PartnerCard.vue";
// import PreorderTimeModal from "@/components/PreorderTimeModal.vue";
// import DeliveryTimeModal from "@/components/DeliveryTimeModal.vue";
import OnlinePaymentModal from "@/components/modals/OnlinePaymentModal.vue";
import GeoModal from "@/components/GeoModal.vue";

import PreorderBill from "@/components/PreorderBill.vue";
import PreorderDetails from "@/components/PreorderDetails.vue";

import DeliveryInfo from "@/components/card/DeliveryInfo.vue";

import OrderStatusNotification from "@/components/OrderStatusNotification.vue";

import PreorderAPI from "@/api/preorder";

import PreorderCommunicator from "@/services/preorder/communicator.js";

import CartService from "@/services/cart";

import BillSaver from "@/services/billsaver";

import SoundService from "@/services/sound";

import GeolocationService from "@/services/geolocation";

import DeliveryCache from "@/services/delivery/cache.js";
import OrderSaver from "@/services/ordersaver";

import SnakeGame from "@/components/SnakeGame.vue";
import PayModal from "@/components/PayModal.vue";

import PositionSaver from "@/services/positionsaver";

import PandaFatalError from "@/components/modals/PandaFatalError.vue";
import PhoneConfirmModal from "@/components/modals/PhoneConfirmModal.vue";

import SecurityAPI from "@/api/security";

import PreorderMode from "@/components/PreorderMode.vue";

import ScheduleService from "@/services/schedule";
import MarketplaceService from "@/services/marketplace";

import AIModal from "@/components/menu/AIModal";

import CafeFeedback from "@/components/CafeFeedback.vue";
import FeedbackButton from "@/components/FeedbackButton.vue";

import SupportContainer from "@/components/SupportContainer.vue";
import SupportIcon from "@/components/SupportIcon.vue";

import TelemetryService from "@/services/telemetry";

import CancelOrderModal from "@/components/CancelOrderModal.vue"

import PaymentAPI from "@/api/payment";
import AlchoholicModal from "@/components/modals/AlcoholicModal.vue";

import CommentField from "@/components/order-details/CommentField.vue";

import NotificationsUpdateModal from "@/components/modals/NotificationsUpdateModal.vue";

import FutureOrderAlert from "@/components/card/FutureOrderAlert.vue"

import moment from "moment";

export default {
  data() {
    return {
      busy: false,
      client: {
        name: null,
        phone: null,
        address: null,
      },
      takeaway_date: null,
      cart_comment: null,
      guests_count: 1,
      with_reservation: false,
      address_details: null,

      preorder: null,

      editing_preorder: false,

      pay_modal: false,
      time_modal: false,
      geo_modal: false,
      geo_modal_fail: null,
      geolocation_positon: null,
      geo_is_in_area: false,

      playing_game: false,
      out_of_range: false,
      cafe_closed_modal: false,

      pay_methods_modal: false,
      selected_pay_method: "cash",

      confirming_phone: false,
      confirm_phone_token: null,

      choosing_mode: false,

      time_modal_secure: true,

      showAIModal: false,

      sending_order: false,

      refuse_modal: false,

      show_feedback: false,
      feedback_sent: false,
      promocode: null,

      current_split_index: 0,

      create_error: null,

      split_payments: null,
      alcoModal: false,

      phone_confirm_ui: false,

      notifications_update_modal: false,

      estimated_packaging_cost: 0,

      cancel_time_left: 0,

      overriden_zone_price: null,

      order_status_expired: false
    };
  },
  props: {
    cart: Array,

    cafe: Object,
    target: Object,
    is_delivery: Boolean,
    min_check: Number,
    server_time: [Number, String, Object],
    // client: Object
  },
  watch: {
    pay_modal() {
      this.toggleGlobalScrolling(this.pay_modal);
    },
    cart() {
      this.checkSplitPayments();
    },
    refuse_modal() {
      this.toggleGlobalScrolling(this.refuse_modal);
    }
  },
  methods: {
    propagateSwitchView(arg) {
      this.$emit("switchView", arg);
    },

    onUpdate(preorder) {
      this.preorder = preorder;
      SoundService.playOrderUpdateSound();
      this.$emit("disableScrollSaver");
      PositionSaver.clear("delivery");

      if (this.preorder.status == "confirmed") {
        // if (window.localStorage.getItem("aiw_survey_1") === null) {
        //   this.showAIModal = true;
        // }
        this.onPreorderConfirmed();
      }
    },

    closeAI() {
      this.showAIModal = false;
      window.localStorage.setItem(`aiw_survey_1`, Date.now());
    },

    getReferralIdOrNull() {
      const referral_value = window.localStorage.getItem(`last_referral_id`);

      if (referral_value) {
        const tokens = referral_value.split(":");

        if (tokens[0] != this.cafe.online_menu_link) {
          window.localStorage.removeItem("last_referral_id");

          return null;
        }

        return tokens[1];
      }
    },

    sendPreorder() {
      const request_data = {
        cart: this.cart,
        name: this.client.name,
        phone: this.client.phone,
        address: this.client.address,
        address_details: this.address_details,
        is_delivery: this.is_delivery,
        cafe_id: this.target.cafe_id,
        takeaway_date: this.takeaway_date,
        comment: this.cart_comment,
        is_in_area: this.geo_is_in_area,
        guests_count: this.guests_count,
        with_reservation: this.with_reservation,
        referral_id: this.getReferralIdOrNull(),
        client_id: this.client.phone
          ? this.$store.state.clients.client_id
          : null,
        marketplace_order:
          !!MarketplaceService && !!MarketplaceService.get()
            ? MarketplaceService.get().flag
            : false,
        promocode: this.promocode,
      };

      PreorderAPI.create(request_data)
        .then((data) => {
          this.preorder = data.preorder;
          OrderSaver.set(this.modeKey, this.preorder);
          this.onPreorderSent();
        })
        .catch((error) => {
          console.error(error);
          this.create_error = this.$t(`errors.${error}`);
        })
        .finally(() => {
          this.busy = false;
        });

      TelemetryService.emit(
            TelemetryService.Events.SendCreateRequest,
            JSON.stringify(request_data)
      );
    },

    onPreorderSent(from_sync) {
      PreorderCommunicator.connect({
        onUpdate: this.onUpdate,
      });
      PreorderCommunicator.listen(this.preorder._id);
      SoundService.playOrderUpdateSound();
      DeliveryCache.clear();
      window.localStorage.removeItem("delivery_client");
      PositionSaver.clear("preorder");
      PositionSaver.clear("delivery");
      this.$emit("disableScrollSaver");

      if (!from_sync) this.onPayMethodSelect(this.selected_pay_method);
      TelemetryService.addMilestone("order");
    },

    editPreorder() {
      PreorderAPI.edit(this.preorder._id, this.client, this.takeaway_date)
        .then((data) => {
          this.time_modal = false;
          this.$set(this.preorder, "takeaway_date", this.takeaway_date);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    showTimeUI() {
      this.busy = true;
      this.time_modal = true;

      this.toggleGlobalScrolling(true);
    },

    showPayUI() {
      if (!this.cafe.pay_methods) {
        this.pay_modal = true;
        return;
      }

      if (this.cafe.pay_methods.online && !this.cafe.pay_methods.cash) {
        this.pay_modal = true;
        return;
      }

      this.pay_methods_modal = true;
      this.resetScroll();
      this.toggleGlobalScrolling(true);
    },

    hidePayUI() {
      this.pay_methods_modal = false;
      this.toggleGlobalScrolling(false);
    },

    hidePayModal() {
      this.pay_modal = false;
      this.sending_order = false;
    },

    onCartSend(comment) {
      this.cart_comment = comment;

      // this.geo_modal = true;
      // this.geo_modal_fail = null;

      // if (this.cafeClosed) {
      //   this.cafe_closed_modal = true;
      //   return;
      // }

      // if (!(this.cafe.modes.preorder && this.cafe.modes.delivery)) {
      //   this.$emit('setTarget', this.cafe.modes.delivery);
      //   this.showTimeUI();
      // } else {
      //   this.choosing_mode = true;
      // }
      this.showTimeUI();
    },
    onChooseMode(is_delivery) {
      this.choosing_mode = false;
      this.$emit("setTarget", is_delivery);
      this.showTimeUI();
    },
    onDetailsConfirm(params) {
      if (this.sending_order) return;

      this.client.name = params.name;
      this.client.phone = params.phone;
      this.client.address = params.address;
      this.takeaway_date = params.takeaway_date;
      this.selected_pay_method = params.pay_method;
      this.guests_count = params.guests_count;
      this.with_reservation = params.with_reservation;
      this.address_details = params.address_details;
      this.cart_comment = params.comment;
      this.promocode = params.promocode;

      if (!this.takeaway_date && this.cafeClosed) {
        this.cafe_closed_modal = true;
        return;
      }

      // if (
      //   this.cafeClosed &&
      //   !(this.target.is_delivery && this.cafe.delivery.is_scheduled)
      // ) {
      //   this.cafe_closed_modal = true;
      //   return;
      // }

      if (this.$store.getters["cart/hasExciseItem"]) {
        this.alcoModal = true;
        return;
      }

      this.sending_order = true;
      this.finishSending();
    },
    onTimeModalConfirm(takeaway_date, client) {
      this.client = client;
      this.time_modal = false;
      this.takeaway_date = takeaway_date;

      //if there is no cached client, ask for phone
      if (!this.$store.getters["clients/isAuthorized"]) {
        this.askPhoneConfirmation();
      } else {
        //if there is a client cached, check if phone numbers are equal
        if (
          this.time_modal_secure &&
          this.$store.state.clients.client.phone == this.client.phone
        ) {
          this.finishSending();
        } else {
          this.askPhoneConfirmation();
        }
      }
    },

    askPhoneConfirmation() {
      this.confirming_phone = true;

      SecurityAPI.ask(this.client.phone)
        .then((data) => {
          this.confirm_phone_token = data.token;
        })
        .catch((error) => {
          alert(
            `Сталася помлика підтвердження. Будь ласка, спробуйте пізніше.`
          );
        });
    },

    resendConfirmation() {
      SecurityAPI.ask(this.client.phone)
        .then((data) => {
          this.confirm_phone_token = data.token;
        })
        .catch((error) => {
          alert(
            `Сталася помлика підтвердження. Будь ласка, спробуйте пізніше.`
          );
        });
    },

    sendOrEdit() {
      this.confirming_phone = false;

      if (this.target.is_delivery) {
        this.checkDeliveryAddress();
      } else {
        if (this.editing_preorder) {
          this.editPreorder();
        } else {
          this.sendPreorder();
        }

        this.toggleGlobalScrolling(false);
      }
    },
    finishSending() {
      this.alcoModal = false;
      // if (this.cafe._id == 299 || this.cafe._id == 1) {
      //   this.sendOrEdit();
      //   return;
      // }
      this.$store
        .dispatch("clients/create", this.client)
        .then((ok) => {
          this.sendOrEdit();
        })
        .catch((error) => {
          // alert(
          //   `Сталася помилка запису данних клієнта. Будь ласка, спробуйте пізніше.`
          // );
          this.create_error = error;
        });
    },

    checkDeliveryAddress() {
      if (this.shouldSkipAddressCheck) {
        this.sendPreorder();
        this.toggleGlobalScrolling(false);
        return;
      }

      PreorderAPI.checkDeliveryAddress(this.target.cafe_id, this.client.address)
        .then((data) => {
          if (!data.result) {
            this.out_of_range = true;
            this.busy = false;
            this.sending_order = false;
            return;
          }

          if (this.editing_preorder) {
            this.editPreorder();
          } else {
            this.sendPreorder();
          }

          this.toggleGlobalScrolling(false);
        })
        .catch((error) => {
          alert(`Сталася помилка при перевірці зони доставки.`);
          this.busy = false;
          this.sending_order = false;
        });
    },

    onBillSave() {
      const locale = this.$i18n.messages[this.$i18n.locale].download_bill;
      const currency = this.$store.state.currency.current.code;
      BillSaver.generateAndSaveBill(
        this.cafe.name,
        this.preorder,
        locale,
        currency
      );
      // BillSaver.generateAndSaveBill(this.cafe.name, this.preorder);
    },

    onEdit() {
      this.$emit(
        "setCart",
        this.preorder.list
          .filter((item) => {
            return item.category != "Delivery" && item.category != "Package";
          })
          .map((item) => {
            delete item._id;
            return item;
          })
      );
      this.client = this.preorder.client;
      window.localStorage.setItem(
        "delivery_client",
        JSON.stringify(this.client)
      );
      this.preorder = null;
      OrderSaver.clear(this.modeKey);

      // this.editing_preorder = true;
      // this.time_modal = true;
      // this.toggleGlobalScrolling(true);
    },

    closePayModal() {
      this.pay_modal = false;
    },
    tickCancelTimer() {
      this.cancel_time_left--;

      if (this.cancel_time_left <= 0) return;

      setTimeout(this.tickCancelTimer, 1500);
    },
    onPreorderConfirmed() {
      this.sending_order = false;
      this.preorder.status = "confirmed";
      OrderSaver.clear(this.modeKey);
      this.$store.dispatch("cart/clear");
      TelemetryService.addMilestone("pay");
      this.$emit("order-closed");
      window.localStorage.setItem(
        "web_status_id",
        `preorder:${this.preorder._id}`
      );

      const notif_modal = window.localStorage.getItem(
        `notifications_update_modal`
      );

      if (!notif_modal) {
        if (
          moment().diff(this.$store.state.clients.client.created_at, "hours") >
          12
        ) {
          this.notifications_update_modal = true;
        }
      }

      this.cancel_time_left = moment(
        this.preorder.confirmed_at
      ).add(10, 'minutes').diff(moment(), 'seconds');

      this.tickCancelTimer();

      window.onbeforeunload = function () {
        return "Are you sure you want to leave?";
      };
    },
    checkGeolocation(pos) {
      this.geolocation_positon = pos;

      PreorderAPI.checkGeolocation(this.target.cafe_id, pos.lat, pos.lon)
        .then((data) => {
          if (!data.result && this.target.is_delivery) {
            this.geo_modal_fail = "out_of_range";
            return;
          }

          this.geo_is_in_area = data.is_in_area;

          this.geo_modal = false;
          this.showTimeUI();
        })
        .catch((error) => {
          alert(`Помилка :( (${error})`);
        });
    },

    onGeoModalConfirm() {
      if (this.geo_modal_fail == "out_of_range") {
        this.geo_modal = false;
        this.out_of_range = true;
        return;
      }

      if (this.geo_modal_fail == "browser") {
        this.geo_modal = false;
        return;
      }

      GeolocationService.getPosition()
        .then(this.checkGeolocation)
        .catch((error) => {
          this.geo_modal_fail = "browser";
        });
    },

    isIndividualCafe(key) {
      if (key === "tenshi") return `${this.cafe._id}` == `46`;
      return false;
    },

    onPayMethodSelect(method) {
      const pay_method = method;

      PreorderAPI.setPayMethod(this.preorder._id, pay_method)
        .then((resp) => {
          this.hidePayUI();

          if (pay_method == "online" && resp.preorder.status != "confirmed") {
            this.pay_modal = true;
          } else if (!this.pay_modal) {
            this.preorder = resp.preorder;
            if (this.preorder.status == "confirmed") {
              this.onPreorderConfirmed();
            }
          }

          this.resetScroll();
        })
        .catch((error) => {
          alert(`Помилка :( (${error})`);
        })
        .finally(() => {
          this.sending_order = false;
        });
    },

    backToMarketplace() {
      // window.location.href = 'https://delivery.qrwaiter.com.ua';
      this.$router.push("/marketplace");
    },

    cancelPhoneConfirmation() {
      this.confirming_phone = false;
      this.time_modal = true;
      this.$nextTick(() => this.$refs.timeModal.onNext());
    },

    onFeedbackSent() {
      this.show_feedback = false;
      this.feedback_sent = true;
    },

    checkSplitPayments() {
      if (!this.cart.length) return;

      PaymentAPI.getCartSplits(this.cafe._id, this.modeKey, this.cart)
        .then((data) => {
          this.split_payments = data.splits;
        })
        .catch((error) => {});
    },

    runNextSplitPayment() {
      this.pay_modal = true;
    },

    estimateDeliveryToAddress(address) {
      if (!this.cafe.delivery.dynamic_ui_price) return;

      PreorderAPI.estimateDelivery({
        address,
        client_id: this.$store.state.clients.client_id,
        cafe_id: this.cafe._id,
        cart_total: this.$store.getters['cart/total']
      }).then(data => {
        if (data.is_zone_specific_price) {
          this.overriden_zone_price = data.client_pays;
        }
      }).catch(err => {
        console.error(`Delivery estiamtion failed`, err);
      })
    },

    syncFromPaymentPage(order_id) {
      PreorderAPI.sync(order_id)
        .then((data) => {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {},
          });

          if (
            data.preorder.status == "closed" ||
            data.preorder.status == "cancelled"
          ) {
            this.propagateSwitchView("menu");
            return;
          }

          this.preorder = data.preorder;

          if (data.preorder.status == "confirmed") {
            this.onPreorderConfirmed();
          } else {
            this.onPreorderSent(true);
          }
        })
        .catch((err) => {});
    },
    cancelPreorder(reason) {
      PreorderAPI.cancelPreorder(
        this.preorder._id,
        reason
      ).then(data => {
        this.refuse_modal = false;

        this.cancel_time_left = 0;

        this.$refs.orderStatusNotification.fetchInfo();
      }).catch(err => {
        this.refuse_modal = false;
      });
    },
    onCartUpdated() {
      this.checkSplitPayments();
      this.estimatePackaging();
    },
    estimatePackaging() {
      if (this.cafe[this.modeKey].packaging_type == 'free') return;

      PreorderAPI.estimatePackaging({
        cafe_id: this.cafe._id,
        mode: this.modeKey,
        cart: this.cart
      }).then(data => {
        if (data.cost != undefined) this.estimated_packaging_cost = data.cost;
      }).catch(err => {
        console.error(err);
      });
    }
  },
  mounted() {
    SoundService.init();

    window.document.body.style.backgroundColor = this.isDarkMode
      ? "black"
      : "#ededed";

    this.resetScroll();
    this.$nextTick(() => {
      if (this.$store.getters["clients/isAuthorized"]) {
        this.client = Object.assign({}, this.$store.state.clients.client);
      }
    });

    // const client_json = window.localStorage.getItem("delivery_client");
    // if (client_json) {
    //   this.client = JSON.parse(client_json);
    // }

    this.checkSplitPayments();
  },
  components: {
    OrderCart,
    OrderButtons,
    PartnerCard,
    DeliveryInfo,
    OrderStatusNotification,
    CommentField,
    // PreorderTimeModal,
    // DeliveryTimeModal,
    PreorderDetails,

    OnlinePaymentModal,
    PreorderBill,

    GeoModal,
    PayModal,
    PandaFatalError,
    PhoneConfirmModal,

    CancelOrderModal,

    SnakeGame,

    PreorderMode,

    AIModal,
    CafeFeedback,
    FeedbackButton,
    SupportContainer,
    SupportIcon,
    AlchoholicModal,

    NotificationsUpdateModal,

    FutureOrderAlert
  },
  computed: {
    virtual_list() {
      if (!this.target.is_delivery) return [];

      return [];
    },
    pay_methods() {
      const container = this.cafe[this.modeKey];

      if (!container.pay_methods) {
        return {
          cash: false,
          card: false,
          online: false,
        };
      }

      return container.pay_methods;
    },
    shouldShowTaxiWarning() {
      if (!this.target.is_delivery) return false;

      let total = 0;

      if (this.preorder && this.preorder.total) {
        total = this.preorder.total;
      } else {
        total = this.$store.getters["cart/total"];
      }

      if (this.isIndividualCafe("tensi") && !this.geo_is_in_area) return true;

      if (`${this.cafe._id}` == "61" && total < 500) return true;

      if (this.cafe.delivery.service_tag != "qrwaiter") return true;

      if (this.cafe.delivery.cost_type == "free") return false;

      if (this.cafe.delivery.cost_type == "constant") return true;

      if (
        this.cafe.delivery.cost_type &&
        this.cafe.delivery.cost_type == "taxi"
      )
        return true;

      if (
        this.cafe.delivery.cost_type == "conditional" &&
        this.cafe.delivery.cost_conditional_type == "taxi" &&
        total < this.cafe.delivery.cost_min_check
      ) {
        return true;
      }

      if (
        this.cafe.delivery.cost_type == "conditional" &&
        this.cafe.delivery.cost_conditional_type == "constant" &&
        total < this.cafe.delivery.cost_min_check
      ) {
        return true;
      }

      return false;
    },
    modeKey() {
      return this.target.is_delivery ? "delivery" : "preorder";
    },
    currency() {
      return this.$store.state.currency.current;
    },
    schedule() {
      let possible = this.cafe[this.modeKey].schedule;

      if (possible && possible.enabled && Object.values(possible).filter(d => d.workday).every(d => d.startHour !== null && d.endHour !== null && d.endMinute !== null)) {
        return possible;
      } else {
        return this.cafe.schedule;
      }
    },
    cafeClosed() {
      if (!this.cafe.schedule) return true;

      const helper = new ScheduleService.ScheduleHelper(this.schedule);

      if (!helper.isOpen()) return true;

      return !helper.isOpenTimeLimit(
        this.cafe[this.modeKey].close_time_limit || 0
      );
    },
    individualDelivery() {
      if (!this.cafe) return null;
      const id = `${this.cafe._id}`;
      if (id == "46") return "tenshi";
      if (id == "39") return "teritoria";

      return null;
    },
    isLoyaltyEnabled() {
      return (
        this.cafe.loyalty &&
        this.cafe.loyalty.cashback_percent &&
        this.cafe.loyalty.terms_agreed
      );
    },
    cashbackValue() {
      if (!this.isLoyaltyEnabled) return 0;

      const qrwaiter_percent = 1;

      const percent = this.cafe.loyalty.cashback_percent + qrwaiter_percent;

      const total = this.preorder
        ? this.preorder.total
        : this.$store.getters["cart/total"];

      //100 - total
      //percent - x

      return Math.ceil((percent * total) / 100);
    },
    canPayByBonus() {
      if (!this.isLoyaltyEnabled) return false;
      const getter = this.$store.getters["clients/canPayByBonus"];
      const amount = this.$store.getters["cart/total"];

      return getter(amount);
    },
    clientBonusBalance() {
      if (!this.isLoyaltyEnabled) return null;

      return this.$store.getters["clients/bonusBalance"];
    },
    scheduleDeliveryTimes() {
      if (!this.cafe.delivery.is_scheduled) return [];

      let dates = [];

      let ptr = moment().hours(12).minutes(0);

      for (let i = 0; i < 7; i++) {
        const schedule = new ScheduleService.ScheduleHelper(
          this.schedule,
          ptr.valueOf()
        );

        if (schedule.isWorkday()) {
          dates.push(ptr.toDate());
        }

        ptr = ptr.add(1, "day");
      }

      return dates;
    },
    possibleTakeawayTimes() {
      let now = moment();

      if (this.target.is_delivery && this.cafe.delivery.is_scheduled) {
        return this.scheduleDeliveryTimes;
      }

      if (this.target.is_delivery) {
        const min_delivery_time = this.cafe.delivery.min_order_time;
        const remainder = 5 - (now.minutes() % 5);
        now = moment(now).add(min_delivery_time + remainder, "minutes");
      } else {
        //Preorders minimal time can be set up in cafe settings
        let min_order_time = 30;
        if (this.cafe.preorder && this.cafe.preorder.min_order_time) {
          min_order_time = this.cafe.preorder.min_order_time;
        }

        now = moment(now).add(min_order_time, "minutes");
      }

      console.log(this.schedule);

      if (this.cafeClosed) {
        let helper = new ScheduleService.ScheduleHelper(
          this.schedule,
          now.valueOf()
        );

        let loop_limit = 4 * 24;

        console.log(`cafe is closed`, helper.endMoment, helper.isOpen());

        while (!helper.isOpen() && loop_limit > 0) {
          now = moment(now).add(1, "hour");

          helper = new ScheduleService.ScheduleHelper(
            this.schedule,
            now.valueOf()
          );

          loop_limit--;
        }
      }

      console.log(`Figured starting date:`, now.toDate());

      //18:56
      //56 % 10 = 6
      // now.minutes(now.minutes() + (10 - (now.minutes() % 10)));
      const max_date = moment(now).add(1, "days").endOf('day');
      // const max_date = moment(
      //   new ScheduleService.ScheduleHelper(
      //     this.schedule,
      //     posssible_max_date.valueOf()
      //   ).endMoment
      // );
      let times = [];

      // const helper = new ScheduleService.ScheduleHelper(
      //   this.schedule,
      //   now.valueOf()
      // );

      // const end_moment = moment(helper.endMoment).subtract(
      //   this.cafe[this.modeKey].close_time_limit || 0,
      //   "minutes"
      // );

      while (now.isSameOrBefore(max_date)) {
        const local_helper = new ScheduleService.ScheduleHelper(
          this.schedule,
          now.valueOf()
        );

        if (local_helper.isOpenTimeLimit(
          this.cafe[this.modeKey].close_time_limit || 0,
        ) && local_helper.isWorkday()) times.push(now.toDate());

        now.add(10, "minutes");
      }

      // const nextday_helper = new ScheduleService.ScheduleHelper(
      //     this.schedule,
      //     moment().add(1, "day")
      //   );

      //   if (nextday_helper.isWorkday()) {
      //     let nextday_now = moment(nextday_helper.startMoment).add(1, "hour");

      //     while (nextday_now.isBefore(moment(nextday_helper.endMoment))) {
      //       times.push(nextday_now.toDate());
      //       nextday_now.add(10, "minutes");
      //     }
      //   }

      return times;
    },
    isTotalBiggerMinCheck() {
      if (!this.min_check) return true;

      return this.$store.getters["cart/total"] >= this.min_check;
    },
    shouldSkipAddressCheck() {
      // return false;
      return `${this.cafe._id}` == "126" || `${this.cafe._id}` == '381';
    },
    showPartnerCard() {
      return [`Ужгород`, `Мукачево`].includes(this.cafe.city) && this.cafe.delivery.service_tag == 'moeco';
    },
    additionalDeliveryPrice() {
      if (
        this.cafe.delivery.service_tag != "qrwaiter" &&
        this.cafe.delivery.taxi_payer == "client"
      ) {
        return this.cafe.delivery.taxi_cost;
      }

      if (this.preorder && this.preorder.delivery_profit) {
        return (
          this.preorder.delivery_client_cost || this.preorder.delivery_profit
        );
      }

      return null;
    },
    deliveryPricingState() {
      if (!this.is_delivery) return null;

      let total = 0;

      if (this.preorder && this.preorder.total) {
        total = this.preorder.total;
      } else {
        total = this.$store.getters["cart/total"];
      }

      if (
        this.cafe.delivery.service_tag == "qrwaiter" ||
        this.cafe.delivery.service_status != "ready"
      ) {
        if (this.cafe.delivery.cost_type == "free") {
          return {
            type: "free",
          };
        }

        if (this.cafe.delivery.cost_type == "constant") {
          return {
            type: "constant",
            price: this.overriden_zone_price || this.cafe.delivery.cost,
            payment: this.cafe.delivery.add_cost_to_check ? "check" : "receive",
          };
        }

        if (this.cafe.delivery.cost_type == "taxi") {
          return {
            type: "taxi",
          };
        }

        if (this.cafe.delivery.cost_type == "conditional") {
          if (total >= this.cafe.delivery.cost_min_check) {
            return {
              type: "free",
            };
          } else {
            return {
              type: this.cafe.delivery.cost_conditional_type,
              price: this.overriden_zone_price || this.cafe.delivery.cost,
              payment: this.cafe.delivery.add_cost_to_check
                ? "check"
                : "receive",
            };
          }
        }
      } else {
        if (this.cafe.delivery.taxi_payer == "client") {
          return {
            type: "constant",
            price: this.cafe.delivery.taxi_cost,
            payment: "receive",
          };
        }

        if (this.cafe.delivery.taxi_payer == "cafe") {
          return {
            type: "free",
          };
        }

        if (this.cafe.delivery.taxi_payer == "conditional") {
          const fitting_cost = this.cafe.delivery.cost_min_checks.find(
            (c) => total < c.limit
          );

          return {
            type: !fitting_cost ? "free" : "constant",
            payment: "receive",
            price: fitting_cost ? fitting_cost.price : 0,
          };
        }
      }
    },
    deliveryPriceUi() {
      if (!this.preorder.is_delivery) return null;

      if (
        this.cafe.delivery.service_tag != "qrwaiter" &&
        this.cafe.delivery.service_tag == "ready"
      ) {
        return this.cafe.delivery.taxi_payer == "client"
          ? `+${this.cafe.delivery.taxi_cost}${this.currency.symbol}`
          : null;
      }

      if (this.cafe.delivery.cost_type == "free") return null;

      if (this.cafe.delivery.cost_type == "constant") {
        return `+${this.cafe.delivery.cost}${this.currency.symbol}`;
      }

      if (this.cafe.delivery.cost_type == "taxi") {
        return this.$t("delivery_bill.delivery_additional");
      }

      if (this.cafe.delivery.cost_type == "conditional") {
        let total = 0;

        if (this.preorder && this.preorder.total) {
          total = this.preorder.total;
        } else {
          total = this.$store.getters["cart/total"];
        }

        const client_pays = total < this.cafe.delivery.cost_min_check;

        if (this.cafe.delivery.cost_conditional_type == "constant") {
          return client_pays
            ? `+${this.cafe.delivery.cost}${this.currency.symbol}`
            : null;
        } else {
          return client_pays
            ? this.$t("delivery_bill.delivery_additional")
            : null;
        }
      }

      return null;
    },
    isPopupOverlaying() {
      return this.show_feedback;
    },
    currentSplitIndex() {
      if (!this.preorder) return 0;

      return this.preorder.split_payments.findIndex((p) => !p.paid_at);
    },
    canPostPayOnline() {
      return (
        this.preorder &&
        ["cash", "card"].includes(this.preorder.pay_method) &&
        this.pay_methods.online &&
        ["confirmed", "serving"].includes(this.preorder.status) &&
        !this.order_status_expired
      );
    },
    cancelOrderTimeString() {
      if (!this.canCancelOrder) return '';

      return moment.duration(
        this.cancel_time_left * 1000
      ).format('mm:ss');

    },
    canCancelOrder() {
      return this.cancel_time_left > 0 && this.preorder.status == 'confirmed';
    },
    isFromMarketplace() {
      return MarketplaceService.get() && MarketplaceService.get().flag;
    }
  },
};
</script>

<style scoped>
.preorder-session-wrapper {
  padding-top: 10vh;
  margin-bottom: 15vh;
  min-height: 85vh;
}

.btn-transparent {
  background: transparent;
  box-shadow: none;
  margin-bottom: 3.7vh;
  border: 1px solid #1d1d1d;
  color: #1d1d1d;
}

.btn-transparent.dark {
  border: 1px solid #ededed;
  color: #ededed;
}

.w-90 {
  width: 90% !important;
}

.btn-unique {
  width: 90%;
  box-shadow: none;
}

.c-white {
  color: #ededed !important;
}

p a {
  color: #129b81;
}

p.dark {
  color: #ededed;
}

.chequeue-comment {
  /* background: transparent;
  padding: 2vh;
  border: 1px solid #908f8d;
  border-radius: 1.2vh; */
  width: 90%;
  margin: 2.5vh auto 0;
}

/* .chequeue-comment h4 {
  color: #a4a3a1;
  font-size: 2.3vh;
  margin: 0;
}

.chequeue-comment p.info {
  font-size: 1.8vh;
  color: #1d1d1d;
  margin: 0;
}

.chequeue-comment p.info.dark {
  color: #ededed;
} */

.empty-cart {
  text-align: center;
  margin-top: 15px;
}

.empty-cart.dark {
  color: #ededed;
}

.empty-cart img {
  width: 25vh;
  margin-bottom: 1vh;
}

p.contact {
  padding-top: 3.7vh;
  width: 80vw;
  margin: auto;
}

button.edit-info {
  box-shadow: none !important;
  background: transparent;
  border: 1px solid #1d1d1d;
  border-radius: 5vh;
  color: #1d1d1d;
}

button.edit-info.dark {
  border: 1px solid #ededed;
  color: #ededed;
}

.preorder-warning {
  text-align: center;
  width: 80vw;
  color: #1d1d1d;
  text-align: center;
  margin: 0 auto;
  padding: 3vh 0;
}

.preorder-warning.dark {
  color: #ededed;
}

.preorder-warning p {
  margin: 0;
  font-size: 4vw;
}

.space {
  width: 100%;
}

.snake-button img {
  height: 35vh;
  width: auto;
}

.snake-button {
  text-align: center;
}

.out-of-range {
  width: 100vw;
  height: 100vh;
  background: #e5e5e5;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 10vh;
  z-index: 1;
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
}

.marketplace-button {
  outline: none;
  -webkit-appearance: none;
  border: 0;
  border-radius: 30px;
  color: #ffffff;
  text-align: center;
  background: #129b81;
  width: 100%;
  padding: 1.3vh 1vh;

  margin-bottom: 3vh;
}

.back-to-marketplace {
  margin: 2.5vh auto 2.5vh;
}

.preorder-mode-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
}

.preorder-ai-modal {
  margin-top: -2.5vh;
}

.delivery-price-row {
  width: 100%;
  display: flex;
  justify-content: space-between;

  padding-right: 5.2vh;
  padding-left: 5.2vh;
  padding-top: 3.7vh;

  color: #129b81 !important;
}

.delivery-row-value {
  color: #129b81 !important;
  font-size: 2vh;
}

.delivery-row-title {
  font-size: 2vh;
  color: #1d1d1b;
}

.delivery-warn {
  padding-top: 0 !important;
  margin-top: 1vh !important;
  color: #908f8d !important;
}

.future-order-alert-wrapper {
    width: 90%;

    margin-bottom: 2.5vh;
}
</style>
