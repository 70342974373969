<template>
  <div class="deliv-info">
    <div class="d-flex">
      <div class="w-50">
        <h4>{{ $t('preorder_time_modal.time') }}</h4>
        <select v-model="takeaway_date">
          <option :value="null">--:--</option>
          <option
            v-for="(time,index) in available_times"
            :key="index"
            :value="time"
          >{{ time | formatTime }}</option>
        </select>

        <div
          :class="{
          'circle-select': true,
          'active': !takeaway_date
          }"
          @click="takeaway_date = null"
        >
          <span>{{ $t('preorder_time_modal.fast_as_possible') }}</span>
        </div>
      </div>
      <div class="w-50">
        <h4>{{ $t('preorder_time_modal.date') }}</h4>
        <h3>{{ today }}</h3>
        <p v-html="$t('preorder_time_modal.date_no_change')">
            <!-- Дату передзамовлення змінити неможливо.
            Вона повинна співпадати з сьогоднішньою.
            Ящо вона не співпадає – перезавантажте сторінку. -->
        </p>
      </div>
    </div>
    <h4 class="pt-40" v-if="is_delivery">{{ $t('desktop_delivery.address') }}</h4>
    <input type="text" v-if="is_delivery" :placeholder="$t('desktop_delivery.address_ph')" id="desktopGmapField" />
    <div v-if="is_delivery ? !!address : true">
      <div class="text-right pt-50">
        <h5 class="d-inline-flex align-items-baseline">
          {{ $t('desktop_delivery.to_pay') }}: 
          <h2 class="cart-total">{{cart_total}}₴</h2>
        </h5>
      </div>
      <div
        :class="!pay_online || !pay_cash ? 'd-flex justify-content-center':'d-flex justify-content-between'"
      >
        <button
          class="next cash"
          v-if="pay_cash"
          @click="onChoosePayMethod('cash')"
        >
          {{ $t('desktop_delivery.pay_cash') }}
        </button>
        <button class="next" v-if="pay_online" @click="onChoosePayMethod('online')">
          {{ $t('desktop_delivery.pay_online') }}
        </button>
      </div>
    </div>
  </div>
</template> 

<style scoped>
* {
  padding: 0;
  margin: 0;
}

select {
  width: 22vh;
  padding: 1vh;
  border: 1px solid #a4a3a1;
  border-radius: 1.5vh;
  background: transparent;
  font-size: 2.7vh;
  margin-top: 1.7vh;
}

h5 {
  font-size: 2.3vh;
}

h2 {
  line-height: 0.8;
  font-size: 5.5vh;
}

h3 {
  font-size: 2.7vh;
  padding-top: 2.2vh;
}

h4 {
  font-size: 2.2vh;
}

.pt-40 {
  padding-top: 4.4vh;
}
.pt-50 {
  padding-top: 5.5vh;
}

.circle-select {
  height: 3.3vh;
  width: 3.3vh;
  margin: 2.2vh 0 0 0;

  border-radius: 5vh;
  border: 1px solid #a4a3a1;
}

.circle-select.active {
  background: #416293;
  border: 2px solid #a4a3a1;
}

.circle-select span {
  margin-top: -0.2vh;
  display: block;
  font-size: 2.2vh;
  padding-left: 4vh;
  width: 26vh;
}

input {
  margin-top: 1.7vh;
  border: 1px solid #a4a3a1;
  border-radius: 1vh;
  width: 100%;
  background: transparent;
  padding: 1.3vh;
  font-size: 1.8vh;
  outline: none;
}
.deliv-info {
  padding: 3.3vh 3.3vh 0;
  background: #ededed;
}

p {
  padding-top: 1.2vh;
  color: #a4a3a1;
  font-size: 1.2vh;
}

.next.cash {
  background: #cbd9e6;
  color: #1d1d1d;
}

.next {
  background: #416293;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 1vh;
  color: white;

  padding: 1.8vh 3.5vh;
  margin: 3.3vh 0 0 0;
  display: block;
  font-size: 2.1vh;
  width: 31vh;
  transition: 0.3s;
  outline: none;
  border: none;
}

.next:active {
  box-shadow: none;
  transform: translateY(0.5vh);
}

.cart-total {
  margin-left: 1vh;
}
</style>

<script>
import moment from "moment";

import GoogleMapsHelper from "@/services/googlemaps/helper.js";

let gmap_autocomplete = null;

export default {
  data() {
    return {
      address: null,
      takeaway_date: null,
    };
  },
  props: {
    cafe: Object,
    initial_address: String,
    is_delivery: Boolean
  },
  methods: {
    initAddressField() {
      const el = document.getElementById("desktopGmapField");

      gmap_autocomplete = GoogleMapsHelper.initAutocompleteWidget(
        el,
        this.onGoogleMapPlaceSelected.bind(this)
      );

      if (this.initial_address) {
        this.address = this.initial_address;
        document.getElementById("desktopGmapField").value = this.initial_address;
      }
    },
    onGoogleMapPlaceSelected() {
      const result = GoogleMapsHelper.formatDeliveryAddress(gmap_autocomplete);

      if (result.valid) {
        this.address = result.address;
      
        this.$emit('address', this.address);
      }      
    },
    nextStep() {
      if (!this.address) return;
      this.$emit("next", this.address, this.takeaway_date);
    },
    onChoosePayMethod(method) {
      this.$emit('next', {
        address: this.address,
        takeaway_date: this.takeaway_date,
        pay_method: method
      });
    }
  },
  mounted() {
    this.initAddressField();
  },
  computed: {
    today() {
      return moment().format("DD.MM.YYYY");
    },
    available_times() {
      let pointer = moment().add(1, "hour");

      pointer.minutes(pointer.minutes() - (pointer.minutes() % 10));

      const end = moment().add(12, "hours");

      const times = [];

      while (pointer.isBefore(end)) {
        times.push(pointer.toDate());
        pointer = pointer.add(10, "minutes");
      }

      return times;
    },
    cart_total() {
      return this.$store.getters["cart/total"];
    },
    pay_online() {
      if (!this.cafe.pay_methods) return false;
      return this.cafe.pay_methods.online;
    },
    pay_cash() {
      if (!this.cafe.pay_methods) return false;
      return this.cafe.pay_methods.cash;
    }
  }
};
</script>