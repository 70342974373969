function formatDeliveryAddress(gmap_autocomplete) {
    const place = gmap_autocomplete.getPlace();

    const components = place.address_components;

    const street_address_component = components.find(c => c.types.includes('street_address'));
    const route_component = components.find(c => c.types.includes('route'));
    const city_component = components.find(c => c.types.includes('locality'));
    const street_number_component = components.find(c => c.types.includes('street_number'));

    const street_address = street_address_component ? street_address_component.long_name : '';
    const route = route_component ? route_component.long_name : '';
    const city = city_component ? city_component.long_name : '';
    const street_number = street_number_component ? street_number_component.long_name : '';

    const parts = [];

    if (street_address) parts.push(street_address);
    if (route) parts.push(route);
    if (street_number) parts.push(street_number);
    if (city) parts.push(city);

    const is_valid = parts.length >= 3;

    return {
        valid: is_valid,
        address: parts.join(', ')
    }
}

function initAutocompleteWidget(el, callback, country, bounds_center) {
    const options = {
        types: ["geocode"],
        fields: ["address_component"],
        componentRestrictions: {
            country: country || 'ua'
        },
    };


    if (bounds_center && bounds_center.lat) {
        const N = 50;

        const loc_min = new google.maps.LatLng(
            bounds_center.lat - (0.009 * N),
            bounds_center.lon - (0.009 * N)
        );

        const loc_max = new google.maps.LatLng(
            bounds_center.lat + (0.009 * N),
            bounds_center.lon + (0.009 * N)
        );

        const bounds = new google.maps.LatLngBounds(
            loc_min,
            loc_max
        );

        options.bounds = bounds;
        options.strictBounds = true;
    }

    const autocomplete = new window.google.maps.places.Autocomplete(
        el,
        options
    );

    console.log(autocomplete);
    
    //Limit address selection to 5 km
    // let N = 50; //km

    // if (bounds_size) {
    //     N = bounds_size;
    // }

    // if (bounds_center && bounds_center.lat) {
    //     //WARNING: very rough estimation, maybe use better formula
    //     const loc_min = new google.maps.LatLng(
    //         bounds_center.lat - (0.009 * N),
    //         bounds_center.lon - (0.009 * N)
    //     );

    //     const loc_max = new google.maps.LatLng(
    //         bounds_center.lat + (0.009 * N),
    //         bounds_center.lon + (0.009 * N)
    //     );

    //     const bounds = new google.maps.LatLngBounds(
    //         loc_min,
    //         loc_max
    //     );

    //     autocomplete.setBounds(bounds);

    //     autocomplete.setOptions({
    //         strictBounds: true
    //     });
    // }
    // } else {
    //     // //TRANSCARPATHIA REGION DEFAULT!
    //     // const loc_min = new google.maps.LatLng(
    //     //     48.322104, 
    //     //     22.053231
    //     // );

    //     // const loc_max = new google.maps.LatLng(
    //     //     48.685342, 
    //     //     22.847540
    //     // );

    //     // const bounds = new google.maps.LatLngBounds(loc_min, loc_max);

    //     // // autocomplete.setBounds(bounds);

    //     // autocomplete.setOptions({
    //     //     strictBounds: true
    //     // });
    // }

    autocomplete.addListener("place_changed", callback);


    return autocomplete;
}

export default {
    formatDeliveryAddress,
    initAutocompleteWidget
}