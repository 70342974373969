<template>
  <div class="package-cost-box" v-if="price">
    <span class="title">📦 {{ $t("packaging_info.title") }}</span>
    <span class="info">
      {{ $t('packaging_info.desc') }} <span class="price-value"> {{ price }}{{ currency.symbol }} </span>
    </span>
  </div>
</template>

<style scoped>
.package-cost-box {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: block;
  padding: 2vh;
  margin: 2.5vh auto 0;
  background: #FFDDBA;
  border-radius: 1.2vh;
}

span.title {
  display: block;
  font-size: 2.3vh;
  line-height: 1;
  color: #1d1d1d;
}

span.info {
  display: block;
  font-size: 2vh;
  padding-top: 2vh;
  line-height: 1;
  color: #1d1d1d;
}

span.price-value {
  color: #129b81;
}
@media screen and(min-width:1024px) {
  .package-cost-box {
    padding: 1.6666666666666667vh;
  }
  span.title {
    font-size: 2vh;
    line-height: 2.3333333333333335vh;
  }
  span.info {
    font-size: 1.7777777777777777vh;
    line-height: 132%;
    padding-top: 0.5555555555555556vh;
  }
  span.price-value {
    font-size: 2.222vh;
    line-height: 2.5555555555555554vh;
  }
}
</style>

  <script>
export default {
  props: {
    price: Number,
  },
  computed: {
    currency() {
      return this.$store.state.currency.current;
    },
  },
};
</script>
