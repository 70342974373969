import BaseAPI from '../base'

function registerEvent(cafe_id, client_id, campaign_tag, event_type) {
    return BaseAPI.sendPost(`advertising/event`, {
        cafe_id,
        client_id,
        campaign_tag,
        event_type
    })
}

export default {
    registerEvent
}