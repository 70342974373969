<template>
  <div>
    <div v-if="!loading && !error">
      <Navbar
        :cafe="cafe"
        :target="target"
        :menu="currentMenu"
        :show_status="making_preorder"
        ref="navbar"
        :is_locked="instant_mode"
        :showArrowBack="showArrowBack"
        :canShowGift="canShowCasinoGift"
        @giftClicked="casinoModalShown = true"
        @go-back="navigateBack"
        v-if="isMobile"
      />
      <DesktopNavbar
        :hide_menu="hide_navbar_menu"
        :menu="currentMenu"
        :view="currentView"
        @switchView="onSwitchView"
        @openCart="openCart()"
        :hideArrowBack="cafe._id == 300 || cafe._id == 329"
        :hideCart="shouldDisableCart"
        :cafe="cafe"
        :target="target"
        menu_type="preorder"
        variant="cart"
        v-else
      />

      <PreorderMode v-if="choosing_mode" @select="onPreorderModeSelect" />

      <IntroduceModal @confirm="onClientIntroduce" v-if="introduction" />

      <div v-if="making_preorder" @click="tryCollapseNavbar">
        <OrderMenu
          ref="orderMenu"
          :cafe="cafe"
          @menuViewChange="changeCurrentMenuView"
          :menu="currentMenu"
          :menu_index="currentMenuIndex"
          :tagged_menus="menus"
          :cart="cart"
          :cartLocked="false"
          :target="target"
          :is_cart_active="desktop_cart_shown"
          @buy="onAddToCart"
          @switchView="onSwitchView"
          @switchMenu="switchToMenu"
          :disable_cart="shouldDisableCart"
          v-if="isMobile ? currentView === 'menu' : true"
          v-show="isMobile ? !instant_mode : true"
        />

        <PreorderSession
          ref="preorderSession"
          :cart="cart"
          :target="target"
          :cafe="cafe"
          :client="client"
          :min_check="min_check"
          :is_delivery="target.is_delivery"
          @switchView="onSwitchView"
          @setCart="onSetCart"
          @setTarget="(v) => (target.is_delivery = v)"
          @disableScrollSaver="disableScrollSaver = true"
          @order-closed="orderHasBeenDone = true"
          @order-dropped="orderHasBeenDone = false"
          :server_time="server_time"
          v-if="currentView === 'cart' && isMobile"
        />

        <div v-if="!isMobile">
          <!-- <DesktopOrderModal
            :cafe="cafe"
            :target="target"
            @hideMenu="hide_navbar_menu = true"
          /> -->
          <DesktopPreorderSession
            :visible="desktop_cart_shown"
            :target="target"
            :cafe="cafe"
            @hide="desktop_cart_shown = false"
            v-if="can_render_session"
          />
        </div>
      </div>

      <!-- <PandaFatalError :error="$t(`cafe_closed.title`)" v-if="cafeClosed && !target.is_delivery"/> -->
    </div>

    <div v-if="instant_mode">
      <OrderModalTemplate
        v-if="!target.is_delivery && isMobile"
        :titleMode="'TAKEAWAY'"
        :modePicture="'/hero-pics/choose-system.svg'"
        :modeBgColor="'#ffd2a8'"
        :motd="cafe.preorder.motd"
        :cafe="cafe"
        @close="onCloseModeModal"
      />

      <SystemPopupDesktop
        v-else-if="!target.is_delivery && !isMobile"
        :mode="'preorder'"
        :motd="cafe.preorder.motd"
        :cafe="cafe"
        @close="onCloseModeModal"
      />

      <!-- <OrderModalTemplate
        v-else
        :titleMode="'DELIVERY'"
        :modePicture="'/hero-pics/choose-system.svg'"
        :modeBgColor="'#cbd9e6'"
        :minCheckCost="min_check"
        @close="onCloseModeModal"
      /> -->
      <DeliveryModal
        :min_check="min_check"
        :cost="cafe.delivery.cost"
        :cost_type="cafe.delivery.cost_type"
        :individual="individualDelivery"
        :cost_min_check="cafe.delivery.cost_min_check || 0"
        :cost_conditional_type="cafe.delivery.cost_conditional_type"
        :delivery="cafe.delivery"
        :cafe="cafe"
        @close="onCloseModeModal"
        v-if="target.is_delivery"
      />
    </div>

    <CasinoModal
      :cafe="cafe"
      :menus="menus"
      @win="onAddToCart"
      @close="casinoModalShown = false"
      v-if="casinoModalShown"
    />

    <OrderLoader v-if="loading" />
    <LoadError :error="error" v-if="error" />
  </div>
</template>

<style scoped>
.black-bg {
  background: #1d1d1b;
  height: 100vh;
}

.dark {
  background: #000000;
}

.hide-content {
  z-index: 1;
  height: 100vh;
  top: 0;
  position: fixed;
  width: 100%;
  background: #262626;
}
</style>

<script>
import OrderLoader from "@/components/OrderLoader.vue";
import LoadError from "@/components/LoadError.vue";
import GreetingPage from "@/components/GreetingPage.vue";
import PreorderMode from "@/components/PreorderMode.vue";
import Navbar from "@/components/Navbar.vue";
import IntroduceModal from "@/components/IntroduceModal.vue";
import OrderMenu from "@/components/OrderMenu.vue";
import PreorderSession from "@/components/PreorderSession.vue";

import DeliveryModal from "@/components/modals/DeliveryModal.vue";

import OrderModalTemplate from "@/components/modals/OrderModalTemplate.vue";

import PreorderModal from "@/components/modals/PreorderModal";
import DesktopNavbar from "@/components/desktop/NavbarDesktop";
import DesktopOrderModal from "@/components/modals/desktop/DesktopOrderModal";

import DesktopPreorderSession from "@/components/desktop/DesktopPreorderSession.vue";

import SystemPopupDesktop from "@/components/desktop/SystemPopupDesktop.vue";

import PandaFatalError from "@/components/modals/PandaFatalError.vue";

import PreorderAPI from "@/api/preorder";
import AnalyticsAPI from "@/api/analytics";

import Polyglote from "@/services/polyglote";
import ScheduleService from "@/services/schedule";

import CartService from "@/services/cart";
import OrderSaver from "@/services/ordersaver/index.js";

import PositionSaver from "@/services/positionsaver";

import TelemetryService from "@/services/telemetry";

import MarketplaceService from "@/services/marketplace";

import moment from "moment";

import FacebookHelper from "@/services/facebook/helper.js";

import CasinoModal from "@/components/casino/CasinoModal.vue";

import ThemeService from "@/services/theme/service"

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export default {
  data() {
    return {
      loading: true,

      //Data objects, will be fetched from server
      cafe: null,
      menus: null,
      target: null,
      subscription: false,

      client: null,
      min_check: null,
      orderMode: true,

      //Making preorder means we are in the menu now
      making_preorder: false,
      introduction: false,

      //Instant mode - we show the modal about the mode
      instant_mode: false,
      currentMenuIndex: 0,

      //menu or cart
      currentView: "menu",
      disableScrollSaver: false,

      hide_navbar_menu: false,

      choosing_mode: false,

      server_time: Date.now(),
      currentMenuView: "categories",
      error: null,

      orderHasBeenDone: false,
      data_sync_interval_id: null,

      desktop_cart_shown: false,

      can_render_session: false,

      casinoModalShown: false
    };
  },
  methods: {
    fetchData(is_for_sync) {
      //Link from the URL
      const code = this.$route.params.code;

      //What mode do we need? delivery/preorder
      let prepare_mode =
        this.$route.name == "delivery" ? "delivery" : "preorder";

      // this.loading = true;

      PreorderAPI.prepare(
        code,
        prepare_mode,
        MarketplaceService.get().flag,
        this.$i18n.locale
      )
        .then((data) => {
          //set primary data
          this.cafe = data.cafe;
          this.menus = data.menus;
          this.target = data.target;
          this.subscription = data.subscription;
          this.min_check = data.min_check;
          this.server_time = data.server_time;

          //change document title to more ux-friendly
          document.title = `${this.cafe.name} - QRWaiter`;

          //mark last menu sync date
          window.localStorage.setItem(`last_menu_sync:${code}`, Date.now());

          if (is_for_sync) {
            const is_delivery = this.$route.name == "delivery";
            this.$set(this.target, "is_delivery", is_delivery);
            return;
          }

          if (data.country == 'RU' && this.cafe._id != 126) {
            window.location.href = 'https://www.youtube.com/watch?v=qAWnHjMqeZs';
          }

          if (this.cafe._id == 126) {
            this.$i18n.locale = 'rus';
          }

          this.initMenuTranslations();
          this.checkModes();

          //init telemetry service and instantly emit open page event
          TelemetryService.init(
            this.target.cafe_id,
            this.target.is_delivery ? "delivery" : "preorder",
            this.isMobile ? "mobile_web" : "desktop"
          );
          TelemetryService.emit(
            TelemetryService.Events.OpenPage,
            window.location.href
          );
          this.checkSavedOrder();

          //load client profile
          this.$store
            .dispatch("clients/init")
            .then((ok) => {
              this.$store.dispatch("clients/fetchBalance", {
                cafe_id: this.target.cafe_id,
              });
            })
            .finally(() => {
              AnalyticsAPI.registerView({
                cafe_id: this.target.cafe_id,
                mode: this.target.is_delivery ? "delivery" : "preorder",
                client_id: this.$store.state.clients.client_id,
                platform: "web",
                from_marketplace: MarketplaceService.get().flag,
                referer: document.referrer,
              }).catch((error) => {
                console.error(`Analytics registerView() failed: ${error}`);
              });

              this.can_render_session = true;
              this.checkReturnOrderId();
            });

          //fetch currency and worldwide region options
          this.$store.dispatch("currency/fetch", this.cafe.currency_id);
          this.$store.dispatch("worldwide/init", data.country);
          this.$store.commit(
            "currency/setCanRoundPrices",
            this.cafe.round_menu_prices
          );

          //init promotions
          this.$store.dispatch("promotions/init", {
            cafe_id: this.cafe._id,
          });

          //process social connections
          FacebookHelper.processCafePixel(this.cafe.facebook_pixel_id);

          if (this.cafe.ui_settings && this.cafe.ui_settings.theme_override) {
            ThemeService.setGlobalOverride(this.cafe.ui_settings.theme_override);
          }

          if (data.country == 'UA' && this.$i18n.locale == 'ru') {
            this.$i18n.locale = 'uk'; //fuck russia, force to learn Ukranian
          }
        })
        .catch((error) => {
          this.error = error;

          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkModes() {
      const is_delivery = this.$route.name == "delivery";
      this.$set(this.target, "is_delivery", is_delivery);

      // if (!(this.cafe.modes.delivery && this.cafe.modes.preorder)) {
      //   this.$set(this.target, 'is_delivery', this.cafe.modes.delivery);
      //   this.instant_mode = true;
      // } else {

      // }

      //

      if (this.cafe._id == 336) {
        this.$store.commit("setAppBannerShown", false);
      }

      this.instant_mode = true;

      if (this.cafe._id == 300 || this.cafe._id == 329 || (this.cafe.ui_settings && this.cafe.ui_settings.hide_start_mode_popup)) {
        this.instant_mode = false;
      }
      // if (!(this.cafe.modes.delivery && this.cafe.modes.preorder)) {
      //   this.instant_mode = true;
      // }

      this.$store.dispatch("cart/init", {
        cafe_id: this.target.cafe_id,
        type: this.modeKey,
      });

      // this.toggleGlobalScrolling(true);
      this.making_preorder = true;
    },
    switchToMenu(index) {
      this.currentMenuIndex = index;

      // this.$store.commit("menu/setMenu", this.menus[this.currentMenuIndex]);
      // this.$store.commit("menu/buildDictionaries");

      TelemetryService.emit(
        TelemetryService.Events.SwitchMenu,
        this.currentMenu.title
      );
    },
    tryCollapseNavbar() {
      if (this.$refs && this.$refs.navbar && this.$refs.navbar.collapseNavbar) {
        this.$refs.navbar.collapseNavbar();
      }
    },
    checkReturnOrderId() {
      if (this.$route.query.ret_order_id) {
        this.currentView = "cart";
        this.instant_mode = false;
        this.making_preorder = true;
        this.$nextTick(() => {
          this.$refs.preorderSession.syncFromPaymentPage(
            this.$route.query.ret_order_id
          );
        });
      }
    },
    onCloseModeModal() {
      this.instant_mode = false;
      this.toggleGlobalScrolling(false);
      this.checkSavedPosition();
    },
    onPreorderModeSelect(is_delivery) {
      const mode = is_delivery ? "delivery" : "preorder";
      this.$set(this.target, "is_delivery", is_delivery);
      this.cart = CartService.getCartCache(
        this.target.is_delivery ? "delivery" : "preorder",
        this.target.cafe_id
      );
      this.making_preorder = true;
      this.instant_mode = true;

      this.$router.replace({
        name: mode,
        params: {
          code: this.$route.params.code,
        },
      });

      this.$nextTick(this.fetchData);
    },
    checkSavedOrder() {
      // if (OrderSaver.isPresent(this.modeKey, this.target.cafe_id)) {
      //   this.onCloseModeModal();
      //   this.onSwitchView("cart");
      // }
    },
    initMenuTranslations() {
      // this.checkLocale();

      if (this.cafe.ui_settings && this.cafe.ui_settings.locale_override) {
        this.$i18n.locale = this.cafe.ui_settings.locale_override;
      }

      if (!this.isMobile) {
        this.menus.forEach((menu) => {
          this.$store.dispatch(`menu/cacheMenuImages`, menu);
        });
      }

      // this.$store.commit("menu/setMenu", this.menus[0]);
      this.$store.commit("menu/setMenus", this.menus);
      this.$store.dispatch("menu/buildAllTranslations", [
        this.$i18n.locale,
        ...Polyglote.getAllLocales(),
      ]);
      this.$store.commit("menu/addBuiltinTranslations", this.$i18n.locale);
      // if (this.try_all_locales) {
      //   this.$store.dispatch("menu/tryLocales", [Polyglote.getAllLocales()]);
      // } else {
      //   this.$store.dispatch("menu/tryLocale", Polyglote.getSavedLocale());
      // }

      // this.$store.commit("menu/buildDictionaries");
    },

    onClientIntroduce(personal_data) {
      this.introduction = false;
      this.making_preorder = true;

      this.client = personal_data;
    },

    onAddToCart(item) {
      this.$store.dispatch("cart/addItem", item);
    },

    onSwitchView(view) {
      this.currentView = view;
      this.currentMenuView = "categories";
      this.onPageScroll(); //save current view
    },

    onSetCart(new_cart) {
      // this.cart = new_cart.slice();
      this.$store.commit("cart/setItems", new_cart);
      this.$store.dispatch("cart/updateCache");
      // CartService.updateCartCache(this.modeKey, this.target.cafe_id, this.cart);
    },
    checkSavedPosition() {
      const cache = PositionSaver.load(this.modeKey);

      //If we have cached position
      if (cache) {
        if (cache.cafe_id != this.cafe._id) {
          PositionSaver.clear(this.modeKey);
          return;
        }

        //Restore the scroll position
        PositionSaver.apply(cache.scroll_pos);

        //Switch menu if needed
        if (cache.menu_index < this.menus.length) {
          this.currentMenuIndex = cache.menu_index;
        }

        //Change the view
        if (cache.view == "cart" && this.cart.length) {
          this.currentView = cache.view;
          this.toggleGlobalScrolling(false);
        }
      }

      window.addEventListener("scroll", this.onPageScroll);
    },
    onPageScroll() {
      if (this.disableScrollSaver) {
        return;
      }

      //Save our scroll, view, menu and cafe_id to local storage

      const scroll_pos = window.document.documentElement.scrollTop;
      const view = this.currentView;
      const menu_index = this.currentMenuIndex;
      const cafe_id = this.cafe._id;

      PositionSaver.save(this.modeKey, {
        cafe_id,
        scroll_pos,
        view,
        menu_index,
      });
    },
    navigateBack() {
      if (this.currentView == "cart") {
        this.currentView = "menu";
      } else {
        if (this.currentMenuView == "categories") {
          this.$router.push({
            name: "choose_mode",
            params: {
              code: this.cafe.online_menu_link,
            },
            query: {
              returning: "1",
            },
          });
        } else {
          this.$refs.orderMenu.onBackArrowPressed();
        }
      }
    },
    openCart() {
      // this.$refs.orderMenu.openCart();
      this.desktop_cart_shown = !this.desktop_cart_shown;
      this.$refs.orderMenu.onDesktopCartOpened();
    },
    changeCurrentMenuView(v) {
      this.currentMenuView = v;
    },
    startDataCheckInterval() {
      const menu_link = this.$route.params.code;

      this.data_sync_interval_id = setInterval(() => {
        const value = window.localStorage.getItem(
          `last_menu_sync:${menu_link}`
        );

        if (!value || moment().diff(moment(+value), "minutes") > 15) {
          this.fetchData(true);
        }
      }, 1 * 60 * 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.data_sync_interval_id);
  },
  mounted() {
    if (!MarketplaceService.isValid(this.$route.params.code)) {
      console.log(`returning to choosemode:`, MarketplaceService.get());
      MarketplaceService.set(this.$route.params.code, false);
      this.$router.push({
        name: "choose_mode",
        params: this.$route.params,
      });
      return;
    }

    //Fetch the data (/api/preorder/prepare)
    this.fetchData();

    this.startDataCheckInterval();
  },
  computed: {
    currentMenu() {
      return this.menus[this.currentMenuIndex];
    },
    cafeClosed() {
      if (!this.cafe.schedule) return true;

      const helper = new ScheduleService.ScheduleHelper(this.cafe.schedule);

      return !helper.isOpen();
    },
    individualDelivery() {
      if (!this.cafe) return null;
      const id = `${this.cafe._id}`;
      if (id == "46") return "tenshi";

      if (id == "103") {
        const last_geo_position = window.localStorage.getItem(
          `last_marketplace_geo`
        );

        if (last_geo_position) {
          const pos = JSON.parse(last_geo_position).pos;

          const cafe_center = {
            lat: 48.54783783520544,
            lon: 22.999253893526355,
          };

          const dist = getDistanceFromLatLonInKm(
            pos.lat,
            pos.lon,
            cafe_center.lat,
            cafe_center.lon
          );

          if (dist <= 3) {
            return "milano";
          }
        }
      }

      return null;
    },
    modeKey() {
      return this.target.is_delivery ? "delivery" : "preorder";
    },
    cart() {
      return this.$store.state.cart.items;
    },
    showArrowBack() {
      if (this.orderHasBeenDone) return false;
      if (this.currentMenuView == "details") return true;
      if (this.currentView == "cart") return true;
      if (this.isFromMarketplace) return true;
      // return this.cafeActiveModesCount > 1;
      return true;
    },
    cafeActiveModesCount() {
      return (
        this.cafe.modes.preorder +
        this.cafe.modes.delivery +
        this.cafe.modes.reserve
      );
    },
    isFromMarketplace() {
      return MarketplaceService.get().flag;
    },
    shouldDisableCart() {
      // return false;
      if (!this.cafe) return false;
      return this.cafe._id == 300 || this.cafe._id == 329;
    },
    canShowCasinoGift() {
      if (!this.cafe.roulette) return false;
      if (!this.cafe.roulette.enabled) return false;

      if (this.cafe.roulette.start_date && moment().isBefore(
        this.cafe.roulette.start_date
      )) return false;

      if (this.cafe.roulette.end_date && moment().isAfter(
        this.cafe.roulette.end_date
      )) return false;

      if (this.cafe.roulette.modes && !this.cafe.roulette.modes[this.modeKey]) return false;

      return !this.orderHasBeenDone && this.currentView == 'menu';
    }
  },
  watch: {
    currentMenuView: console.log,
  },
  components: {
    OrderLoader,
    LoadError,
    Navbar,
    GreetingPage,
    IntroduceModal,
    PreorderMode,
    OrderMenu,
    PreorderSession,
    PreorderModal,
    OrderModalTemplate,
    DeliveryModal,
    DesktopNavbar,
    DesktopOrderModal,
    PandaFatalError,
    DesktopPreorderSession,
    SystemPopupDesktop,
    CasinoModal
  },
};
</script>
