<template>
  <div class="alco-modal-wrapper">
    <div class="hide-content"></div>
    <div class="alco-modal-content">
      <div class="alco-subtitle color-grey">
        {{ $t("notifications_update.title") }}
      </div>
      <img class="alco-image" src="/hero-pics/busy-cart.png" />
      <div
        class="alco-text color-white"
        v-html="$t('notifications_update.content')"
      ></div>
      <LongButton variant="primary" @click="onConfirm">
        OK
      </LongButton>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    onConfirm() {
      window.localStorage.setItem('notifications_update_modal', `${Date.now()}`);
      this.$emit("close");
    },
  },
  mounted() {
    this.toggleGlobalScrolling(true);
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  },
};
</script>

<style scoped>
.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  left: 0;
}

.alco-modal-content {
  position: fixed;
  top: 50%;
  width: 90vw;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2.5vh 5.4vw 3.7vh;
  text-align: center;
  background: #1d1d1b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2.3vh 2.3vh 10vw 10vw;

  z-index: 10;
}

.alco-image {
  width: 20vh;
  margin-top: 1vh;
}

.alco-text {
  margin-top: 1vh;
}

.alco-cancel-button {
  margin-top: 2.5vh;
}
</style>