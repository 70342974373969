  <template>
  <div>
    <div class="preorder-content" v-show="!phone_confirm_ui">
      <div class="preorder-box" v-thematic>
        <!-- <h5 v-thematic>{{ $t('preorder_details.contacts') }}</h5> -->
        <ClientNameField
          :fullname="!!forcedTakeawayDates"
          v-model="details.name"
          :valid="validation.name"
          class="p-0"
          id="detailsNameInput"
        />

        <ClientPhoneField
          @click="phone_confirm_ui = true"
          v-model="details.phone"
          :valid="validation.phone"
          id="detailsPhoneInput"
          v-if="!hidePhoneField"
        />


        <!-- <h5 v-if="is_delivery" v-thematic class="pt-40">{{ $t('preorder_details.delivery_title') }}</h5> -->
        <!-- <h5 v-else class="pt-40">{{ $t('preorder_details.preorder_title') }}</h5> -->

        <div class="preorder-detail" v-if="false">
          <img
            :src="
              isDarkMode
                ? '/icons/details/house-white.svg'
                : '/icons/details/house.svg'
            "
            :class="{
              disabled: !raw_address.length,
            }"
          />

          <input
            type="text"
            :class="{
              'detail-input up': true,
              'detail-red-input': !validation.address,
            }"
            v-thematic
            id="gmapField"
            @input="(e) => (raw_address = e.target.value)"
            :placeholder="$t('preorder.address')"
            v-if="!shouldDisableGoogleAutocomplete"
          />

          <!-- <input
              type="number"
              :class="{
                'detail-input': true,
              }"
              v-thematic
              placeholder="Номер будинку"
                 v-if="!shouldDisableGoogleAutocomplete"
            /> -->

          <input
            type="text"
            :class="{
              'detail-input': true,
              'detail-red-input': !validation.address,
            }"
            v-thematic
            v-model="details.address"
            :placeholder="$t('preorder.address')"
            v-else
          />
        </div>
        <div v-if="false">
          <div
            class="preorder-detail"
            v-for="detail in cafe.delivery.address_details || []"
            :key="detail"
          >
            <img
              :src="
                isDarkMode
                  ? '/icons/details/house-white.svg'
                  : '/icons/details/house.svg'
              "
              :class="!address_details_selection[detail] ? 'disabled' : ''"
            />
            <input
              type="number"
              :class="{
                'detail-input': true,
              }"
              v-thematic
              v-model="address_details_selection[detail]"
              :placeholder="$t(`preorder_details.details_types.${detail}`)"
            />
          </div>
        </div>

        <ClientAddressField 
          v-model="details.address" 
          :cafe_location="cafe.address_location"
          :address_details="cafe.delivery.address_details"
          @details="(v) => (address_details_selection = v)"
          :valid="validation.address"
          :google_autocomplete="!shouldDisableGoogleAutocomplete"
          v-if="is_delivery"
        />

        <ChooseTimeField 
          v-model="details.takeaway_date" 
          :dates="timeFieldDates" 
          :allow_asap="isSpecificTimeDisabled ? true : show_delivery_asap && !forcedTakeawayDates"
          :valid="validation.takeaway"
          :timezone="cafe.timezone"
          id="detailsTimeInput"
          v-if="showTimeField"
        />

        <ReserveTakeawayField
          v-if="!is_delivery && allowMakingReservation"
          v-model="details.with_reservation"
        />

        <PersonsAmountField
          v-model.number="details.guests_count"
          isCutlery
          v-if="!hideGuestsCount"
        />

        <!-- <h5
          v-thematic
          class="pt-40"
          v-if="!hideGuestsCount"
        >{{ $t('preorder_details.guests_count') }}</h5> -->

        <!-- <div class="preorder-detail" v-if="!hideGuestsCount">
          <select v-model="details.guests_count">
            <option v-for="n in 6" :key="n" :value="n - 1">
              {{ $tc("preorder_details.guests_count_suffix", n - 1) }}
            </option>
          </select>
          <img
            :src="
              isDarkMode
                ? '/icons/details/cutlery-white.svg'
                : '/icons/details/cutlery.svg'
            "
            v-if="details.guests_count"
          />
          <img src="/icons/details/cutlery-eco.svg" v-else />

           
          <p :class="{
            'selective':  details.guests_count == 0,
            'inherit': true
          }">
            {{
              $tc("preorder_details.guests_count_suffix", details.guests_count)
            }}
          </p>
          <div class="selective-item">
            <img
              :src="
                isDarkMode ? '/icons/arrow-black.svg' : '/icons/arrow-white.svg'
              "
              :class="details.guests_count < 1 ? 'arrow disabled':'arrow'"
              v-if="details.guests_count > 0"
            />
            <img src="/icons/arrow-green.svg" class="arrow" v-else>
          </div>
        </div> -->

        <PayMethods
          v-model="details.pay_method"
          :bonus_balance="bonus_balance"
          :total="totalCartPrice"
          :available_methods="pay_methods"
          :showBonus="showBonus"
          :validation="validation.pay_method"
          id="detailsPayMethod"
        />

        <DeliveryInfo
          :state="deliveryPricingState"
          v-if="deliveryPricingState"
        />

        <PackagingInfo class="w-90" :price="estimated_packaging_cost" v-if="estimated_packaging_cost"/>

        <PromocodeInput 
          class="preorder-promocode-input"
          v-model="details.promocode"
          :mode="is_delivery ? 'delivery' : 'preorder'"
          :cafe_id="cafe._id"/>

        <CommentField v-model="details.comment" class="mt-30" v-if="!cafe.ui_settings.hide_comment"/>

        <SplitPaymentsList
          :splits="split_payments"
          :index="split_index"
          v-if="isUsingSplitPayments"
        />

        <div
          @click="onConfirm"
          class="order-confirm-button"
          v-if="!isUsingSplitPayments"
        >
          <h4>{{ $t("order_buttons.send") }}</h4>
          <small class="pay"
            >{{ totalDishesInCart }} ({{ totalCartPrice }}
            {{ currency.code }})</small
          >
        </div>
        <div
          @click="onConfirm"
          class="order-confirm-button"
          v-else-if="isUsingSplitPayments"
        >
          <h4>{{ $t("split_payment.pay_button") }} {{ split_index + 1 }}</h4>
          <small class="pay"
            >{{ split_payments[split_index].total }}
            {{ currency.symbol }}</small
          >
        </div>
      </div>
    </div>

    <!-- <LongButton variant="success" @click="onConfirm()">Підтвердити | {{totalOrderPrice}}₴</LongButton> -->

    <PhoneConfirmModal
      :token="confirming_token"
      @confirmed="onPhoneConfirmed"
      @resend="askPhoneConfirmation"
      @cancel="confirming_phone = false"
      v-if="confirming_phone && isMobile"
    />

    <CorrectAddressModal 
      @close="show_fix_address = false"
      v-if="show_fix_address"/>

    <!-- <ErrorModal /> -->

    <PhoneConfirmationUI 
      :cafe_id="cafe._id"
      :initial_phone="details.phone"
      @close="phone_confirm_ui = false"
      @confirm="onNewPhoneConfirmed"
      :verify_sms="cafe.client_app.verify_client_phone"
      v-if="phone_confirm_ui"/>
  </div>
</template>

<style scoped>
.pt-40 {
  padding-top: 5vh;
}

.mt-30 {
  margin-top: 3.7vh;
}

h4 {
  margin: 0;
  font-size: 2vh;
  color: #ededed;
  line-height: normal;
}
small {
  font-size: 1.7vh;
  color: #a4a3a1;
}

.preorder-box {
  overflow-x: hidden;
  padding: 0 2.5vh 0;
  position: relative;
}

.preorder-box h5.dark {
  color: #a4a3a1;
}

.preorder-box.dark > * {
  color: #ededed;
}

.preorder-detail {
  display: flex;
  color: #1d1d1b;
  padding: 2vh 0;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #a4a3a1;
}
.preorder-detail img {
  height: 3.8vh;
  width: 3.8vh;
  margin-right: 2vh;
}
.inherit {
  width: inherit;
}

p {
  width: max-content;
  margin: 0;
  line-height: normal;
  font-size: 2vh;
}
h5 {
  color: #a4a3a1;
  margin: 0;
  text-transform: uppercase;
  font-size: 2vh;
  padding-bottom: 1vh;
  border-bottom: 1px solid #a4a3a1;
}

.time-selector-box {
  height: 4vh;
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: inherit;
}

p.selective {
  color: #129b81;
}

.selective-item {
  width: inherit;
}
.selective-item img.arrow {
  float: right;
  margin: 0;
  width: auto;
  height: 1.5vh;
  transform: rotate(-90deg);
}
select:focus ~ .selective-item img.arrow {
  transform: rotate(180deg);
  transition: 0.3s;
}

select {
  width: 85%;
  position: absolute;
  background: transparent;
  border: none;
  opacity: 0;
}

p.content {
  font-size: 2vh;
  margin: 0;
  line-height: normal;
  padding-top: 1.1vh;
  color: #454543;
}

.order-details {
  padding: 1.2vh 5.5vw 2.5vh;
}

.order-details p {
  line-height: inherit;
}

.order-details img {
  vertical-align: sub;
  height: 3vh;
  width: 3vh;
}

img.disabled {
  opacity: 0.45;
}

.order-details div {
  padding-top: 1.2vh;
}
p.order-info {
  line-height: 0.8;
  padding-left: 1vh;
  margin-bottom: 0;
  display: inline-block;
}

.detail-input {
  border: 0;
  outline: 0;
  /* line-height: normal; */
  -webkit-appearance: none;
  color: #1d1d1b;
  background: transparent;
  width: 100%;
}

.up {
  /* margin-top: -0.8vh; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  background-clip: content-box !important;
}

.detail-input.dark {
  color: #ededed;
}

.detail-red-input {
  /* border: 1px solid #e2223b; */
  color: #e2223b !important;
}

.detail-red-input::placeholder {
  color: #e2223b !important;
}

.order-confirm-button {
  animation: fade 0.5s;
  margin: 2.5vh auto 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  border-radius: 1.5vh;
  width: 100%;
  text-align: center;
  background: #129b81 !important;
  color: #ededed !important;
  padding: 1.6vh;
  cursor: pointer;
}

.order-confirm-button .pay {
  color: #ededed !important;
}

.detail-placeholder {
  color: #a4a3a1;
}

.preorder-promocode-input {
  padding-top: 3.2vh;
}

@media screen and (min-width: 1024px) {
  .order-confirm-button {
    padding: 1.4444444444444444vh 0;
  }
  .order-confirm-button h4 {
    font-size: 1.6666666666666667vh;
    line-height: 1.8888888888888888vh;
  }
  .order-confirm-button .pay {
    font-size: 1.2222222222222223vh;
    line-height: 1.4444444444444444vh;
  }
}
</style>

<script>
import PhoneConfirmModal from "@/components/modals/PhoneConfirmModal.vue";
import CorrectAddressModal from "@/components/modals/CorrectAddressModal.vue";
import PandaFatalError from "@/components/modals/PandaFatalError.vue";

import ClientNameField from "@/components/order-details/ClientNameField.vue";
import ClientPhoneField from "@/components/order-details/ClientPhoneField.vue";
import ChooseTimeField from "@/components/order-details/ChooseTimeField.vue";
import PersonsAmountField from "@/components/order-details/PersonsAmountField.vue";
import ReserveTakeawayField from "@/components/order-details/ReserveTakeawayField.vue";
import ClientAddressField from "@/components/order-details/ClientAddressField.vue";

import CommentField from "@/components/order-details/CommentField.vue";

import PayMethods from "@/components/PayMethods.vue";
import PhoneConfirmationUI from "@/components/modals/PhoneConfirmationUI.vue";

import SplitPaymentsList from "@/components/SplitPaymentsList.vue";

import DeliveryInfo from "@/components/card/DeliveryInfo.vue";

import SecurityAPI from "@/api/security";

import moment from "moment";

import ScheduleService from "@/services/schedule";

import { IMaskDirective } from "vue-imask";

import GoogleMapsHelper from "@/services/googlemaps/helper.js";

import PromocodeInput from "@/components/order-details/PromocodeInput.vue";

import FixAddressModal from "@/components/modals/FixAddressModal.vue";

import PackagingInfo from "@/components/card/PackagingInfo.vue"

let gmapAutocomplete = null;

export default {
  props: {
    is_delivery: Boolean,
    show_delivery_asap: {
      type: Boolean,
      default: true,
    },
    pay_methods: Object,
    dates: Array,
    bonus_balance: [Number, Object],
    split_payments: Array,
    split_index: Number,
    estimated_packaging_cost: Number,
    showBonus: {
      type: Boolean,
      default: false,
    },
    cafe: Object,
    taxiDelivery: {
      type: Boolean,
      default: false,
    },
    deliveryPricingState: Object,
  },
  data() {
    return {
      details: {
        name: null,
        phone: null,
        address: null,
        address_details: null,
        takeaway_date: "none",
        pay_method: null,
        guests_count: 0,
        with_reservation: false,
        promocode: null,
        comment: null,
      },

      phone_confirm_ui: false,

      address_details_selection: {
        floor: null,
        entrance: null,
        flat: null,
      },

      validation: {
        phone: true,
        name: true,
        address: true,
        pay_method: true,
        takeaway: true,
      },

      raw_phone_number: null,
      raw_address: "",

      confirming_phone: false,
      confirming_token: null,

      phone_mask_complete: false,
      hide_phone_icon: true,

      show_fix_address: false,
    };
  },
  watch: {
    "details.name"() {
      if (this.details.name && this.details.name.length >= 3) {
        if (!this.validation.name) this.validation.name = true;
      }
    },
    "details.pay_method"() {
      if (this.details.pay_method) {
        this.validation.pay_method = true;
      }
    },
    "phone_confirm_ui"() {
      this.$emit('phoneConfirmUI', this.phone_confirm_ui);
    },
    'details.address'() {
      this.$emit('addressChanged', this.details.address);
    }
  },
  methods: {
    checkClientCache() {
      if (this.hidePhoneField) return;

      if (this.$store.getters["clients/isAuthorized"]) {
        const client = this.$store.state.clients.client;

        this.details.name = client.name;
        this.details.phone = client.phone;
        this.phone_mask_complete = true;
        this.hide_phone_icon = false;

        // document.getElementById("phoneIconImage").classList.remove("disabled");

        if (client.address && this.is_delivery) {
          this.details.address = client.address;

          this.$nextTick(() => {
            document.getElementById("gmapField").value = client.address;
          });
        }

        this.raw_address = this.details.address;
      }
    },
    emitConfirm() {
      this.$emit("confirm", this.details);
    },
    scrollToElement(id) {
      window.document.documentElement.scrollTop = document.getElementById(
        id
      ).offsetTop;
    },
    onConfirm() {
      this.validation.name = true;
      this.validation.phone = true;
      this.validation.address = true;
      this.validation.takeaway = true;

      if (!this.details.name || this.details.name.length < 3) {
        this.validation.name = false;
        this.scrollToElement("detailsNameInput");
      }

      if (
        !this.hidePhoneField &&
        !this.smsConfirmationDisabled &&
        (!this.details.phone || this.details.phone.length < 7)
      ) {
        this.scrollToElement("detailsPhoneInput");
        this.validation.phone = false;
      }

      if (this.isSpecificTimeDisabled) {
        this.details.takeaway_date = null;
      }

      if (this.details.takeaway_date == "none") {
        this.scrollToElement("detailsTimeInput");
        this.validation.takeaway = false;
      }

      if (this.is_delivery && !this.shouldDisableGoogleAutocomplete)
        if (
          !this.details.address ||
          this.details.address != document.getElementById("gmapField").value
        ) {
          //we use raw checking in case a street is not correctly
          //mapped to google
          //(user enters house number, but google does not pass it via place_components)
          const raw = document.getElementById("gmapField").value;

          if (raw && raw.split(",").length >= 5) {
            console.log(raw.split(","));
            this.details.address = raw
              .split(",")
              .slice(0, 3)
              .join(",");
            this.validation.address = true;
          } else {
            this.scrollToElement("gmapField");
            this.validation.address = false;
            this.show_fix_address = true;
          }
        }

      if (this.cafe.delivery.address_details)
        this.details.address_details = this.cafe.delivery.address_details
          .filter((d) => {
            return !!this.address_details_selection[d];
          })
          .map((d) => {
            return `${this.$t(`preorder_details.details_types.${d}`)}: ${
              this.address_details_selection[d]
            }`;
          })
          .join(", ");

      if (!this.details.pay_method) {
        this.validation.pay_method = false;
        this.scrollToElement("detailsPayMethod");
        return;
      }

      if (
        !this.validation.name ||
        (!this.smsConfirmationDisabled && !this.validation.phone) ||
        !this.validation.address ||
        !this.validation.takeaway
      ) {
        return;
      }

      if (this.isUsingSplitPayments && this.split_index > 0) {
        this.$emit("paySplit");
        return;
      }

      // if (
      //   !this.smsConfirmationDisabled &&
      //   (!this.$store.getters["clients/isAuthorized"] ||
      //     this.$store.state.clients.client.phone != this.details.phone)
      // ) {
      //   // const mobizon_is_down = true;

      //   // if (mobizon_is_down) {
      //   //   this.emitConfirm();
      //   //   return;
      //   // }

      //   //dgijit
      //   // if (this.cafe._id == 126) {
      //   //   this.emitConfirm();
      //   //   return;
      //   // }
      //   //

      //   this.askPhoneConfirmation();
      //   return;
      // } else {
      //   this.emitConfirm();
      // }
      this.emitConfirm();
    },
    formatTakeawayDate(raw) {
      if (!raw) return this.$t("preorder_time_modal.fast_as_possible");

      const m = moment(raw);
      const now = moment();

      if (now.isSame(m, "day")) return this.$t("reserve.today");
      if (now.add(1, "day").isSame(m, "day"))
        return this.$t("reserve.tomorrow");

      return m.format("DD.MM.YYYY");
    },
    formatTakeawayTime(raw) {
      if (!raw) return "--:--";
      return moment(raw).format("HH:mm");
    },
    onPhoneMaskComplete(ev) {
      const detail = ev.detail;

      this.details.phone = detail.unmaskedValue;
      this.phone_mask_complete = true;
      this.validation.phone = true;
      this.hide_phone_icon = false;
      document.getElementById("phoneIconImage").classList.remove("disabled");
    },
    askPhoneConfirmation() {
      this.confirming_phone = true;

      SecurityAPI.ask(this.details.phone)
        .then((data) => {
          this.confirming_token = data.token;
          if (!this.isMobile) {
            this.$emit('phoneConfirm', this.confirming_token);
            this.confirming_phone = false;
          }
        })
        .catch((error) => {
          alert(
            `Сталася помилка підтвердження. Будь ласка, спробуйте пізніше.`
          );
        });
    },
    onPhoneConfirmed() {
      this.$store
        .dispatch("clients/create", {
          name: this.details.name,
          phone: this.details.phone,
          address: this.details.address,
        })
        .finally(() => {
          this.confirming_phone = false;
          this.$nextTick(this.emitConfirm);
        });
    },
    showGoogleMapWidget() {
      const el = document.getElementById("gmapField");

      gmapAutocomplete = GoogleMapsHelper.initAutocompleteWidget(
        el,
        this.onGmapPlaceChange.bind(this),
        this.$store.getters["worldwide/googleMapsCountry"],
        this.cafe._id == 126 ? cafe.address_location : null
      );
    },
    onGmapPlaceChange() {
      const selected_address = GoogleMapsHelper.formatDeliveryAddress(
        gmapAutocomplete
      );

      document.getElementById("gmapField").value = selected_address.address;

      if (!selected_address.valid) {
        return;
      }

      this.details.address = selected_address.address;
      this.validation.address = true;
    },
    onPhoneMaskAccept(ev) {
      const v = ev.detail.unmaskedValue;
      this.phone_mask_complete = false;

      // DIRECT DOM MANIPULATION
      // because for unknown reason if we use raw_phone_number
      // in condition for img in template
      // the phone input breaks and we cant enter the phone number normally
      if (!this.phone_mask_complete) {
        console.log("add ", v);
        document.getElementById("phoneIconImage").classList.add("disabled");
      } else {
        console.log("remove", v);
        document.getElementById("phoneIconImage").classList.remove("disabled");
      }

      this.raw_phone_number = ev.detail.unmaskedValue;
    },
    onPhoneInputChanged(ev) {
      this.raw_phone_number = ev.target.value.split("_")[0];

      if (this.raw_phone_number.length < 8) {
        this.hide_phone_icon = true;
        console.log(this.hide_phone_icon);
      }
    },
    onNewPhoneConfirmed(value) {
      this.raw_phone_number = value;
      this.details.phone = value;
    },
    estimatePackaging() {
      
    }
  },
  mounted() {
    // this.phone_mask.mask = this.$store.getters.getCountryOptions.phone_mask;
    this.checkClientCache();

    if (this.is_delivery) {
      if (!this.shouldDisableGoogleAutocomplete) {
        this.$nextTick(this.showGoogleMapWidget);
      }
    }

    // if (this.is_delivery) {
    //   if (!this.show_delivery_asap || this.isScheduledDelivery) {
    //     this.details.takeaway_date = this.dates[0];
    //   }
    // } else {
    //   this.details.takeaway_date = this.dates[0];
    // }

    this.$nextTick(() => {
      this.checkClientCache();

      // document.getElementById('detailsPhoneInput').addEventListener(
      //   'keyup',
      //   this.onPhoneInputChanged
      // );
    });

    if (!this.showTimeField) {
      this.details.takeaway_date = null;
    }

    if (this.show_delivery_asap) {
      this.details.takeaway_date = null;
    }

    if (this.forcedTakeawayDates) {
      this.details.takeaway_date = this.forcedTakeawayDates[0];
    }
  },
  computed: {
    totalDishesInCart() {
      return this.$tc(
        "order_buttons.dishes",
        this.$store.state.cart.items.length
      );
    },
    totalCartPrice() {
      return this.$store.getters["cart/total"];
    },
    currency() {
      return this.$store.state.currency.current;
    },
    hideGuestsCount() {
      if (`${this.cafe._id}` == "299") return true;
      const container = this.is_delivery
        ? this.cafe.delivery
        : this.cafe.preorder;
      return !container.use_guests_count || this.isScheduledDelivery;
      // return !this.cafe.delivery.use_guests_count || this.isScheduledDelivery || this.cafe._id == 299;
    },
    hidePhoneField() {
      return false;
    },
    smsConfirmationDisabled() {
      return this.cafe._id == 299 || !this.cafe.security.require_phone;
    },
    showTimeField() {
      return !(this.cafe._id == 299);
    },
    shouldDisableGoogleAutocomplete() {
      // return `${this.cafe._id}` == "126";
      // return false;
      return this.cafe._id == 381;
      // return false;
    },
    forcedTakeawayDates() {
      if (`${this.cafe._id}` == `356`) { //smart cafe
        let dates = [];

        let ptr = moment().add(1,'day').hours(13).minutes(0);

        if (ptr.day() == 0) {
          ptr = ptr.add(1,'day');
        } else if (ptr.day() == 6) {
          ptr = ptr.add(2,'day');
        }

        const end = moment(ptr).hours(17).minutes(0);

        while (ptr.isSameOrBefore(end)) {
          dates.push(ptr.toDate());
          ptr = ptr.add(10, 'minutes')
        }
    

        // for (let day = 1; day < 7; day++) {
        //   let ptr = moment().add(day, 'days');

        //   if ([0,6].includes(ptr.day())) {
        //     continue;
        //   }

          
        // }

        return dates;
      }

      if (['336'].includes(`${this.cafe._id}`)) {
        let dates = [];

        let today_limit = moment().hours(10).minutes(0);

        console.log(today_limit.format());

        if (moment().isBefore(today_limit)) {
          console.log('isBefore');
          dates.push(
            moment().hours(11).minutes(0).toDate()
          );
        }

        for (let i = 1; i <= 4; i++) {
          dates.push(moment().add(i, 'day').hours(11).minutes(0).toDate());
        }

        return dates;
      } else {
        return null;
      }
    },
    allowMakingReservation() {
      return this.cafe.preorder.allow_reservation;
    },
    phone_mask() {
      return {
        mask: this.$store.getters["worldwide/phoneMask"],
        lazy: false,
      };
    },
    isScheduledDelivery() {
      return this.is_delivery && this.cafe.delivery.is_scheduled;
    },
    isPhoneIconDisabled() {
      if (this.phone_mask_complete) return true;
      return this.raw_phone_number && this.raw_phone_number.length < 8;
    },
    takewayNotSelected() {
      return this.details.takeaway_date == "none";
    },
    isUsingSplitPayments() {
      return (
        this.details.pay_method == "online" &&
        this.split_payments &&
        this.split_payments.length > 1
      );
    },
    isSpecificTimeDisabled() {
      if (this.is_delivery) return false;
      return !this.cafe.preorder.allow_specific_time;
    },
    timeFieldDates() {
      if (this.isSpecificTimeDisabled) return [];

      return this.forcedTakeawayDates ? this.forcedTakeawayDates : this.dates
    }
  },

  components: {
    PhoneConfirmModal,
    PandaFatalError,
    CorrectAddressModal,
    ClientNameField,
    ClientPhoneField,
    ChooseTimeField,
    PersonsAmountField,
    ReserveTakeawayField,
    CommentField,
    SplitPaymentsList,
    PayMethods,
    ClientAddressField,
    DeliveryInfo,
    PromocodeInput,
    FixAddressModal,
    PhoneConfirmationUI,
    PackagingInfo
  },
  directives: {
    imask: IMaskDirective,
  },
};
</script>
