<template>
  <div :class="classes" @click="$emit('click')">
    <div><slot></slot></div>
    <img :src="`/icons/${icon}.svg`" alt="" />
  </div>
</template>
<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'outlined-black'
    },
    icon: String,
  },
  computed: {
    classes() {
      return {
        'check-action-button': true,
        [this.variant]: true
      };
    },
  },
};
</script>
<style scoped>
.check-action-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4444444444444444vh 2.2222vh;
  font-size: 2vh;
  border-radius: 1.1111vh;
  line-height: 132%;
  box-sizing: border-box;
  cursor: pointer;
}
.outlined-black {
  border: 0.111111vh solid #1d1d1b;
  color: #1d1d1b;
}
.outlined-red {
  border: 0.111111vh solid #e2223b;
  color: #e2223b;
}
.filled-green {
  border: 0.111111vh solid #129b81;
  color: #ededed;
  background: #129b81;
}
</style>
