<template>
  <div>
    <div class="order-cart" v-if="!isEmpty">
      <div class="box" v-for="(item,index) in cart" :key="index">
        <div class="w-15">
          <img :src="getProductImage(item)" alt />
        </div>
        <div class="info w-85">
          <div class="d-flex justify-content-between align-items-end">
          <h4>
            {{ item.name }}
           
          </h4>
           <span class="price">{{ item.price }}{{currency.symbol}}</span>
           </div>

          <div class="d-flex justify-content-between pt-10" v-for="(mod,mod_index) in item.modificators" :key="mod_index">
            <h5>+ {{mod.name}}</h5>
            <span class="color-grey">{{mod.price}}{{currency.symbol}}</span>
          </div>
        </div>
        <div class="quantity">
          <img src="/icons/btn-white-minus.svg" @click="onChangeQuantity(index, -1)" />
          {{item.quantity}}x
          <img
            src="/icons/btn-white-plus.svg"
            @click="onChangeQuantity(index, 1)"
            class="mr-0"
          />
        </div>
      </div>
    </div>
    <div class="pt-15 comment d-flex align-items-end justify-content-end" v-if="!isEmpty">
      <h5 class="d-flex align-items-baseline">
        {{ $t('list.total') }}
        <h2>{{cart_total}}{{currency.symbol}}</h2>
      </h5>
    </div>
    <div class="comment" v-if="!isEmpty">
      <h6>{{ $t('cart.comment') }}</h6>
      <textarea
        :placeholder="$t('desktop_delivery.comment_desc')"
        class="w-100"
        maxlength="300"
        v-model="comment"
      ></textarea>
    </div>
    <button class="next" @click="nextStep" v-if="!isEmpty">
      {{ $t('desktop_delivery.next') }}
    </button>
    <h4 class="empty-title" v-if="isEmpty">{{ $t('cart.empty') }}</h4>
  </div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  display: none;
}

h4 {
  line-height: 1;
  font-size: 2vh;
}


.info span.price {
  font-size: 2.7vh;
  line-height: 0.8;
}

.info span {
  font-size: 1.8vh;
  line-height: 0.8;
}


.info h4 {
  white-space: nowrap;
  overflow: hidden;
  max-width: 45vh;
  text-overflow: ellipsis;
}

.info h5 {
  font-size: 2vh;
  line-height: 0.8;
}

h5 {
  font-size: 2.2vh;
}


h6 {
  font-size: 2vh;
}
.order-cart {
  max-height: 38vh;
  overflow-y: auto;
  background: #ededed;
}
.info {
  height: fit-content;
  padding-left: 2.2vh;
}

.next {
  background: #416293;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 1vh;
  color: white;

  padding: 1.8vh 3.5vh;
  margin: 2.2vh auto 0;
  display: block;
  font-size: 2.1vh;
  width: 33.3vh;
  transition: 0.3s;
  outline: none;
  border: none;
}

.next:active {
  box-shadow: none;
  transform: translateY(0.5vh);
}

.box {
  display: flex;
  padding: 2.2vh 3.3vh;
  flex-flow: row wrap;
  border-bottom: 1px solid #a4a3a1;
}
.quantity {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 1.7vh;
  font-size: 2.7vh;
}

.quantity img {
  cursor: pointer;
}

.qnty-total h5 {
  float: right;
   font-size: 2.3vh;
  color: #a4a3a1;
}

h2 {
  color: #a4a3a1;
  padding-left: 2.2vh;
  font-size: 5.5vh;
}

.comment {
  padding: 0 3.3vh;
}

.pt-15 {
  padding-top: 1.7vh;
}

.pt-10 {
  padding-top: 1.1vh;
}

textarea {
  margin-top: 1.2vh;
  border-radius: 1vh;
  padding: 1vh;
  font-size: 2vh;
  border: 1px solid #a4a3a1;
  background: #ededed;
}

textarea:focus {
  outline: none;

  border-color: rgb(128, 128, 128);
}

.box img {
  height: 8vh;
  width: 8vh;
  object-fit: cover;
  border-radius: 1vh;
}
.quantity img {
  height: 3.8vh;
  width: auto;
  margin: 0 4.5vh;
}
.w-15 {
  width: 8vh;
}
.w-85 {
  width: 56.6vh;
}

.empty-title {
  text-align: center;
  margin-top: 2.2vh;
}
</style>

<script>
export default {
  data() {
    return {
      comment: null
    };
  },
  methods: {
    onChangeQuantity(index, delta) {
      this.$store.dispatch("cart/changeQuantity", {
        index,
        delta
      });
    },
    nextStep() {
      this.$emit("next", this.comment);
    },
    getProductImage(product) {
      return this.$store.getters["menu/productImageUrl"](product);
    }
  },
  computed: {
    cart() {
      return this.$store.state.cart.items;
    },
    cart_total() {
      return this.$store.getters["cart/total"];
    },
    isEmpty() {
      return !this.cart.length;
    },
    currency() {
      return this.$store.state.currency.current;
    }
  }
};
</script>