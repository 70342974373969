<template>
  <div>
    <div class="order-detail">
      <img
        :src="currentIcon"
        :class="{
          disabled: (!raw_address || !raw_address.length) && valid,
        }"
      />

      <input
        type="text"
        :class="{
          'detail-input up': true,
          'detail-red-input': !valid,
        }"
        v-thematic
        id="gmapField"
        @input="(e) => (raw_address = e.target.value)"
        :placeholder="$t('preorder.address')"
        v-if="google_autocomplete"
      />

      <input
        type="text"
        :class="{
          'detail-input': true,
          'detail-red-input': !valid,
        }"
        v-thematic
        :value="value"
        @input="ev => $emit('input', ev.target.value)"
        :placeholder="$t('preorder.address')"
        v-else
      />
    </div>
    <div v-if="address_details && address_details.length">
      <div
        class="order-detail"
        v-for="detail in address_details || []"
        :key="detail"
      >
        <img
          :src="currentIcon"
          :class="!address_details_selection[detail] ? 'disabled' : ''"
        />
        <input
          type="number"
          :class="{
            'detail-input': true,
          }"
          v-thematic
          v-model="address_details_selection[detail]"
          @blur="onDetailsBlur"
          :placeholder="$t(`preorder_details.details_types.${detail}`)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.order-detail img {
  /* margin-right: 2.2vw !important; */
}
</style>

<script>
import GoogleMapsHelper from "@/services/googlemaps/helper.js";

let gmapAutocomplete = null;

export default {
  props: {
    cafe_location: Object,
    value: String,
    valid: {
      type: Boolean,
      default: false
    },
    address_details: [Object, Array],
    google_autocomplete: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value() {
      this.raw_address = this.value;
    }
  },
  data() {
    return {
      address_details_selection: {
        floor: null,
        entrance: null,
        flat: null,
      },
      raw_address: null
    }
  },
  computed:{
     currentIcon() {
      const themeSuffix = this.isDarkMode ? "-white" : "";
      if (!this.valid) return `/icons/details/house-red.svg`;
      if (this.value === null) return `/icons/details/house${themeSuffix}.svg`;
      return `/icons/details/house${themeSuffix}.svg`;
    },
  },
  methods: {
    showGoogleMapWidget() {
      const el = document.getElementById("gmapField");

      gmapAutocomplete = GoogleMapsHelper.initAutocompleteWidget(
        el,
        this.onGmapPlaceChange.bind(this),
        this.$store.getters["worldwide/googleMapsCountry"],
        this.cafe_location || null
      );
    },
    onDetailsBlur() {
      this.$emit('details', Object.assign({}, this.address_details_selection));
    },
    onGmapPlaceChange() {
      const selected_address = GoogleMapsHelper.formatDeliveryAddress(
        gmapAutocomplete
      );

      document.getElementById("gmapField").value = selected_address.address;

      if (!selected_address.valid) {
        return;
      }

      this.$emit('input', selected_address.address);
    },
  },
  mounted() {
    if (this.google_autocomplete) {
      this.$nextTick(this.showGoogleMapWidget);
    }
  }
}
</script>
